import React from 'react';
import { LinkBox } from './styles';

const LinkButton = ({ link, title }) => {
      return (
            <>
                  <LinkBox to={link}>{title}</LinkBox>
            </>
      );
};

export default LinkButton;
