import { Col } from 'react-bootstrap';
import { styled } from 'styled-components';

export const QuotationContactPerson = styled.div`
      border: 1px solid #f2f2f2;
      margin: 0px;
      border-radius: 5px;
      padding: 20px 10px;
      margin-top: 20px;
      background-color: #f2f2f2;
`;

export const QuotationBusinessDetail = styled.div`
      /* gap: 10px;
      display: flex;
      flex-wrap: wrap; */

      div {
            display: flex;
            gap: 10px;
            align-items: center;
            margin-bottom: 10px;

            &:last-child {
                  margin-bottom: 0%;
            }

            span {
                  width: -webkit-fill-available;
            }

            input {
                  border-radius: 0;
            }
      }
`;

export const QuotationItems = styled(Col)`
      margin-top: 3rem;
      tr {
            th {
                  padding: 10px;
            }
      }

      table {
            tbody {
                  tr {
                        border: 2px dashed #666;

                        img {
                              height: 80px;
                              width: 80px;
                              object-fit: contain;
                        }

                        .form-select,
                        .form-control {
                              border-radius: 0;
                        }

                        td {
                              padding: 10px;
                        }

                        &:nth-child(even) {
                              background-color: #f2f2f2;
                        }

                        button {
                              border-radius: 0;
                              padding: 4px 10px;
                              margin-right: 10px;
                              display: flex;
                              border: 0;
                              background: transparent;
                        }
                  }
            }
      }
`;

export const QuotationButton = styled.button`
      border-radius: 0;
      padding: 4px 16px;
      margin-right: 10px;
      border: 0;
      background: ${(props) => props.theme.primaryColor};
      color: ${(props) => props.theme.white};

      &:hover {
            background-color: ${(props) => props.theme.secondaryColor};
      }
`;
