import React, { useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import axios from 'axios';
import { Navigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { Loading } from '../../components/Loading';
import { LoginHeader } from './syles';

const Login = () => {
      const [email, setEmail] = useState('');
      const [password, setPassword] = useState('');
      const [isLoading, setIsLoading] = useState(false);
      const [redirect, setRedirect] = useState(false);

      // Error Handle
      const [validated, setValidated] = useState(false);

      const handleButtonClick = async (event) => {
            event.preventDefault();
            setIsLoading(true);

            const form = event.currentTarget;
            if (form.checkValidity() === false) {
                  event.preventDefault();
                  event.stopPropagation();
            }

            setValidated(true);

            if (email !== '' && password !== '') {
                  await axios
                        .post(
                              `${process.env.REACT_APP_SECRET_KEY}/api/auth/login`,
                              {
                                    email,
                                    password
                              }
                        )
                        .then((response) => {
                              if (response.data.token) {
                                    localStorage.setItem('isLoggedIn', true);
                                    localStorage.setItem(
                                          'token',
                                          JSON.stringify(response.data.token)
                                    );
                                    localStorage.setItem(
                                          'tokenExpiration',
                                          new Date(
                                                new Date().getTime() +
                                                      response.data
                                                            .token_validity *
                                                            1000
                                          )
                                    );
                                    setRedirect(true);
                              }
                              if (response.data.result === 'error') {
                                    if (response.data.errorMessage) {
                                          toast.error(
                                                response.data.errorMessage
                                          );
                                    }
                                    if (response.data.message != null) {
                                          if (response.data.message.email) {
                                                toast.error(
                                                      response.data.message
                                                            .email
                                                );
                                          }
                                          if (response.data.message.password) {
                                                toast.error(
                                                      response.data.message
                                                            .password
                                                );
                                          }
                                    }
                              }
                        })
                        .catch((error) => {
                              toast.error(error.message);
                        });
                  setIsLoading(false);
            }

            setIsLoading(false);
      };

      if (redirect) {
            return <Navigate to={`/dashboard`} />;
      }
      const login = localStorage.getItem('isLoggedIn');
      if (login) {
            return <Navigate to={`/dashboard`} />;
      }

      return (
            <>
                  <LoginHeader>
                        <Row className="justify-content-center text-aligns-center g-0 w-100 h-100">
                              <Col xs={4} className="m-auto">
                                    <Card>
                                          <Card.Header>
                                                <Card.Title>Login</Card.Title>
                                          </Card.Header>
                                          <Card.Body>
                                                <Form
                                                      noValidate
                                                      validated={validated}
                                                      className="row g-3"
                                                      onSubmit={
                                                            handleButtonClick
                                                      }
                                                >
                                                      <Form.Group>
                                                            <Form.Label>
                                                                  Email
                                                            </Form.Label>
                                                            <Form.Control
                                                                  type="email"
                                                                  placeholder="Enter email"
                                                                  onChange={(
                                                                        event
                                                                  ) =>
                                                                        setEmail(
                                                                              event
                                                                                    .target
                                                                                    .value
                                                                        )
                                                                  }
                                                                  required={
                                                                        true
                                                                  }
                                                            />
                                                      </Form.Group>
                                                      <Form.Group>
                                                            <Form.Label>
                                                                  Password
                                                            </Form.Label>
                                                            <Form.Control
                                                                  type="password"
                                                                  placeholder="Enter password"
                                                                  onChange={(
                                                                        event
                                                                  ) =>
                                                                        setPassword(
                                                                              event
                                                                                    .target
                                                                                    .value
                                                                        )
                                                                  }
                                                                  required
                                                            />
                                                      </Form.Group>
                                                      <Button
                                                            variant="primary"
                                                            type="submit"
                                                      >
                                                            Login
                                                            {isLoading && (
                                                                  <Loading />
                                                            )}
                                                      </Button>
                                                </Form>
                                          </Card.Body>
                                    </Card>
                              </Col>
                        </Row>
                  </LoginHeader>
            </>
      );
};

export default Login;
