import { Table } from 'react-bootstrap';
import { styled } from 'styled-components';

export const QuotationTable = styled(Table)`
      tbody {
            tr {
                  border-left: 1px solid #f2f2f2;
                  border-right: 1px solid #f2f2f2;

                  &:nth-child(odd) {
                        border-bottom-color: transparent;
                  }

                  td {
                        border: 0;
                  }

                  &.bg-gray td {
                        background-color: #f2f2f2;
                  }

                  input {
                        border-radius: 0;
                  }

                  input::-webkit-outer-spin-button,
                  input::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                  }

                  input[type='number'] {
                        -moz-appearance: textfield;
                  }

                  &:nth-child(even) {
                        td {
                              padding: 0;

                              ul {
                                    color: #333;

                                    li::marker {
                                          color: #444;
                                    }

                                    li {
                                          font-size: 15px;
                                    }
                              }
                        }
                  }

                  img {
                        width: 120px;
                        height: 120px;
                        object-fit: contain;
                        float: right;
                        padding: 20px;
                        margin: 10px;
                        border: 1px solid #ddd;
                        background-color: ${(props) => props.theme.white};
                  }
            }
      }
`;
