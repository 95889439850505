import React, { useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import {
      CardHeader,
      CardLinks,
      CardTitle
} from '../../styles/components/common/Table';
import { LinkButton } from '../../styles/components/common/Button';

import axios from 'axios';
import { toast } from 'react-hot-toast';
import Input from '../../components/Form/Input';
import FormButton from '../../components/Form/Button';
import FormImage from '../../components/Form/image';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import FormSeo from '../../components/commons/FormSeo';
import Description from '../../components/Form/Description';

const PageEdit = () => {
      const { pageId } = useParams();

      const [page, setPage] = useState([]);
      const [title, setTitle] = useState('');
      const [slug, setSlug] = useState('');
      const [summary, setSummary] = useState('');
      const [description, setDescription] = useState('');
      const [image, setImage] = useState('');
      const [imageUrl, setImageUrl] = useState('');
      const [loading, setLoading] = useState(false);

      const [seoTitle, setSeoTitle] = useState(null);
      const [seoImage, setSeoImage] = useState('');
      const [seoImageUrl, setSeoImageUrl] = useState(null);
      const [seoKeyword, setSeoKeyword] = useState(null);
      const [seoDescription, setSeoDescription] = useState(null);

      const token = JSON.parse(localStorage.getItem('token'));
      const navigate = useNavigate();

      const loadData = async () => {
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/pages/${pageId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                                    'Content-Type': 'multipart/form-data'
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setPage(response.data.page);
                              setTitle(response.data.page.title);
                              setSlug(response.data.page.slug);
                              setSummary(response.data.page.summary);
                              setDescription(response.data.page.description);
                              setImageUrl(response.data.page.image);
                              setSeoTitle(response.data.page.seo_title);
                              setSeoKeyword(response.data.page.seo_keyword);
                              setSeoDescription(
                                    response.data.page.seo_description
                              );
                              setSeoImageUrl(response.data.page.seo_image);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };

      useEffect(() => {
            loadData();
      }, []);

      const handleForm = async (event) => {
            event.preventDefault();
            setLoading(true);
            const data = {
                  slug: slug,
                  title: title,
                  summary: summary,
                  description: description,
                  image: image,
                  seo_title: seoTitle,
                  seo_image: seoImage,
                  seo_keyword: seoKeyword,
                  seo_description: seoDescription
            };

            let updatedSlug = false;

            const handleUpdateSlug = () => {
                  if (updatedSlug) {
                        navigate(`/dashboard/pages/${updatedSlug}/edit`);
                  }
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/pages/${pageId}`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                                    'Content-Type': 'multipart/form-data'
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              toast.success(response.data.message);
                              updatedSlug = slug;
                              handleUpdateSlug();
                        }
                        if (response.data.result === 'error') {
                              if (response.data.message.title) {
                                    toast.error(response.data.message.title);
                              }
                              if (response.data.message.setSlug) {
                                    toast.error(response.data.message.setSlug);
                              }
                              if (response.data.message.summary) {
                                    toast.error(response.data.message.summary);
                              }
                              if (response.data.message.description) {
                                    toast.error(
                                          response.data.message.description
                                    );
                              }
                              if (response.data.message.image) {
                                    toast.error(response.data.message.image);
                              }
                              if (response.data.message.seo_image) {
                                    toast.error(
                                          response.data.message.seo_image
                                    );
                              }
                        }
                        setLoading(false);
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };

      return (
            <>
                  <Form onSubmit={handleForm}>
                        <Row>
                              <Col lg={12}>
                                    <Card>
                                          <CardHeader>
                                                <CardTitle>Edit Page</CardTitle>
                                                <CardLinks>
                                                      <LinkButton to="/dashboard/pages">
                                                            Back
                                                      </LinkButton>
                                                </CardLinks>
                                          </CardHeader>
                                          <Card.Body>
                                                <Row className="g-3">
                                                      <Input
                                                            title="Title"
                                                            classes={8}
                                                            required={true}
                                                            value={title}
                                                            handleChange={
                                                                  setTitle
                                                            }
                                                      />
                                                      <Input
                                                            title="Slug"
                                                            classes={4}
                                                            required={true}
                                                            value={slug}
                                                            handleChange={
                                                                  setSlug
                                                            }
                                                      />
                                                      <Description
                                                            description={
                                                                  description
                                                            }
                                                            title="Desciption"
                                                            required={true}
                                                            setDescription={
                                                                  setDescription
                                                            }
                                                      />
                                                </Row>
                                          </Card.Body>
                                    </Card>
                                    <Card className="mt-4">
                                          <Card.Body>
                                                <Row className="g-3">
                                                      <FormImage
                                                            title="Featured Image"
                                                            setImage={setImage}
                                                            setImageUrl={
                                                                  setImageUrl
                                                            }
                                                            required={false}
                                                            imageUrl={imageUrl}
                                                      />
                                                </Row>
                                          </Card.Body>
                                    </Card>
                                    <FormSeo
                                          seoImageUrl={seoImageUrl}
                                          setSeoImage={setSeoImage}
                                          setSeoImageUrl={setSeoImageUrl}
                                          setSeoDescription={setSeoDescription}
                                          setSeoTitle={setSeoTitle}
                                          setSeoKeyword={setSeoKeyword}
                                          description={seoDescription}
                                          keyword={seoKeyword}
                                          title={seoTitle}
                                    />
                                    <Card className="mt-4 mb-5">
                                          <Card.Body>
                                                <Row>
                                                      <Col lg={12}>
                                                            <FormButton
                                                                  title="Submit"
                                                                  loading={
                                                                        loading
                                                                  }
                                                            />
                                                      </Col>
                                                </Row>
                                          </Card.Body>
                                    </Card>
                              </Col>
                        </Row>
                  </Form>
            </>
      );
};

export default PageEdit;
