import React from 'react';
import { CardHeaderBox, CardLinks, CardTitle } from './styles';

const CardHeader = ({ title, children }) => {
      return (
            <>
                  <CardHeaderBox>
                        <CardTitle>{title}</CardTitle>
                        {children && <CardLinks>{children}</CardLinks>}
                  </CardHeaderBox>
            </>
      );
};

export default CardHeader;
