import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import { CardHeader, CardTitle } from '../../styles/components/common/Table';
import { LinkButton } from '../../styles/components/common/Button';
import Input from '../../components/Form/Input';
import Select from '../../components/Form/Select';
import FormImage from '../../components/Form/image';
import FormButton from '../../components/Form/Button';
import Textarea from '../../components/Form/Textarea';
import { useParams } from 'react-router-dom';

const BannerEdit = () => {
      const { bannerId } = useParams();

      const [loading, setLoading] = useState(false);

      const [banner, setBanner] = useState([]);
      const [loadingData, setLoadingData] = useState(false);

      const [title, setTitle] = useState('');
      const [image, setImage] = useState('');
      const [imageUrl, setImageUrl] = useState('');
      const [description, setDescription] = useState('');
      const [status, setStatus] = useState(false);

      const loadData = async () => {
            setLoadingData(true);
            axios.get(
                  `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/banners/${bannerId}`,
                  {
                        headers: {
                              Authorization: `Bearer ${JSON.parse(
                                    localStorage.getItem('token')
                              )}`
                        }
                  }
            )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setBanner(response.data.banner);
                              setTitle(response.data.banner.title);
                              setImageUrl(response.data.banner.image);
                              setDescription(response.data.banner.description);
                              setStatus(response.data.banner.status);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoadingData(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      const handleSubmit = async (event) => {
            event.preventDefault();
            setLoading(true);

            const data = {
                  title: title ? title : banner.title,
                  image: image,
                  description: description ? description : banner.description,
                  status: status ? status : banner.status
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/banners/${bannerId}`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`,
                                    'Content-Type': 'multipart/form-data'
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              toast.success(response.data.message);
                        }
                        if (response.data.result === 'error') {
                              if (response.data.message.status) {
                                    toast.error(response.data.message.status);
                              }
                              if (response.data.message.title) {
                                    toast.error(response.data.message.title);
                              }
                              if (response.data.message.description) {
                                    toast.error(
                                          response.data.message.description
                                    );
                              }
                              if (response.data.message.image) {
                                    toast.error(response.data.message.image);
                              }
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      return (
            <>
                  <Form onSubmit={handleSubmit}>
                        <Row>
                              <Col lg={12}>
                                    <Card>
                                          <CardHeader>
                                                <CardTitle>
                                                      Create new Banner
                                                </CardTitle>
                                                <LinkButton to="/dashboard/banners">
                                                      Back
                                                </LinkButton>
                                          </CardHeader>
                                          <Card.Body>
                                                <Row className="g-3">
                                                      <Input
                                                            title="Title"
                                                            classes={8}
                                                            value={title}
                                                            required={true}
                                                            handleChange={
                                                                  setTitle
                                                            }
                                                      />
                                                      <Select
                                                            title="Status"
                                                            classes={4}
                                                            selected={status}
                                                            setStatus={
                                                                  setStatus
                                                            }
                                                      />
                                                      <Textarea
                                                            title="Description"
                                                            required={true}
                                                            value={description}
                                                            handleChange={
                                                                  setDescription
                                                            }
                                                      />
                                                </Row>
                                          </Card.Body>
                                    </Card>

                                    <Card className="mt-4">
                                          <Card.Body>
                                                <Row className="g-3">
                                                      <FormImage
                                                            title="Banner Image"
                                                            setImage={setImage}
                                                            setImageUrl={
                                                                  setImageUrl
                                                            }
                                                            grid={2}
                                                            required={true}
                                                            imageUrl={imageUrl}
                                                      />
                                                </Row>
                                          </Card.Body>
                                    </Card>

                                    <Card className="mt-4 mb-5">
                                          <Card.Body>
                                                <Row>
                                                      <Col lg={12}>
                                                            <FormButton
                                                                  title="Submit"
                                                                  loading={
                                                                        loading
                                                                  }
                                                            />
                                                      </Col>
                                                </Row>
                                          </Card.Body>
                                    </Card>
                              </Col>
                        </Row>
                  </Form>
            </>
      );
};

export default BannerEdit;
