import React from 'react';
import { Card, Form, Table } from 'react-bootstrap';
import { CardHeader, CardTitle } from '../../styles/components/common/Table';
import { CardBody } from 'reactstrap';
import TableSearch from '../Form/Search';
import {  EditIcon, ShowIcon } from '../icons';
import ProductImage from '../Form/ProductImage';


import TableFooter from '../Form/TableFooter';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import DeleteButton from '../commons/DeleteButton';

const ProductList = ({
      loadData,
      loading,
      setLoading,
      products,
      setProducts,
      totalProducts,
      perPages,
      currentPage,
      setLimit,
      setSearch,
      getProductData

}) => {
      const token = JSON.parse(localStorage.getItem('token'));

      const handleStatus = async (product) => {
            const data = {
                  id: product.id
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/products/status/update`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              toast.success(response.data.message);
                        }
                        if (response.data.result === 'error') {
                              toast.error(response.data.message);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };

      const handleDelete = async (product) => {
            setLoading(true);
            await axios
                  .delete(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/products/${product.slug}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setProducts([]);
                              loadData();
                              toast.success(response.data.message);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      return (
            <>
                  <Card className="mt-4">
                        <CardHeader>
                              <CardTitle>Product List</CardTitle>
                        </CardHeader>
                        <CardBody>
                              <TableSearch
                                    setLimit={setLimit}
                                    setSearch={setSearch}
                              />
                              <Table hover bordered>
                                    <thead>
                                          <tr>
                                                <th>Title</th>
                                                <th>Product Code</th>
                                                <th>Brand</th>
                                                <th>Price</th>
                                                <th>Stock</th>
                                                <th width="80px">Status</th>
                                                <th width="100px">Action</th>
                                          </tr>
                                    </thead>
                                    <tbody>
                                          {!loading ? (
                                                products.length > 0 ? (
                                                      products.map(
                                                            (
                                                                  product,
                                                                  index
                                                            ) => (
                                                                  <>
                                                                        <tr
                                                                              key={
                                                                                    index
                                                                              }
                                                                        >
                                                                              {/* <td>{product.index}</td> */}
                                                                              <td>
                                                                                    <ProductImage
                                                                                          image={
                                                                                                product.image
                                                                                          }
                                                                                          title={
                                                                                                product.title
                                                                                          }
                                                                                          category={
                                                                                                product.category
                                                                                          }
                                                                                    />
                                                                              </td>
                                                                              <td>
                                                                                    {
                                                                                          product.productCode
                                                                                    }
                                                                              </td>
                                                                              <td>
                                                                                    {
                                                                                          product.brand_name
                                                                                    }
                                                                              </td>
                                                                              <td>
                                                                                    Rs{' '}
                                                                                    {
                                                                                          product.price
                                                                                    }
                                                                              </td>
                                                                              <td>
                                                                                    {
                                                                                          product.stock
                                                                                    }
                                                                              </td>
                                                                              <td>
                                                                                    <Form.Check
                                                                                          type="switch"
                                                                                          id="custom-switch"
                                                                                          defaultChecked={
                                                                                                product.status ===
                                                                                                1
                                                                                                      ? true
                                                                                                      : false
                                                                                          }
                                                                                          onClick={() =>
                                                                                                handleStatus(
                                                                                                      product
                                                                                                )
                                                                                          }
                                                                                    />
                                                                              </td>
                                                                              <td>
                                                                                    <Link
                                                                                          to={`/dashboard/products/${product.slug}/edit`}
                                                                                          className="text-secondary"
                                                                                    >
                                                                                          <EditIcon />
                                                                                    </Link>
                                                                                    <Link
                                                                                          to={`/dashboard/products/${product.slug}/show`}
                                                                                          className="text-success mx-2"
                                                                                    >
                                                                                          <ShowIcon />
                                                                                    </Link>
                                                                                    <DeleteButton
                                                                                          item={
                                                                                                product
                                                                                          }
                                                                                          handleDelete={
                                                                                                handleDelete
                                                                                          }
                                                                                          loading={
                                                                                                loading
                                                                                          }
                                                                                          setLoading={
                                                                                                setLoading
                                                                                          }
                                                                                    />
                                                                              </td>
                                                                        </tr>
                                                                  </>
                                                            )
                                                      )
                                                ) : (
                                                      <>
                                                            <tr>
                                                                  <td
                                                                        colSpan={
                                                                              7
                                                                        }
                                                                        className="text-center"
                                                                  >
                                                                        No
                                                                        Product
                                                                        Found
                                                                  </td>
                                                            </tr>
                                                      </>
                                                )
                                          ) : (
                                                <>
                                                      <tr>
                                                            <td
                                                                  colSpan={7}
                                                                  className="text-center"
                                                            >
                                                                  Loading
                                                            </td>
                                                      </tr>
                                                </>
                                          )}
                                    </tbody>
                              </Table>

                              <TableFooter
                                    perPages={perPages}
                                    totalItems={totalProducts}
                                    currentPage={currentPage}
                                    getData={getProductData}
                              />
                        </CardBody>
                  </Card>
            </>
      );
};

export default ProductList;
