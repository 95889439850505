import { NavLink } from 'react-router-dom';
import { styled } from 'styled-components';

export const Header = styled.header``;

export const Sidebar = styled.div`
      max-width: 250px;
      position: fixed;
      width: 250px;
      overflow-y: auto;
      height: 100vh;

      ul li {
            padding-left: 0;
      }

      &::-webkit-scrollbar {
            width: 5px;
      }

      &::-webkit-scrollbar-thumb {
            background: #828bb2;
            border-radius: 10px;
      }

      &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px grey;
            border-radius: 10px;
      }
`;

export const SidebarNav = styled.nav`
      ul {
            li {
                  ul {
                        padding: 0 0 0 20px;
                        margin-left: 32px;
                        border-left: 1px solid
                              ${(props) => props.theme.borderColor};
                        list-style: none;

                        li {
                              padding: 4px 10px;
                              position: relative;

                              a {
                                    color: ${(props) =>
                                          props.theme.secondaryColor};
                                    font-size: 13px;
                                    text-transform: capitalize;
                                    font-weight: 400;
                                    margin-bottom: 5px;
                              }

                              a.sub-active {
                                    &::after {
                                          content: '';
                                          position: absolute;
                                          width: 8px;
                                          height: 8px;
                                          left: -10px;
                                          top: 16.5px;
                                          display: block;
                                          border-radius: 50%;
                                          background: ${(props) =>
                                                props.theme.primaryColor};
                                    }

                                    &::before {
                                          content: '';
                                          position: absolute;
                                          width: 15px;
                                          height: 2px;
                                          background: ${(props) =>
                                                props.theme.primaryColor};
                                          left: -20px;
                                          top: 19.5px;
                                          display: block;
                                    }
                              }
                        }
                  }
            }
      }
`;

export const SidebarHeader = styled.div`
      border-bottom: 1px solid ${(props) => props.theme.borderColor};
      padding: 10px 20px;
      background: ${(props) => props.theme.white};
      position: fixed;
      width: 250px;
      z-index: 1;
      text-align: center;

      img {
            max-width: 180px;
            height: 45px;
      }
`;

export const MetisMenu = styled.ul`
      padding: 0;
      padding-top: 65px;

      li {
            a {
            }
      }
`;

export const MetisMenuLink = styled(NavLink)`
      display: flex;
      align-items: center;
      padding: 4px 7px 4px 28px;
      font-weight: 400;
      color: ${(props) => props.theme.linkColor};
      text-decoration: none;

      &:hover,
      &:active {
            background-color: ${(props) => props.theme.primaryColor};
            color: ${(props) => props.theme.white};
      }
`;

export const MetisMenuTitle = styled.div`
      width: 100%;
      span {
            text-decoration: none !important;
            font-size: 14px;
      }
`;

export const MetisMenuIcon = styled.div`
      i,
      span {
            width: 30px;
            font-size: 20px;
      }
`;

export const MenuSeparator = styled.div`
      color: #68798b;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      font-weight: 700;
      font-size: 12px;
      font-weight: 400;
      opacity: 0.7;
      display: block;
      margin: 24px 0 3px 15px;
`;
