import React from 'react';
import {
      MenuSeparator,
      MetisMenuIcon,
      MetisMenuLink,
      MetisMenuTitle
} from '../../styles/layouts/navigation';
import { NavLink } from 'react-router-dom';
import CheckMultiplePermission from '../roles/CheckMultiplePermission';

const ProductManage = ({ userPermissions }) => {
      return (
            <>
                  <CheckMultiplePermission
                        userPermissions={userPermissions}
                        permissions={[
                              'product-list',
                              'product-create',
                              'product-edit',
                              'product-show',
                              'trending-product-list',
                              'trending-product-create',
                              'trending-product-edit',
                              'trending-product-show',
                              'brand-list',
                              'brand-edit',
                              'brand-create',
                              'brand-show',
                              'category-list',
                              'category-create',
                              'category-edit',
                              'category-show',
                              'product-size-list',
                              'product-size-create',
                              'product-size-edit',
                              'unit-list',
                              'unit-create',
                              'unit-show',
                              'unit-edit',
                              'showroom-list',
                              'showroom-create',
                              'showroom-edit',
                              'showroom-delete',
                              'showroom-collection-list',
                              'showroom-collection-create',
                              'showroom-collection-edit',
                              'showroom-collection-delete',
                              'order-all',
                              'order-pending',
                              'order-processing',
                              'order-shipping',
                              'order-rejected',
                              'order-cancelled',
                              'order-delivered',
                              'order-delete'
                        ]}
                  >
                        <MenuSeparator>Product Manage</MenuSeparator>
                        <CheckMultiplePermission
                              userPermissions={userPermissions}
                              permissions={[
                                    'product-list',
                                    'product-create',
                                    'product-edit',
                                    'product-show',
                                    'trending-product-list',
                                    'trending-product-create',
                                    'trending-product-edit',
                                    'trending-product-show',
                                    'brand-list',
                                    'brand-edit',
                                    'brand-create',
                                    'brand-show',
                                    'category-list',
                                    'category-create',
                                    'category-edit',
                                    'category-show',
                                    'product-size-list',
                                    'product-size-create',
                                    'product-size-edit',
                                    'unit-list',
                                    'unit-create',
                                    'unit-show',
                                    'unit-edit'
                              ]}
                        >
                              <li>
                                    <MetisMenuLink
                                          className="has-arrow"
                                          href="#"
                                    >
                                          <MetisMenuIcon>
                                                <span className="material-symbols-outlined">
                                                      inventory
                                                </span>
                                          </MetisMenuIcon>
                                          <MetisMenuTitle>
                                                <span>Products Manage</span>
                                          </MetisMenuTitle>
                                    </MetisMenuLink>
                                    <ul>
                                          <CheckMultiplePermission
                                                userPermissions={
                                                      userPermissions
                                                }
                                                permissions={[
                                                      'brand-list',
                                                      'brand-edit',
                                                      'brand-create',
                                                      'brand-show'
                                                ]}
                                          >
                                                <li>
                                                      <NavLink to="/dashboard/brands">
                                                            Brand
                                                      </NavLink>
                                                </li>
                                          </CheckMultiplePermission>
                                          <CheckMultiplePermission
                                                userPermissions={
                                                      userPermissions
                                                }
                                                permissions={[
                                                      'category-list',
                                                      'category-create',
                                                      'category-edit',
                                                      'category-show'
                                                ]}
                                          >
                                                <li>
                                                      <NavLink to="/dashboard/categories">
                                                            Category
                                                      </NavLink>
                                                </li>
                                          </CheckMultiplePermission>
                                          <CheckMultiplePermission
                                                userPermissions={
                                                      userPermissions
                                                }
                                                permissions={[
                                                      'product-list',
                                                      'product-create',
                                                      'product-edit',
                                                      'product-show',
                                                      'product-size-list',
                                                      'product-size-create',
                                                      'product-size-edit',
                                                      'unit-list',
                                                      'unit-create',
                                                      'unit-show',
                                                      'unit-edit'
                                                ]}
                                          >
                                                <li>
                                                      <NavLink to="/dashboard/products">
                                                            Product List
                                                      </NavLink>
                                                </li>
                                          </CheckMultiplePermission>
                                          <CheckMultiplePermission
                                                userPermissions={
                                                      userPermissions
                                                }
                                                permissions={[
                                                      'trending-product-list',
                                                      'trending-product-create',
                                                      'trending-product-edit',
                                                      'trending-product-show'
                                                ]}
                                          >
                                                <li>
                                                      <NavLink to="/dashboard/trending_products">
                                                            Trending Product
                                                      </NavLink>
                                                </li>
                                          </CheckMultiplePermission>
                                    </ul>
                              </li>
                        </CheckMultiplePermission>
                        <li>
                              <MetisMenuLink className="has-arrow" href="#">
                                    <MetisMenuIcon>
                                          <span className="material-symbols-outlined">
                                                image
                                          </span>
                                    </MetisMenuIcon>
                                    <MetisMenuTitle>
                                          <span>Image Gallery</span>
                                    </MetisMenuTitle>
                              </MetisMenuLink>
                              <ul>
                                    <li>
                                          <NavLink to="/dashboard/images/create">
                                                Upload Image
                                          </NavLink>
                                    </li>
                              </ul>
                        </li>
                        <CheckMultiplePermission
                              userPermissions={userPermissions}
                              permissions={[
                                    'showroom-list',
                                    'showroom-create',
                                    'showroom-edit',
                                    'showroom-delete',
                                    'showroom-collection-list',
                                    'showroom-collection-edit',
                                    'showroom-collection-create',
                                    'showroom-collection-deleyte'
                              ]}
                        >
                              <li>
                                    <MetisMenuLink
                                          className="has-arrow"
                                          href="#"
                                    >
                                          <MetisMenuIcon>
                                                <span className="material-symbols-outlined">
                                                      inventory
                                                </span>
                                          </MetisMenuIcon>
                                          <MetisMenuTitle>
                                                <span>Showroom Manage</span>
                                          </MetisMenuTitle>
                                    </MetisMenuLink>
                                    <ul>
                                          <CheckMultiplePermission
                                                userPermissions={
                                                      userPermissions
                                                }
                                                permissions={[
                                                      'showroom-list',
                                                      'showroom-edit',
                                                      'showroom-create',
                                                      'showroom-show'
                                                ]}
                                          >
                                                <li>
                                                      <NavLink to="/dashboard/showrooms">
                                                            Showroom List
                                                      </NavLink>
                                                </li>
                                          </CheckMultiplePermission>
                                          <CheckMultiplePermission
                                                userPermissions={
                                                      userPermissions
                                                }
                                                permissions={[
                                                      'showroom-collection-list',
                                                      'showroom-collection-create',
                                                      'showroom-collection-edit',
                                                      'showroom-collection-delete'
                                                ]}
                                          >
                                                <li>
                                                      <NavLink to="/dashboard/showroom_collections">
                                                            Collection List
                                                      </NavLink>
                                                </li>
                                          </CheckMultiplePermission>
                                    </ul>
                              </li>
                        </CheckMultiplePermission>
                        <CheckMultiplePermission
                              userPermissions={userPermissions}
                              permissions={[
                                    'order-all',
                                    'order-pending',
                                    'order-processing',
                                    'order-shipping',
                                    'order-rejected',
                                    'order-cancelled',
                                    'order-delivered',
                                    'order-delete'
                              ]}
                        >
                              <li>
                                    <MetisMenuLink
                                          className="has-arrow"
                                          href="#"
                                    >
                                          <MetisMenuIcon>
                                                <span className="material-symbols-outlined">
                                                      shopping_cart
                                                </span>
                                          </MetisMenuIcon>
                                          <MetisMenuTitle>
                                                <span>Order Manage</span>
                                          </MetisMenuTitle>
                                    </MetisMenuLink>
                                    <ul>
                                          <CheckMultiplePermission
                                                userPermissions={
                                                      userPermissions
                                                }
                                                permissions={['order-all']}
                                          >
                                                <li>
                                                      <NavLink to="/dashboard/orders">
                                                            All Order List
                                                      </NavLink>
                                                </li>
                                          </CheckMultiplePermission>
                                          <CheckMultiplePermission
                                                userPermissions={
                                                      userPermissions
                                                }
                                                permissions={['order-pending']}
                                          >
                                                <li>
                                                      <NavLink to="/dashboard/pending/orders">
                                                            Pending Order
                                                      </NavLink>
                                                </li>
                                          </CheckMultiplePermission>
                                          <CheckMultiplePermission
                                                userPermissions={
                                                      userPermissions
                                                }
                                                permissions={[
                                                      'order-processing'
                                                ]}
                                          >
                                                <li>
                                                      <NavLink to="/dashboard/processing/orders">
                                                            Processing Order
                                                      </NavLink>
                                                </li>
                                          </CheckMultiplePermission>
                                          <CheckMultiplePermission
                                                userPermissions={
                                                      userPermissions
                                                }
                                                permissions={['order-shipping']}
                                          >
                                                <li>
                                                      <NavLink to="/dashboard/shipping/orders">
                                                            Shipping Order
                                                      </NavLink>
                                                </li>
                                          </CheckMultiplePermission>
                                          <CheckMultiplePermission
                                                userPermissions={
                                                      userPermissions
                                                }
                                                permissions={['order-rejected']}
                                          >
                                                <li>
                                                      <NavLink to="/dashboard/rejected/orders">
                                                            Rejected Order
                                                      </NavLink>
                                                </li>
                                          </CheckMultiplePermission>
                                          <CheckMultiplePermission
                                                userPermissions={
                                                      userPermissions
                                                }
                                                permissions={[
                                                      'order-cancelled'
                                                ]}
                                          >
                                                <li>
                                                      <NavLink to="/dashboard/cancelled/orders">
                                                            Cancelled Order
                                                      </NavLink>
                                                </li>
                                          </CheckMultiplePermission>
                                          <CheckMultiplePermission
                                                userPermissions={
                                                      userPermissions
                                                }
                                                permissions={[
                                                      'order-delivered'
                                                ]}
                                          >
                                                <li>
                                                      <NavLink to="/dashboard/delivered/orders">
                                                            Delivered Order
                                                      </NavLink>
                                                </li>
                                          </CheckMultiplePermission>
                                    </ul>
                              </li>
                        </CheckMultiplePermission>
                  </CheckMultiplePermission>
            </>
      );
};

export default ProductManage;
