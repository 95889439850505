import { Card, ListGroup } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const MainDashboard = styled.div`
      display: flex;
      border: 1px solid rgba(0, 0, 0, 0.175);
      background-color: ${(props) => props.theme.white} !important;
`;

export const ProfileContainer = styled.div``;

export const ProfileSidebar = styled(Card)`
      min-height: 100%;
      padding: 0;
      border-radius: 0;
      width: 250px;
      border: 0;
`;

export const ProfileCardBody = styled(Card.Body)`
      padding-left: 5px;
      padding-top: 10px;
      padding: 0px;
      text-align: center;
`;

export const ProfileListGroup = styled(ListGroup)`
      --bs-list-group-border-width: 0 !important;

      .list-group-item:hover {
            background-color: rgba(159, 157, 157, 0.2);
            border-radius: 0;
      }

      .list-group-item {
            padding: 10px 20px;
            width: 100%;

            &:hover {
                  background-color: rgba(159, 157, 157, 0.2);
                  border-radius: 0;
            }
      }

      .list-group-item.active {
            border-left: 3px solid black;
            background-color: transparent !important;
            color: ${(props) => props.theme.primaryColor};
            border-radius: 0 !important;
      }

      .list-group-item > .active {
            border-left: 3px solid black;
      }
`;

export const ProfileContent = styled.div`
      width: 100%;
      background-color: #fff;
      border-left: 1px solid rgba(0, 0, 0, 0.175) !important;
      border-color: rgba(0, 0, 0, 0.175);
      min-height: calc(100vh - 120px);
      padding: 10px 20px;
`;
