import React from 'react';
import TableSearch from '../TableSearch';
import TableFooter from '../TableFooter';
import { Card, Table } from 'react-bootstrap';

const CardBody = ({
      setLimit,
      perPages,
      totalItems,
      currentPage,
      getData,
      setSearch,
      children
}) => {
      return (
            <>
                  <Card.Body>
                        <TableSearch
                              setLimit={setLimit}
                              setSearch={setSearch}
                        />

                        <Table hover bordered>
                              {children}
                        </Table>

                        <TableFooter
                              perPages={perPages}
                              totalItems={totalItems}
                              currentPage={currentPage}
                              getData={getData}
                        />
                  </Card.Body>
            </>
      );
};

export default CardBody;
