import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Table } from 'react-bootstrap';
import {
      CardHeader,
      CardLinks,
      CardTitle
} from '../../styles/components/common/Table';
import { LinkButton } from '../../styles/components/common/Button';
import TableSearch from '../../components/Form/Search';
import axios from 'axios';
import TableFooter from '../../components/Form/TableFooter';
import { toast } from 'react-hot-toast';
import Progress from 'react-progress-2';
import { Loading } from '../../components/Loading';
import ProductImage from '../../components/Form/ProductImage';
import ItemStatus from '../../components/Form/Status';
import { Link } from 'react-router-dom';
import { EditIcon, ShowIcon } from '../../components/icons';
import DeleteButton from '../../components/commons/DeleteButton';

const CategoryList = () => {
      const [loading, setLoading] = useState(false);
      const [categories, setCategories] = useState([]);
      const [perPages, setPerPages] = useState(0);
      const [currentPage, setCurrentPage] = useState(0);
      const [totalCategories, setTotalCategories] = useState(0);

      const token = JSON.parse(localStorage.getItem('token'));
      const [limit, setLimit] = useState(10);
      const [search, setSearch] = useState('');

      const fetchData = async () => {
            setLoading(true);

            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/categories?limit=${limit}&search=${search}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`
                              }
                        }
                  )
                  .then((response) => {
                        setCategories(response.data.categories);
                        setTotalCategories(response.data.totalCategories);
                        setPerPages(response.data.itemsCountPerPage);
                        setCurrentPage(response.data.currentPage);
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            fetchData();
      }, [limit, search]);

      const handleStatus = async (category) => {
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/categories/${category.slug}/status`,
                        {},
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              toast.success(response.data.message);
                        }
                        if (response.data.result === 'error') {
                              toast.error(response.data.message);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };

      async function getCategoryData(pageNumber = 1) {
            window.scrollTo(0, 0);
            setLoading(true);
            Progress.show();
            const url = `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/categories?page=${pageNumber}&limit=${limit}&search=${search}`;
            await axios(url, {
                  headers: {
                        Authorization: `Bearer ${token}`
                  }
            })
                  .then((response) => {
                        setCategories(response.data.categories);
                        setTotalCategories(response.data.totalCategories);
                        setPerPages(response.data.itemsCountPerPage);
                        setCurrentPage(response.data.currentPage);
                        Progress.hide();
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      }

      const handleDelete = async (category) => {
            setLoading(true);
            await axios
                  .delete(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/categories/${category.slug}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setCategories([]);
                              fetchData();
                              toast.success(response.data.message);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      return (
            <>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader>
                                          <CardTitle>Category List</CardTitle>
                                          <CardLinks>
                                                <LinkButton to="/dashboard/categories/create">
                                                      Add New
                                                </LinkButton>
                                          </CardLinks>
                                    </CardHeader>
                                    <Card.Body>
                                          <TableSearch
                                                setLimit={setLimit}
                                                setSearch={setSearch}
                                          />

                                          <Table hover bordered>
                                                <thead>
                                                      <tr>
                                                            <th>#</th>
                                                            <th>
                                                                  Category Name
                                                            </th>
                                                            <th>Products</th>
                                                            <th>Menu Status</th>
                                                            <th>Status</th>
                                                            <th width="120px">
                                                                  Action
                                                            </th>
                                                      </tr>
                                                </thead>
                                                <tbody>
                                                      {!loading ? (
                                                            categories.length >
                                                            0 ? (
                                                                  categories.map(
                                                                        (
                                                                              category,
                                                                              index
                                                                        ) => (
                                                                              <>
                                                                                    <tr
                                                                                          key={
                                                                                                index
                                                                                          }
                                                                                    >
                                                                                          <td>
                                                                                                {
                                                                                                      category.index
                                                                                                }
                                                                                          </td>
                                                                                          <td>
                                                                                                <ProductImage
                                                                                                      title={
                                                                                                            category.title
                                                                                                      }
                                                                                                      image={
                                                                                                            category.image
                                                                                                      }
                                                                                                      category={
                                                                                                            category.parent
                                                                                                      }
                                                                                                />
                                                                                          </td>
                                                                                          <td>
                                                                                                {category.products
                                                                                                      ? category.products
                                                                                                      : 0}
                                                                                          </td>
                                                                                          <td>
                                                                                                <ItemStatus
                                                                                                      item={
                                                                                                            category
                                                                                                      }
                                                                                                      type={
                                                                                                            true
                                                                                                      }
                                                                                                      handleStatus={
                                                                                                            handleStatus
                                                                                                      }
                                                                                                />
                                                                                          </td>
                                                                                          <td>
                                                                                                <ItemStatus
                                                                                                      item={
                                                                                                            category
                                                                                                      }
                                                                                                      handleStatus={
                                                                                                            handleStatus
                                                                                                      }
                                                                                                />
                                                                                          </td>
                                                                                          <td className="text-center">
                                                                                                <Link
                                                                                                      to={`/dashboard/categories/${category.slug}/edit`}
                                                                                                      className="text-secondary"
                                                                                                >
                                                                                                      <EditIcon />
                                                                                                </Link>
                                                                                                <Link
                                                                                                      to={`/dashboard/categories/${category.slug}/show`}
                                                                                                      className="text-success mx-2"
                                                                                                >
                                                                                                      <ShowIcon />
                                                                                                </Link>
                                                                                                <DeleteButton
                                                                                                      loading={
                                                                                                            loading
                                                                                                      }
                                                                                                      setLoading={
                                                                                                            setLoading
                                                                                                      }
                                                                                                      handleDelete={
                                                                                                            handleDelete
                                                                                                      }
                                                                                                      item={
                                                                                                            category
                                                                                                      }
                                                                                                />
                                                                                          </td>
                                                                                    </tr>
                                                                              </>
                                                                        )
                                                                  )
                                                            ) : (
                                                                  <>
                                                                        <tr>
                                                                              <td
                                                                                    colSpan={
                                                                                          6
                                                                                    }
                                                                                    className="text-center"
                                                                              >
                                                                                    No
                                                                                    Category
                                                                                    Found
                                                                              </td>
                                                                        </tr>
                                                                  </>
                                                            )
                                                      ) : (
                                                            <>
                                                                  <tr>
                                                                        <td
                                                                              colSpan={
                                                                                    6
                                                                              }
                                                                              className="text-center"
                                                                        >
                                                                              <Loading />
                                                                        </td>
                                                                  </tr>
                                                            </>
                                                      )}
                                                </tbody>
                                          </Table>

                                          <TableFooter
                                                perPages={perPages}
                                                totalItems={totalCategories}
                                                currentPage={currentPage}
                                                getData={getCategoryData}
                                          />
                                    </Card.Body>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default CategoryList;
