import React from 'react';
import { Col, Form } from 'react-bootstrap';

const Textarea = ({ title, required, handleChange, value }) => {
      return (
            <>
                  <Col lg={12}>
                        <Form.Label>
                              {title}{' '}
                              {required && (
                                    <span className="text-danger">*</span>
                              )}
                        </Form.Label>
                        <Form.Control
                              as="textarea"
                              onChange={(event) =>
                                    handleChange(event.target.value)
                              }
                              value={value}
                              rows={4}
                        />
                  </Col>
            </>
      );
};

export default Textarea;
