import React from 'react';
import CheckPermission from '../roles/CheckPermission';
import {
      MenuSeparator,
      MetisMenuIcon,
      MetisMenuLink,
      MetisMenuTitle
} from '../../styles/layouts/navigation';

const DashboardManage = ({ userPermissions }) => {
      return (
            <>
                  <CheckPermission
                        userPermissions={userPermissions}
                        permission="Dashboard"
                  >
                        <MenuSeparator>Dashboard</MenuSeparator>
                        <li>
                              <MetisMenuLink to="/dashboard">
                                    <MetisMenuIcon>
                                          <span className="material-symbols-outlined">
                                                widgets
                                          </span>
                                    </MetisMenuIcon>
                                    <MetisMenuTitle>
                                          <span>Dashboard</span>
                                    </MetisMenuTitle>
                              </MetisMenuLink>
                        </li>
                  </CheckPermission>
            </>
      );
};

export default DashboardManage;
