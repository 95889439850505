import React, { useEffect, useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import {
      CardHeader,
      CardLinks,
      CardTitle
} from '../../styles/components/common/Table';

import { LinkButton } from '../../styles/components/common/Button';
import Input from '../../components/Form/Input';
import FormButton from '../../components/Form/Button';
import axios from 'axios';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';

function ContactEdit() {
      const { contactId } = useParams();
      const [loadingData, setLoadingData] = useState(false);

      const [firstName, setFirstName] = useState('');
      const [lastName, setLastName] = useState('');
      const [phoneNumber, setPhoneNumber] = useState('');
      const [address, setAddress] = useState('');
      const [email, setEmail] = useState('');
      const [position, setPosition] = useState('');
      const [companyName, setCompanyName] = useState('');
      const [panNumber, setPanNumber] = useState('');
      const [projectName, setProjectName] = useState(null);
      const [loading, setLoading] = useState(false);

      const token = JSON.parse(localStorage.getItem('token'));

      const loadData = async () => {
            setLoadingData(true);

            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/contact_persons/${contactId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                                    'Content-Type': 'multipart/form-data'
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setFirstName(response.data.contact.first_name);
                              setLastName(response.data.contact.last_name);
                              setAddress(response.data.contact.address);
                              setPhoneNumber(
                                    response.data.contact.phone_number
                              );
                              setEmail(response.data.contact.email);
                              setCompanyName(
                                    response.data.contact.company_name
                              );
                              setPanNumber(response.data.contact.pan_number);
                              setProjectName(
                                    response.data.contact.project_name
                              );
                              setPosition(response.data.contact.position);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoadingData(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      const handleSubmit = async (event) => {
            event.preventDefault();
            setLoading(true);

            const data = {
                  first_name: firstName,
                  last_name: lastName,
                  phone_number: phoneNumber,
                  address: address,
                  email: email,
                  position: position,
                  company_name: companyName,
                  pan_number: panNumber,
                  project_name: projectName
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/contact_persons/${contactId}`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                                    'Content-Type': 'multipart/form-data'
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              toast.success(response.data.message);
                        }
                        if (response.data.result === 'error') {
                              if (response.data.message.first_name) {
                                    toast.error(
                                          response.data.message.first_name
                                    );
                              }
                              if (response.data.message.last_name) {
                                    toast.error(
                                          response.data.message.last_name
                                    );
                              }
                              if (response.data.message.company_name) {
                                    toast.error(
                                          response.data.message.company_name
                                    );
                              }
                              if (response.data.message.phone_number) {
                                    toast.error(
                                          response.data.message.phone_number
                                    );
                              }
                              if (response.data.message.address) {
                                    toast.error(response.data.message.address);
                              }
                              if (response.data.message.email) {
                                    toast.error(response.data.message.email);
                              }
                              if (response.data.message.position) {
                                    toast.error(response.data.message.position);
                              }
                              if (response.data.message.pan_number) {
                                    toast.error(
                                          response.data.message.pan_number
                                    );
                              }
                              if (response.data.message.project_name) {
                                    toast.error(
                                          response.data.message.project_name
                                    );
                              }
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });

            setLoading(false);
      };
      return (
            <>
                  <Form onSubmit={handleSubmit}>
                        <Row>
                              <Col lg={12}>
                                    <Card>
                                          <CardHeader>
                                                <CardTitle>
                                                      Edit Contact
                                                </CardTitle>
                                                <CardLinks>
                                                      <LinkButton to="/dashboard/contacts">
                                                            Back
                                                      </LinkButton>
                                                </CardLinks>
                                          </CardHeader>
                                          <Card.Body>
                                                <Row className="g-3">
                                                      <Input
                                                            title="First Name"
                                                            required={true}
                                                            value={firstName}
                                                            handleChange={
                                                                  setFirstName
                                                            }
                                                      />
                                                      <Input
                                                            title="Last Name"
                                                            required={true}
                                                            value={lastName}
                                                            handleChange={
                                                                  setLastName
                                                            }
                                                      />
                                                      <Input
                                                            title="Email Address"
                                                            value={email}
                                                            handleChange={
                                                                  setEmail
                                                            }
                                                      />
                                                      <Input
                                                            title="Phone No."
                                                            required={true}
                                                            value={phoneNumber}
                                                            handleChange={
                                                                  setPhoneNumber
                                                            }
                                                      />
                                                      <Input
                                                            title="Address"
                                                            value={address}
                                                            handleChange={
                                                                  setAddress
                                                            }
                                                            required={true}
                                                      />
                                                      <Input
                                                            title="Company Name"
                                                            value={companyName}
                                                            handleChange={
                                                                  setCompanyName
                                                            }
                                                      />
                                                      <Input
                                                            title="PAN No."
                                                            value={panNumber}
                                                            handleChange={
                                                                  setPanNumber
                                                            }
                                                      />
                                                      <Input
                                                            title="Position"
                                                            value={position}
                                                            handleChange={
                                                                  setPosition
                                                            }
                                                      />
                                                      <Input
                                                            title="Project Name"
                                                            value={projectName}
                                                            handleChange={
                                                                  setProjectName
                                                            }
                                                      />
                                                </Row>
                                          </Card.Body>
                                    </Card>

                                    <Card className="mt-4 mb-5">
                                          <Card.Body>
                                                <Row>
                                                      <Col lg={12}>
                                                            <FormButton
                                                                  title="Submit"
                                                                  loading={
                                                                        loading
                                                                  }
                                                            />
                                                      </Col>
                                                </Row>
                                          </Card.Body>
                                    </Card>
                              </Col>
                        </Row>
                  </Form>
            </>
      );
}

export default ContactEdit;
