import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Table } from 'react-bootstrap';

import { toast } from 'react-hot-toast';
import Progress from 'react-progress-2';
import { CardHeader, CardTitle } from '../../styles/components/common/Table';
import TableSearch from '../../components/Form/Search';
import { ShowIcon } from '../../components/icons';
import { Link } from 'react-router-dom';
import ProductImage from '../../components/Form/ProductImage';
import TableFooter from '../../components/Form/TableFooter';
import DeleteButton from '../../components/commons/DeleteButton';

const DealerEnquiryList = () => {
      const [loading, setLoading] = useState(false);
      const [enquiries, setEnquiries] = useState([]);
      const [totalEnquiries, setTotalEnquiries] = useState(0);
      const [perPages, setPerPages] = useState(0);
      const [currentPage, setCurrentPage] = useState(0);

      const token = JSON.parse(localStorage.getItem('token'));
      const [limit, setLimit] = useState(10);
      const [search, setSearch] = useState('');

      const loadData = async () => {
            setLoading(true);

            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/dealer_enquiries?limit=${limit}&search=${search}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`
                              }
                        }
                  )
                  .then((response) => {
                        setEnquiries(response.data.enquiries);
                        setTotalEnquiries(response.data.totalEnquiries);
                        setPerPages(response.data.itemsCountPerPage);
                        setCurrentPage(response.data.currentPage);
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, [limit, search]);

      async function getData(pageNumber = 1) {
            window.scrollTo(0, 0);
            Progress.show();
            const url = `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/dealer_enquiries?page=${pageNumber}&limit=${limit}&search=${search}`;
            await axios(url, {
                  headers: {
                        Authorization: `Bearer ${token}`
                  }
            })
                  .then((response) => {
                        setEnquiries(response.data.brands);
                        setTotalEnquiries(response.data.totalEnquiries);
                        setPerPages(response.data.itemsCountPerPage);
                        setCurrentPage(response.data.currentPage);
                        Progress.hide();
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      }

      const handleDelete = async (complain) => {
            setLoading(true);
            await axios
                  .delete(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/dealer_enquiries/${complain.slug}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              loadData();
                        }
                        setLoading(false);
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };

      return (
            <>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader>
                                          <CardTitle>
                                                Dealer Enquiry List
                                          </CardTitle>
                                    </CardHeader>

                                    <Card.Body>
                                          <TableSearch
                                                setLimit={setLimit}
                                                setSearch={setSearch}
                                          />
                                          <Table hover bordered>
                                                <thead>
                                                      <tr>
                                                            <th>#</th>
                                                            <th>Name</th>
                                                            <th>
                                                                  Company Name
                                                            </th>
                                                            <th>Phone</th>
                                                            <th>Date</th>
                                                            <th width="120px">
                                                                  Action
                                                            </th>
                                                      </tr>
                                                </thead>
                                                <tbody>
                                                      {!loading ? (
                                                            enquiries.length >
                                                            0 ? (
                                                                  enquiries.map(
                                                                        (
                                                                              enquiry,
                                                                              index
                                                                        ) => (
                                                                              <>
                                                                                    <tr
                                                                                          key={
                                                                                                index
                                                                                          }
                                                                                    >
                                                                                          <td>
                                                                                                {
                                                                                                      enquiry.index
                                                                                                }
                                                                                          </td>
                                                                                          <td>
                                                                                                <ProductImage
                                                                                                      title={
                                                                                                            enquiry.name
                                                                                                      }
                                                                                                />
                                                                                          </td>
                                                                                          <td>
                                                                                                {
                                                                                                      enquiry.company_name
                                                                                                }
                                                                                          </td>
                                                                                          <td>
                                                                                                {
                                                                                                      enquiry.phone
                                                                                                }
                                                                                          </td>
                                                                                          <td>
                                                                                                {
                                                                                                      enquiry.date
                                                                                                }
                                                                                          </td>
                                                                                          <td className="text-center">
                                                                                                <Link
                                                                                                      to={`/dashboard/dealer_enquiries/${enquiry.slug}/show`}
                                                                                                      className="text-success mx-2"
                                                                                                >
                                                                                                      <ShowIcon />
                                                                                                </Link>
                                                                                                <DeleteButton
                                                                                                      handleDelete={
                                                                                                            handleDelete
                                                                                                      }
                                                                                                      item={
                                                                                                            enquiry
                                                                                                      }
                                                                                                      loading={
                                                                                                            loading
                                                                                                      }
                                                                                                      setLoading={
                                                                                                            setLoading
                                                                                                      }
                                                                                                />
                                                                                          </td>
                                                                                    </tr>
                                                                              </>
                                                                        )
                                                                  )
                                                            ) : (
                                                                  <tr>
                                                                        <td
                                                                              colSpan={
                                                                                    6
                                                                              }
                                                                              className="text-center"
                                                                        >
                                                                              No
                                                                              Dealer
                                                                              Enquiry
                                                                              Found
                                                                        </td>
                                                                  </tr>
                                                            )
                                                      ) : (
                                                            <>
                                                                  <tr>
                                                                        <td
                                                                              colSpan={
                                                                                    6
                                                                              }
                                                                              className="text-center"
                                                                        >
                                                                              Loading
                                                                        </td>
                                                                  </tr>
                                                            </>
                                                      )}
                                                </tbody>
                                          </Table>

                                          <TableFooter
                                                perPages={perPages}
                                                totalItems={totalEnquiries}
                                                currentPage={currentPage}
                                                getData={getData}
                                          />
                                    </Card.Body>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default DealerEnquiryList;
