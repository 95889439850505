import React, { useState, useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { CardBody } from 'reactstrap';
import {
      QuotationButton,
      QuotationItems
} from '../../styles/backend/quotation';
import axios from 'axios';
import ProductImage from '../../components/Form/ProductImage';
import { Navigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import CustomerInformation from './CustomerInformation';
import { Loading } from '../../components/Loading';

const QuotationCreate = () => {
      const [quotations, setQuotations] = useState([]);
      const [loading, setLoading] = useState(false);
      const [redirect, setRedirect] = useState(false);

      const [totalAmount, setTotalAmount] = useState(0);
      const [totalQuantity, setTotalQuantity] = useState(0);

      const [contacts, setContacts] = useState([]);
      const [contactList, setContactList] = useState([]);
      const [loadingData, setLoadingData] = useState(false);

      const [contact, setContact] = useState('');
      const [contactNumber, setContactNumber] = useState('');
      const [companyName, setCompanyName] = useState('');
      const [panNumber, setPanNumber] = useState('');
      const [projectName, setProjectName] = useState('');
      const [remarks, setRemarks] = useState('');
      const [address, setAddress] = useState('');
      const [emailAddress, setEmailAddress] = useState('');
      const [contactPerson, setContactPerson] = useState('');

      const loadData = async () => {
            setLoadingData(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/customers/quotations`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setQuotations(response.data.quotations);
                              setTotalQuantity(response.data.quotations.length);
                              const newTotalAmount =
                                    response.data.quotations.reduce(
                                          (total, quotation) =>
                                                total +
                                                parseFloat(quotation.amount),
                                          0
                                    );
                              setTotalAmount(newTotalAmount.toFixed(2));
                        }
                  });

            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/contact_persons/getContacts/list`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setContacts(response.data.contacts);
                              setContactList(response.data.contactList);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });

            setLoadingData(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      const handleKeyPress = (event) => {
            if (event.key === 'Enter') {
                  event.preventDefault(); // Prevent default "Enter" key behavior
            }
      };

      const handleSubmit = async (event) => {
            event.preventDefault();
            if (event.key === 'Enter') {
                  event.preventDefault();
            }

            const data = {
                  contact: contact,
                  contact_person: contactPerson,
                  phone_number: contactNumber,
                  email_address: emailAddress,
                  address: address,
                  company_name: companyName,
                  pan_no: panNumber,
                  project_name: projectName,
                  remarks: remarks
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/customers/quotations/store`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              toast.success(response.data.message);
                              setRedirect(true);
                        }
                        if (response.data.result === 'error') {
                              if (response.data.message.company_name) {
                                    toast.error(
                                          response.data.message.company_name
                                    );
                              }
                              if (response.data.message.email_address) {
                                    toast.error(
                                          response.data.message.email_address
                                    );
                              }
                              if (response.data.message.contact_people) {
                                    toast.error(
                                          response.data.message.contact_people
                                    );
                              }
                              if (response.data.message.address) {
                                    toast.error(response.data.message.address);
                              }
                              if (response.data.message.phone_number) {
                                    toast.error(
                                          response.data.message.phone_number
                                    );
                              }
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };

      const removeQuotation = async (slug) => {
            setLoadingData(true);
            await axios
                  .delete(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/quotations/${slug}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setQuotations([]);
                              toast.success(response.data.message);
                              loadData();
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoadingData(false);
      };

      const updateQuantity = async (itemId, newQuantity, index) => {
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/quotations/update-quantity/${itemId}`,
                        { quantity: newQuantity },
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              const updatedQuotations = [...quotations];
                              updatedQuotations[index].amount =
                                    response.data.amount;
                              setQuotations(updatedQuotations);

                              const newTotalAmount = updatedQuotations.reduce(
                                    (total, quotation) =>
                                          total + parseFloat(quotation.amount),
                                    0
                              );
                              setTotalAmount(newTotalAmount.toFixed(2));

                              // toast.success(response.data.message);
                        } else if (response.data.result === 'error') {
                              if (response.data.message) {
                                    toast.error(response.data.message.quantity);
                              }
                        }
                  })
                  .catch((error) => {
                        toast.error('Error updating quantity:', error);
                  });
      };

      const handleQuantityChange = (event, index) => {
            const { value } = event.target;

            const itemId = quotations[index].slug;
            updateQuantity(itemId, value, index);
      };

      const updatePrice = async (itemId, newPrice, index) => {
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/quotations/update-price/${itemId}`,
                        { price: newPrice },
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              const updatedQuotations = [...quotations];
                              updatedQuotations[index].amount =
                                    response.data.amount;
                              setQuotations(updatedQuotations);

                              const newTotalAmount = updatedQuotations.reduce(
                                    (total, quotation) =>
                                          total + parseFloat(quotation.amount),
                                    0
                              );
                              setTotalAmount(newTotalAmount.toFixed(2));
                              // toast.success(response.data.message);
                        }
                        if (response.data.result === 'error') {
                              if (response.data.message) {
                                    toast.error(response.data.message.quantity);
                              }
                        }
                  })
                  .catch((error) => {
                        toast.error('Error updating price:', error);
                  });
      };

      const handlePriceChange = (event, index) => {
            const { value } = event.target;

            const itemId = quotations[index].slug;
            updatePrice(itemId, value, index);
      };

      if (redirect) {
            return <Navigate to={`/dashboard/quotations`} />;
      }

      const updateDiscount = async (itemId, newDiscount, index) => {
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/quotations/update-discount/${itemId}`,
                        { discount: newDiscount },
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              const updatedQuotations = [...quotations];
                              updatedQuotations[index].amount =
                                    response.data.amount;
                              setQuotations(updatedQuotations);

                              const newTotalAmount = updatedQuotations.reduce(
                                    (total, quotation) =>
                                          total + parseFloat(quotation.amount),
                                    0
                              );
                              setTotalAmount(newTotalAmount.toFixed(2));
                              // toast.success(response.data.message);
                        }
                        if (response.data.result === 'error') {
                              if (response.data.message) {
                                    toast.error(response.data.message.discount);
                              }
                        }
                  })
                  .catch((error) => {
                        toast.error('Error updating discount:', error);
                  });
      };

      const handleDiscountChange = (event, index) => {
            const { value } = event.target;

            const itemId = quotations[index].slug;
            updateDiscount(itemId, value, index);
      };

      const handleContactDetail = (event) => {
            const index = event['value'];
            setContact(contacts[index].slug);
            setContactPerson(
                  contacts[index].contact_person
                        ? contacts[index].contact_person
                        : ''
            );
            setAddress(contacts[index].address ? contacts[index].address : '');
            setCompanyName(
                  contacts[index].company_name
                        ? contacts[index].company_name
                        : ''
            );
            setEmailAddress(contacts[index].email ? contacts[index].email : '');
            setPanNumber(contacts[index].pan_no ? contacts[index].pan_no : '');
            setContactNumber(
                  contacts[index].phone_number
                        ? contacts[index].phone_number
                        : ''
            );
            setProjectName(
                  contacts[index].project_name
                        ? contacts[index].project_name
                        : ''
            );
      };

      return (
            <>
                  <form onSubmit={handleSubmit}>
                        <Row>
                              <Col lg={12}>
                                    <Card>
                                          <CardBody>
                                                <div className="row">
                                                      <CustomerInformation
                                                            contacts={
                                                                  contactList
                                                            }
                                                            loading={
                                                                  loadingData
                                                            }
                                                            handleContactDetail={
                                                                  handleContactDetail
                                                            }
                                                            setAddress={
                                                                  setAddress
                                                            }
                                                            address={address}
                                                            setCompanyName={
                                                                  setCompanyName
                                                            }
                                                            companyName={
                                                                  companyName
                                                            }
                                                            setContactNumber={
                                                                  setContactNumber
                                                            }
                                                            contactNumber={
                                                                  contactNumber
                                                            }
                                                            setEmailAddress={
                                                                  setEmailAddress
                                                            }
                                                            emailAddress={
                                                                  emailAddress
                                                            }
                                                            setPanNumber={
                                                                  setPanNumber
                                                            }
                                                            panNumber={
                                                                  panNumber
                                                            }
                                                            setProjectName={
                                                                  setProjectName
                                                            }
                                                            projectName={
                                                                  projectName
                                                            }
                                                            setRemarks={
                                                                  setRemarks
                                                            }
                                                            remarks={remarks}
                                                            contactPerson={
                                                                  contactPerson
                                                            }
                                                            setContactPerson={
                                                                  setContactPerson
                                                            }
                                                      />
                                                      {!loadingData ? (
                                                            <QuotationItems
                                                                  lg={12}
                                                            >
                                                                  <table className="w-100">
                                                                        <thead>
                                                                              <tr>
                                                                                    <th>
                                                                                          Product
                                                                                          Item
                                                                                    </th>
                                                                                    <th>
                                                                                          Req.
                                                                                          Price
                                                                                    </th>
                                                                                    <th>
                                                                                          Req.
                                                                                          Qty
                                                                                    </th>
                                                                                    <th>
                                                                                          Req.
                                                                                          Discount
                                                                                    </th>
                                                                                    <th>
                                                                                          Amount
                                                                                    </th>
                                                                              </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                              {quotations?.length >
                                                                              0 ? (
                                                                                    quotations?.map(
                                                                                          (
                                                                                                quotation,
                                                                                                index
                                                                                          ) => (
                                                                                                <>
                                                                                                      <tr
                                                                                                            key={
                                                                                                                  index
                                                                                                            }
                                                                                                      >
                                                                                                            <td
                                                                                                                  style={{
                                                                                                                        width: '100%'
                                                                                                                  }}
                                                                                                            >
                                                                                                                  <div className="d-flex align-items-center">
                                                                                                                        <button
                                                                                                                              type="button"
                                                                                                                              onClick={() =>
                                                                                                                                    removeQuotation(
                                                                                                                                          quotation.slug
                                                                                                                                    )
                                                                                                                              }
                                                                                                                        >
                                                                                                                              <span className="material-symbols-outlined text-danger">
                                                                                                                                    delete
                                                                                                                              </span>
                                                                                                                        </button>
                                                                                                                        <ProductImage
                                                                                                                              title={
                                                                                                                                    quotation.product_name
                                                                                                                              }
                                                                                                                              image={
                                                                                                                                    quotation.image
                                                                                                                              }
                                                                                                                              category={
                                                                                                                                    <div>
                                                                                                                                          <span>
                                                                                                                                                MRP
                                                                                                                                                Price:
                                                                                                                                                Rs{' '}
                                                                                                                                                <strong>
                                                                                                                                                      {
                                                                                                                                                            quotation.mrp_price
                                                                                                                                                      }
                                                                                                                                                </strong>
                                                                                                                                          </span>
                                                                                                                                    </div>
                                                                                                                              }
                                                                                                                        />
                                                                                                                  </div>
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                  <input
                                                                                                                        className="form-control"
                                                                                                                        style={{
                                                                                                                              width: '120px'
                                                                                                                        }}
                                                                                                                        defaultValue={
                                                                                                                              quotation.requested_price || quotation.mrp_price
                                                                                                                        }
                                                                                                                        onKeyPress={
                                                                                                                              handleKeyPress
                                                                                                                        }
                                                                                                                        onChange={(
                                                                                                                              event
                                                                                                                        ) =>
                                                                                                                              handlePriceChange(
                                                                                                                                    event,
                                                                                                                                    index
                                                                                                                              )
                                                                                                                        }
                                                                                                                  />
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                  <input
                                                                                                                        className="form-control"
                                                                                                                        style={{
                                                                                                                              width: '120px'
                                                                                                                        }}
                                                                                                                        defaultValue={
                                                                                                                              quotation.quantity
                                                                                                                        }
                                                                                                                        onKeyPress={
                                                                                                                              handleKeyPress
                                                                                                                        }
                                                                                                                        onChange={(
                                                                                                                              event
                                                                                                                        ) =>
                                                                                                                              handleQuantityChange(
                                                                                                                                    event,
                                                                                                                                    index
                                                                                                                              )
                                                                                                                        }
                                                                                                                  />
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                  <input
                                                                                                                        className="form-control"
                                                                                                                        defaultValue={
                                                                                                                              quotation.requested_discount
                                                                                                                        }
                                                                                                                        style={{
                                                                                                                              width: '120px'
                                                                                                                        }}
                                                                                                                        onKeyPress={
                                                                                                                              handleKeyPress
                                                                                                                        }
                                                                                                                        onChange={(
                                                                                                                              event
                                                                                                                        ) =>
                                                                                                                              handleDiscountChange(
                                                                                                                                    event,
                                                                                                                                    index
                                                                                                                              )
                                                                                                                        }
                                                                                                                  />
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                  <input
                                                                                                                        className={`form-control amount-${index}`}
                                                                                                                        type="text"
                                                                                                                        style={{
                                                                                                                              width: '120px'
                                                                                                                        }}
                                                                                                                        value={
                                                                                                                              quotation.amount
                                                                                                                        }
                                                                                                                        readOnly
                                                                                                                  />
                                                                                                            </td>
                                                                                                      </tr>
                                                                                                </>
                                                                                          )
                                                                                    )
                                                                              ) : (
                                                                                    <>
                                                                                          <tr>
                                                                                                <td
                                                                                                      colSpan={
                                                                                                            5
                                                                                                      }
                                                                                                      className="text-center"
                                                                                                >
                                                                                                      No
                                                                                                      product
                                                                                                      found
                                                                                                </td>
                                                                                          </tr>
                                                                                    </>
                                                                              )}
                                                                        </tbody>
                                                                  </table>
                                                                  <div className="col-lg-12 text-end mt-3">
                                                                        <p>
                                                                              Total
                                                                              Quantity:{' '}
                                                                              {
                                                                                    totalQuantity
                                                                              }
                                                                        </p>
                                                                        <p>
                                                                              Total
                                                                              Amount:{' '}
                                                                              {
                                                                                    totalAmount
                                                                              }
                                                                        </p>
                                                                  </div>
                                                                  <div className="col-lg-12 text-end mt-3 pt-3 border-top">
                                                                        <QuotationButton>
                                                                              Submit
                                                                              {loading && (
                                                                                    <Loading />
                                                                              )}
                                                                        </QuotationButton>
                                                                  </div>
                                                            </QuotationItems>
                                                      ) : (
                                                            <>
                                                                  <div className="text-center w-100">
                                                                        <Loading />
                                                                  </div>
                                                            </>
                                                      )}
                                                </div>
                                          </CardBody>
                                    </Card>
                              </Col>
                        </Row>
                  </form>
            </>
      );
};

export default QuotationCreate;
