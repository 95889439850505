import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Card, Col, Form, ListGroup, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import CardHeader from '../../components/commons/CardHeader';
import LinkButton from '../../components/commons/LinkButton';
import ItemShow from '../../components/Form/ItemShow';
import FormSelect from '../../components/Form/FormSelect';

const options = [
      {
            id: 0,
            title: 'Pending'
      },
      {
            id: 1,
            title: 'Processing'
      },
      {
            id: 2,
            title: 'Shipping'
      },
      {
            id: 3,
            title: 'Delivered'
      },
      {
            id: 4,
            title: 'Rejected'
      },
      {
            id: 5,
            title: 'Cancelled'
      }
];

const CancelledShow = () => {
      const { orderId } = useParams();
      const [order, setOrder] = useState([]);
      const [status, setStatus] = useState(0);
      const [loading, setLoading] = useState(false);

      const loadData = async () => {
            setLoading(true);

            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/orders/${orderId}/show`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setOrder(response.data.order);
                              setStatus(response.data.order.status_int);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      const handleStatus = async (order, value) => {
            setStatus(value);
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/orders/${order.slug}/update`,
                        {
                              status: value
                        },
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              toast.success(response.data.message);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
      };

      return (
            <>
                  <Row>
                        <Col xs={12}>
                              <Card>
                                    <CardHeader title="Order Details">
                                          <LinkButton
                                                link="/dashboard/cancelled/orders"
                                                title="Back"
                                          />
                                    </CardHeader>
                                    <Card.Body>
                                          <Row>
                                                <Col md={12}>
                                                      <Card>
                                                            <Card.Body>
                                                                  <div class="table responsive">
                                                                        <table class="table table-bordered table-striped">
                                                                              <thead>
                                                                                    <th>
                                                                                          S.No
                                                                                    </th>
                                                                                    <th>
                                                                                          Product
                                                                                          Name
                                                                                    </th>
                                                                                    <th>
                                                                                          Price
                                                                                    </th>
                                                                                    <th>
                                                                                          Qty
                                                                                    </th>
                                                                                    <th>
                                                                                          Total
                                                                                          price
                                                                                    </th>
                                                                              </thead>
                                                                              <tbody>
                                                                                    {!loading
                                                                                          ? order
                                                                                                  ?.items
                                                                                                  ?.length >
                                                                                                  0 &&
                                                                                            order?.items?.map(
                                                                                                  (
                                                                                                        item,
                                                                                                        index
                                                                                                  ) => (
                                                                                                        <tr
                                                                                                              key={
                                                                                                                    index
                                                                                                              }
                                                                                                        >
                                                                                                              <td class="text-center">
                                                                                                                    {
                                                                                                                          item.index
                                                                                                                    }
                                                                                                              </td>
                                                                                                              <td>
                                                                                                                    <div class="d-flex align-items-center">
                                                                                                                          <div class="">
                                                                                                                                <img
                                                                                                                                      src={
                                                                                                                                            item.image
                                                                                                                                      }
                                                                                                                                      style={{
                                                                                                                                            height: '80px'
                                                                                                                                      }}
                                                                                                                                      class="product__image"
                                                                                                                                      alt=""
                                                                                                                                />
                                                                                                                          </div>

                                                                                                                          <div class="">
                                                                                                                                <div class="ps-2">
                                                                                                                                      {
                                                                                                                                            item.product_name
                                                                                                                                      }
                                                                                                                                </div>
                                                                                                                          </div>
                                                                                                                    </div>
                                                                                                              </td>
                                                                                                              <td>
                                                                                                                    {
                                                                                                                          item.price
                                                                                                                    }
                                                                                                              </td>
                                                                                                              <td class="text-center">
                                                                                                                    {
                                                                                                                          item.quantity
                                                                                                                    }
                                                                                                              </td>
                                                                                                              <td>
                                                                                                                    {' '}
                                                                                                                    Rs.{' '}
                                                                                                                    {
                                                                                                                          item.total
                                                                                                                    }{' '}
                                                                                                              </td>
                                                                                                        </tr>
                                                                                                  )
                                                                                            )
                                                                                          : null}
                                                                                    <tr>
                                                                                          <td
                                                                                                colspan="4"
                                                                                                class="text-end"
                                                                                          >
                                                                                                <strong>
                                                                                                      Grand
                                                                                                      Total
                                                                                                </strong>
                                                                                          </td>
                                                                                          <td>
                                                                                                <strong>
                                                                                                      Rs.{' '}
                                                                                                      {
                                                                                                            order?.total_amount
                                                                                                      }
                                                                                                </strong>
                                                                                          </td>
                                                                                    </tr>
                                                                              </tbody>
                                                                        </table>
                                                                        <Form>
                                                                              <div class="row">
                                                                                    <FormSelect
                                                                                          title="Delivery Status"
                                                                                          classes={
                                                                                                4
                                                                                          }
                                                                                          selected={
                                                                                                status
                                                                                          }
                                                                                          options={
                                                                                                options
                                                                                          }
                                                                                          setData={
                                                                                                handleStatus
                                                                                          }
                                                                                          order={
                                                                                                order
                                                                                          }
                                                                                    />
                                                                              </div>
                                                                        </Form>
                                                                  </div>
                                                            </Card.Body>
                                                      </Card>
                                                </Col>
                                                <Col md={12} className="mt-4">
                                                      <Row>
                                                            <Col lg={6}>
                                                                  Personal
                                                                  Details
                                                                  <ListGroup>
                                                                        <ItemShow
                                                                              title="Customer Name"
                                                                              value={
                                                                                    order.name
                                                                              }
                                                                        />
                                                                        <ItemShow
                                                                              title="Phone No."
                                                                              value={
                                                                                    order.phone_number
                                                                              }
                                                                        />
                                                                        <ItemShow
                                                                              title="Email"
                                                                              value={
                                                                                    order.email
                                                                              }
                                                                        />
                                                                        <ItemShow
                                                                              title="Shipping Address"
                                                                              value={
                                                                                    order.address
                                                                              }
                                                                        />
                                                                        <ItemShow
                                                                              title="Billing Address"
                                                                              value={
                                                                                    order.billing_address
                                                                              }
                                                                        />
                                                                  </ListGroup>
                                                            </Col>
                                                            <Col lg={6}>
                                                                  Order Details
                                                                  <ListGroup>
                                                                        <ItemShow
                                                                              title="Date"
                                                                              value={
                                                                                    order.date
                                                                              }
                                                                        />
                                                                        <ItemShow
                                                                              title="Order Name"
                                                                              value={
                                                                                    order.order_name
                                                                              }
                                                                        />
                                                                        <ItemShow
                                                                              title="Payment Status"
                                                                              value={`${order.payment}`}
                                                                        />
                                                                        <ItemShow
                                                                              title="Order Status"
                                                                              value={
                                                                                    order.status
                                                                              }
                                                                        />
                                                                        <ItemShow
                                                                              title="Total Quantity"
                                                                              value={
                                                                                    order.total_items
                                                                              }
                                                                        />
                                                                        <ItemShow
                                                                              title="Total Amount"
                                                                              value={`Rs. ${order.total_amount}`}
                                                                        />
                                                                        <ItemShow
                                                                              title="Category"
                                                                              value={
                                                                                    order.category
                                                                              }
                                                                        />
                                                                  </ListGroup>
                                                            </Col>
                                                      </Row>
                                                </Col>
                                          </Row>
                                    </Card.Body>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default CancelledShow;
