import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Card, Col, ListGroup, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import {
      CardHeader,
      CardLinks,
      CardTitle
} from '../../styles/components/common/Table';
import { LinkButton } from '../../styles/components/common/Button';
import ItemShow from '../../components/Form/ItemShow';
import { toast } from 'react-hot-toast';

import Progress from 'react-progress-2';
import 'react-progress-2/main.css';
import ProductList from '../../components/ProductList';
import { Loading } from '../../components/Loading';

const ColorShow = () => {
      const { colorId } = useParams();
      const [color, setColor] = useState([]);
      const [loading, setLoading] = useState(false);
      const [loadingData, setLoadingData] = useState(false);
      const [products, setProducts] = useState([]);
      const [totalProducts, setTotalProducts] = useState(0);
      const [perPages, setPerPages] = useState(0);
      const [currentPage, setCurrentPage] = useState(0);

      const token = JSON.parse(localStorage.getItem('token'));
      const [limit, setLimit] = useState(10);
      const [search, setSearch] = useState('');

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/colors/${colorId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setColor(response.data.color);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      const loadProduct = async () => {
            setLoadingData(true);

            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/colors/${colorId}/edit?limit=${limit}&search=${search}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setProducts(response.data.products);
                              setTotalProducts(response.data.totalProducts);
                              setPerPages(response.data.itemsCountPerPage);
                              setCurrentPage(response.data.currentPage);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoadingData(false);
      };

      useEffect(() => {
            loadProduct();
      }, [search, limit]);

      async function getProductData(pageNumber = 1) {
            window.scrollTo(0, 0);
            Progress.show();

            const url = `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/colors/${colorId}/edit?page=${pageNumber}&limit=${limit}&search=${search}`;
            await axios(url, {
                  headers: {
                        Authorization: `Bearer ${token}`
                  }
            })
                  .then((response) => {
                        setProducts(response.data.products);
                        setTotalProducts(response.data.totalProducts);
                        setPerPages(response.data.itemsCountPerPage);
                        setCurrentPage(response.data.currentPage);
                        Progress.hide();
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      }

      return (
            <>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader>
                                          <CardTitle>Color Details</CardTitle>
                                          <CardLinks>
                                                <LinkButton to="/dashboard/products/colors">
                                                      Back
                                                </LinkButton>
                                          </CardLinks>
                                    </CardHeader>
                                    <Card.Body>
                                          {!loading ? (
                                                <Row>
                                                      <Col lg={6}>
                                                            <ListGroup>
                                                                  <ItemShow
                                                                        title="Title"
                                                                        value={
                                                                              color.title
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Products"
                                                                        value={
                                                                              color.products
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Created By"
                                                                        value={
                                                                              color.user_name
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Created Date"
                                                                        value={
                                                                              color.date
                                                                        }
                                                                  />
                                                            </ListGroup>
                                                      </Col>
                                                      {color.image && (
                                                            <Col lg={6}>
                                                                  <ListGroup>
                                                                        <img
                                                                              src={
                                                                                    color.image
                                                                              }
                                                                              alt={
                                                                                    color.title
                                                                              }
                                                                              className="w-100"
                                                                        />
                                                                  </ListGroup>
                                                            </Col>
                                                      )}
                                                </Row>
                                          ) : (
                                                <>
                                                      <div
                                                            className="text-center d-flex align-items-center justify-content-center w-100"
                                                            style={{
                                                                  height: '30vh'
                                                            }}
                                                      >
                                                            <Loading />
                                                      </div>
                                                </>
                                          )}
                                    </Card.Body>
                              </Card>

                              {!loading && (
                                    <ProductList
                                          loadData={loadProduct}
                                          loading={loadingData}
                                          setLoading={setLoadingData}
                                          products={products}
                                          totalProducts={totalProducts}
                                          perPages={perPages}
                                          setProducts={setProducts}
                                          currentPage={currentPage}
                                          setLimit={setLimit}
                                          setSearch={setSearch}
                                          getProductData={getProductData}
                                    />
                              )}
                        </Col>
                  </Row>
            </>
      );
};

export default ColorShow;
