import React from 'react';
import CheckMultiplePermission from '../roles/CheckMultiplePermission';
import {
      MenuSeparator,
      MetisMenuIcon,
      MetisMenuLink,
      MetisMenuTitle
} from '../../styles/layouts/navigation';
import { NavLink } from 'react-router-dom';

const UserManage = ({ userPermissions }) => {
      return (
            <>
                  <CheckMultiplePermission
                        userPermissions={userPermissions}
                        permissions={[
                              'user-list',
                              'user-create',
                              'user-edit',
                              'user-show',
                              'role-list',
                              'role-create',
                              'role-edit',
                              'role-delete',
                              'customer-list',
                              'customer-create',
                              'customer-edit',
                              'customer-delete',
                              'customer-enquiry-list',
                              'customer-enquiry-edit',
                              'customer-enquiry-create',
                              'customer-enquiry-show',
                              'dealer-list',
                              'dealer-create',
                              'dealer-create',
                              'dealer-edit',
                              'dealer-request-list',
                              'dealer-request-delete',
                              'dealer-request-show',
                              'dealer-enquiry-list',
                              'dealer-enquiry-delete',
                              'dealer-enquiry-show'
                        ]}
                  >
                        <MenuSeparator>User Manages</MenuSeparator>
                        <CheckMultiplePermission
                              userPermissions={userPermissions}
                              permissions={[
                                    'user-list',
                                    'user-create',
                                    'user-edit',
                                    'user-show',
                                    'role-list',
                                    'role-create',
                                    'role-edit',
                                    'role-delete'
                              ]}
                        >
                              <li>
                                    <MetisMenuLink
                                          className="has-arrow"
                                          href="#"
                                    >
                                          <MetisMenuIcon>
                                                <span className="material-symbols-outlined">
                                                      person
                                                </span>
                                          </MetisMenuIcon>
                                          <MetisMenuTitle>
                                                <span>User Manage</span>
                                          </MetisMenuTitle>
                                    </MetisMenuLink>
                                    <ul>
                                          <CheckMultiplePermission
                                                userPermissions={
                                                      userPermissions
                                                }
                                                permissions={[
                                                      'user-list',
                                                      'user-create',
                                                      'user-edit',
                                                      'user-delete'
                                                ]}
                                          >
                                                <li>
                                                      <NavLink to="/dashboard/users">
                                                            All User
                                                      </NavLink>
                                                </li>
                                          </CheckMultiplePermission>
                                          <CheckMultiplePermission
                                                userPermissions={
                                                      userPermissions
                                                }
                                                permissions={[
                                                      'role-list',
                                                      'role-create',
                                                      'role-edit',
                                                      'role-delete'
                                                ]}
                                          >
                                                <li>
                                                      <NavLink to="/dashboard/roles">
                                                            Role & Permission
                                                      </NavLink>
                                                </li>
                                          </CheckMultiplePermission>
                                    </ul>
                              </li>
                        </CheckMultiplePermission>
                        <CheckMultiplePermission
                              userPermissions={userPermissions}
                              permissions={[
                                    'customer-list',
                                    'customer-create',
                                    'customer-edit',
                                    'customer-delete',
                                    'customer-enquiry-list',
                                    'customer-enquiry-edit',
                                    'customer-enquiry-create',
                                    'customer-enquiry-show'
                              ]}
                        >
                              <li>
                                    <MetisMenuLink
                                          className="has-arrow"
                                          href="#"
                                    >
                                          <MetisMenuIcon>
                                                <span className="material-symbols-outlined">
                                                      person
                                                </span>
                                          </MetisMenuIcon>
                                          <MetisMenuTitle>
                                                <span>Customer Manage</span>
                                          </MetisMenuTitle>
                                    </MetisMenuLink>
                                    <ul>
                                          <CheckMultiplePermission
                                                userPermissions={
                                                      userPermissions
                                                }
                                                permissions={[
                                                      'customer-list',
                                                      'customer-create',
                                                      'customer-edit',
                                                      'customer-delete'
                                                ]}
                                          >
                                                <li>
                                                      <NavLink to="/dashboard/customers">
                                                            All Customers
                                                      </NavLink>
                                                </li>
                                          </CheckMultiplePermission>
                                          <CheckMultiplePermission
                                                userPermissions={
                                                      userPermissions
                                                }
                                                permissions={[
                                                      'customer-enquiry-list',
                                                      'customer-enquiry-edit',
                                                      'customer-enquiry-create',
                                                      'customer-enquiry-show'
                                                ]}
                                          >
                                                <li>
                                                      <NavLink to="/dashboard/customer-enquiries">
                                                            Customer Enquiry
                                                      </NavLink>
                                                </li>
                                          </CheckMultiplePermission>
                                    </ul>
                              </li>
                        </CheckMultiplePermission>

                        <CheckMultiplePermission
                              userPermissions={userPermissions}
                              permissions={[
                                    'dealer-list',
                                    'dealer-create',
                                    'dealer-create',
                                    'dealer-edit',
                                    'dealer-request-list',
                                    'dealer-request-delete',
                                    'dealer-request-show',
                                    'dealer-enquiry-list',
                                    'dealer-enquiry-delete',
                                    'dealer-enquiry-show'
                              ]}
                        >
                              <li>
                                    <MetisMenuLink
                                          className="has-arrow"
                                          href="#"
                                    >
                                          <MetisMenuIcon>
                                                <span className="material-symbols-outlined">
                                                      manage_accounts
                                                </span>
                                          </MetisMenuIcon>
                                          <MetisMenuTitle>
                                                <span>Dealers Manage</span>
                                          </MetisMenuTitle>
                                    </MetisMenuLink>
                                    <ul>
                                          <CheckMultiplePermission
                                                userPermissions={
                                                      userPermissions
                                                }
                                                permissions={[
                                                      'dealer-list',
                                                      'dealer-edit',
                                                      'dealer-create',
                                                      'dealer-show'
                                                ]}
                                          >
                                                <li>
                                                      <NavLink to="/dashboard/dealers">
                                                            All Dealers
                                                      </NavLink>
                                                </li>
                                          </CheckMultiplePermission>
                                          <CheckMultiplePermission
                                                userPermissions={
                                                      userPermissions
                                                }
                                                permissions={[
                                                      'dealer-request-list',
                                                      'dealer-request-delete',
                                                      'dealer-request-show'
                                                ]}
                                          >
                                                <li>
                                                      <NavLink to="/dashboard/dealer_requests">
                                                            Dealer Request
                                                      </NavLink>
                                                </li>
                                          </CheckMultiplePermission>
                                          <CheckMultiplePermission
                                                userPermissions={
                                                      userPermissions
                                                }
                                                permissions={[
                                                      'dealer-enquiry-list',
                                                      'dealer-enquiry-delete',
                                                      'dealer-enquiry-show'
                                                ]}
                                          >
                                                <li>
                                                      <NavLink to="/dashboard/dealer_enquiries">
                                                            Dealer Enquiry
                                                      </NavLink>
                                                </li>
                                          </CheckMultiplePermission>
                                    </ul>
                              </li>
                        </CheckMultiplePermission>
                  </CheckMultiplePermission>
            </>
      );
};

export default UserManage;
