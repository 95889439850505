import React, { useState } from 'react';
import { Card, Col, Row, Table } from 'react-bootstrap';
import {
      CardHeader,
      CardLinks,
      CardTitle
} from '../../styles/components/common/Table';
import { LinkButton } from '../../styles/components/common/Button';
import TableSearch from '../../components/Form/Search';
import TableFooter from '../../components/Form/TableFooter';
import Progress from 'react-progress-2';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { useEffect } from 'react';
import { DeleteIcon, EditIcon, ShowIcon } from '../../components/icons';
import { Link } from 'react-router-dom';
import ItemStatus from '../../components/Form/Status';
import ProductImage from '../../components/Form/ProductImage';

const JobList = () => {
      const [loading, setLoading] = useState(false);
      const [jobs, setJobs] = useState([]);
      const [totalJobs, setTotalJobs] = useState(0);
      const [perPages, setPerPages] = useState(0);
      const [currentPage, setCurrentPage] = useState(0);

      const token = JSON.parse(localStorage.getItem('token'));
      const [limit, setLimit] = useState(10);
      const [search, setSearch] = useState('');

      const loadData = async () => {
            setLoading(true);
            await axios(
                  `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/job_lists?limit=${limit}&search=${search}`,
                  {
                        headers: {
                              Authorization: `Bearer ${token}`
                        }
                  }
            )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setJobs(response.data.jobs);
                              setTotalJobs(response.data.totalJobs);
                              setPerPages(response.data.itemsCountPerPage);
                              setCurrentPage(response.data.currentPage);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };
      useEffect(() => {
            loadData();
      }, [limit, search]);

      async function getJobData(pageNumber = 1) {
            window.screenTop(0, 0);
            Progress.show();
            const url = `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/job_lists?page=${pageNumber}&limit=${limit}&search=${search}`;
            await axios(url, {
                  headers: {
                        Authorization: `Bearer ${token}`
                  }
            })
                  .then((response) => {
                        setJobs(response.data.jobs);
                        setTotalJobs(response.data.totalJobs);
                        setPerPages(response.data.itemsCountPerPage);
                        setCurrentPage(response.data.currentPage);
                        Progress.hide();
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      }

      const handleStatus = async (job) => {
            const data = {
                  slug: job.slug
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/job_lists/${job.slug}/status`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              toast.success(response.data.message);
                        }
                        if (response.data.result === 'error') {
                              toast.error(response.data.message);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };

      const handleDelete = async (job) => {
            setLoading(true);
            await axios
                  .delete(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/job_lists/${job.slug}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setJobs([]);
                              loadData();
                              toast.success(response.data.message);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      return (
            <>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader>
                                          <CardTitle>Job List</CardTitle>
                                          <CardLinks>
                                                <LinkButton to="/dashboard/jobs/create">
                                                      Add New
                                                </LinkButton>
                                          </CardLinks>
                                    </CardHeader>
                                    <Card.Body>
                                          <TableSearch
                                                setLimit={setLimit}
                                                setSearch={setSearch}
                                          />
                                          <Table hover bordered>
                                                <thead>
                                                      <tr>
                                                            <th
                                                                  className="text-center"
                                                                  width="60px"
                                                            >
                                                                  #
                                                            </th>
                                                            <th>Job Title</th>
                                                            <th>Vacant</th>
                                                            <th width="120px">
                                                                  Salary
                                                            </th>
                                                            <th width="120px">
                                                                  Deadline
                                                            </th>
                                                            <th width="120px">
                                                                  Status
                                                            </th>
                                                            <th width="120px">
                                                                  Action
                                                            </th>
                                                      </tr>
                                                </thead>
                                                <tbody>
                                                      {!loading ? (
                                                            jobs.length > 0 ? (
                                                                  jobs.map(
                                                                        (
                                                                              job,
                                                                              index
                                                                        ) => (
                                                                              <>
                                                                                    <tr
                                                                                          key={
                                                                                                index
                                                                                          }
                                                                                    >
                                                                                          <td className="text-center">
                                                                                                {
                                                                                                      job.index
                                                                                                }
                                                                                          </td>
                                                                                          <td>
                                                                                                <ProductImage
                                                                                                      title={
                                                                                                            job.title
                                                                                                      }
                                                                                                      image={
                                                                                                            job.image
                                                                                                      }
                                                                                                      category={
                                                                                                            job.type
                                                                                                      }
                                                                                                />
                                                                                          </td>
                                                                                          <td>
                                                                                                {
                                                                                                      job.no_of_vacancy
                                                                                                }
                                                                                          </td>
                                                                                          <td>
                                                                                                {
                                                                                                      job.salary
                                                                                                }
                                                                                          </td>
                                                                                          <td>
                                                                                                {
                                                                                                      job.deadline
                                                                                                }
                                                                                          </td>
                                                                                          <td>
                                                                                                <ItemStatus
                                                                                                      item={
                                                                                                            job
                                                                                                      }
                                                                                                      handleStatus={
                                                                                                            handleStatus
                                                                                                      }
                                                                                                />
                                                                                          </td>
                                                                                          <td className="text-center">
                                                                                                <Link
                                                                                                      to={`/dashboard/jobs/${job.slug}/edit`}
                                                                                                      className="text-secondary"
                                                                                                >
                                                                                                      <EditIcon />
                                                                                                </Link>
                                                                                                <Link
                                                                                                      to={`/dashboard/jobs/${job.slug}/show`}
                                                                                                      className="text-success mx-2"
                                                                                                >
                                                                                                      <ShowIcon />
                                                                                                </Link>
                                                                                                <Link
                                                                                                      onClick={() =>
                                                                                                            handleDelete(
                                                                                                                  job
                                                                                                            )
                                                                                                      }
                                                                                                      className="text-danger mx-2"
                                                                                                >
                                                                                                      <DeleteIcon />
                                                                                                </Link>
                                                                                          </td>
                                                                                    </tr>
                                                                              </>
                                                                        )
                                                                  )
                                                            ) : (
                                                                  <>
                                                                        <tr>
                                                                              <td
                                                                                    colSpan="7"
                                                                                    className="text-center"
                                                                              >
                                                                                    No
                                                                                    Job
                                                                                    Found.
                                                                              </td>
                                                                        </tr>
                                                                  </>
                                                            )
                                                      ) : (
                                                            <>
                                                                  <tr>
                                                                        <td
                                                                              colSpan="7"
                                                                              className="text-center"
                                                                        >
                                                                              Loading...
                                                                        </td>
                                                                  </tr>
                                                            </>
                                                      )}
                                                </tbody>
                                          </Table>
                                          <TableFooter
                                                perPages={perPages}
                                                totalItems={totalJobs}
                                                currentPage={currentPage}
                                                getData={getJobData}
                                          />
                                    </Card.Body>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default JobList;
