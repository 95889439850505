import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { CardHeader, CardTitle } from '../../styles/components/common/Table';
import { LinkButton } from '../../styles/components/common/Button';
import FormSeo from '../../components/commons/FormSeo';
import FormButton from '../../components/Form/Button';
import FormImage from '../../components/Form/image';
import { useParams } from 'react-router-dom';
import Input from '../../components/Form/Input';
import FormSelect from '../../components/Form/FormSelect';
import Select from '../../components/Form/Select';

const options = [
      {
            id: 'PDF',
            title: 'PDF'
      },
      {
            id: 'Image',
            title: 'Image'
      }
];

const CatalogueEdit = () => {
      const { catalogueId } = useParams();

      const [catalogue, setCatalogue] = useState([]);
      const [title, setTitle] = useState('');
      const [image, setImage] = useState('');
      const [imageUrl, setImageUrl] = useState('');

      const [file, setFile] = useState('');
      const [fileUrl, setFileUrl] = useState('');

      const [type, setType] = useState('');
      const [status, setStatus] = useState('');
      const [loading, setLoading] = useState(false);

      const [seoTitle, setSeoTitle] = useState(null);
      const [seoKeyword, setSeoKeyword] = useState(null);
      const [seoDescription, setSeoDescription] = useState(null);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/catalogues/${catalogueId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setCatalogue(response.data.catalogue);
                              setTitle(response.data.catalogue.title);
                              setImageUrl(response.data.catalogue.image);
                              setFileUrl(response.data.catalogue.file);
                              setType(response.data.catalogue.type);
                              setStatus(response.data.catalogue.status);
                              setSeoTitle(response.data.catalogue.seo_title);
                              setSeoDescription(
                                    response.data.catalogue.seo_description
                              );
                              setSeoKeyword(
                                    response.data.catalogue.seo_keyword
                              );
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      const handleFormSubmit = async (event) => {
            event.preventDefault();
            setLoading(true);
            const data = {
                  title: title,
                  type: type,
                  file: file,
                  image: image,
                  status: status,
                  seo_title: seoTitle,
                  seo_keyword: seoKeyword,
                  seo_description: seoDescription
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/catalogues/${catalogueId}`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`,
                                    'Content-Type': 'multipart/form-data'
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              toast.success(response.data.message);
                        }
                        if (response.data.result === 'error') {
                              if (response.data.message.title) {
                                    toast.error(response.data.message.title);
                              }
                              if (response.data.message.type) {
                                    toast.error(response.data.message.type);
                              }
                              if (response.data.message.file) {
                                    toast.error(response.data.message.file);
                              }
                              if (response.data.message.image) {
                                    toast.error(response.data.message.image);
                              }
                              if (response.data.message.seo_title) {
                                    toast.error(
                                          response.data.message.seo_title
                                    );
                              }
                              if (response.data.message.seo_keyword) {
                                    toast.error(
                                          response.data.message.seo_keyword
                                    );
                              }
                              if (response.data.message.seo_description) {
                                    toast.error(
                                          response.data.message.seo_description
                                    );
                              }
                              if (response.data.message.status) {
                                    toast.error(response.data.message.status);
                              }
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      return (
            <>
                  <Form onSubmit={handleFormSubmit}>
                        <div className="">
                              <Row>
                                    <Col lg={12}>
                                          <Card>
                                                <CardHeader>
                                                      <CardTitle>
                                                            Edit Catalogue
                                                      </CardTitle>
                                                      <LinkButton
                                                            to="/dashboard/catalogues"
                                                            className="btn btn-secondary"
                                                      >
                                                            Back
                                                      </LinkButton>
                                                </CardHeader>
                                                <div className="card-body">
                                                      <div className="row g-3">
                                                            <Input
                                                                  title="Title"
                                                                  classes={8}
                                                                  required={
                                                                        true
                                                                  }
                                                                  value={title}
                                                                  handleChange={(
                                                                        event
                                                                  ) =>
                                                                        setTitle(
                                                                              event
                                                                                    .target
                                                                                    .value
                                                                        )
                                                                  }
                                                            />
                                                            <FormSelect
                                                                  title="Type"
                                                                  classes={4}
                                                                  selected={
                                                                        type
                                                                  }
                                                                  required={
                                                                        true
                                                                  }
                                                                  options={
                                                                        options
                                                                  }
                                                                  loading={
                                                                        loading
                                                                  }
                                                                  setData={
                                                                        setType
                                                                  }
                                                            />
                                                            <Select
                                                                  title="Status"
                                                                  selected={
                                                                        status
                                                                  }
                                                                  classes={4}
                                                                  setStatus={
                                                                        setStatus
                                                                  }
                                                            />
                                                      </div>
                                                </div>
                                          </Card>

                                          <Card className="mt-4">
                                                <Card.Body>
                                                      <Row className="g-3">
                                                            <FormImage
                                                                  title="Feature Image"
                                                                  setImage={
                                                                        setImage
                                                                  }
                                                                  grid={2}
                                                                  setImageUrl={
                                                                        setImageUrl
                                                                  }
                                                                  required={
                                                                        true
                                                                  }
                                                                  imageUrl={
                                                                        imageUrl
                                                                  }
                                                            />
                                                            <FormImage
                                                                  title="PDF File"
                                                                  setImage={
                                                                        setFile
                                                                  }
                                                                  grid={2}
                                                                  setImageUrl={
                                                                        setFileUrl
                                                                  }
                                                                  imageUrl={
                                                                        fileUrl &&
                                                                        '/images/icons/pdf.png'
                                                                  }
                                                                  imageType="application/pdf"
                                                            />
                                                      </Row>
                                                </Card.Body>
                                          </Card>

                                          <FormSeo
                                                setSeoDescription={
                                                      setSeoDescription
                                                }
                                                setSeoTitle={setSeoTitle}
                                                setSeoKeyword={setSeoKeyword}
                                                title={seoTitle}
                                                keyword={seoKeyword}
                                                description={seoDescription}
                                          />

                                          <Card className="mt-4 mb-5">
                                                <Card.Body>
                                                      <Row>
                                                            <Col lg={12}>
                                                                  <FormButton
                                                                        title="Submit"
                                                                        loading={
                                                                              loading
                                                                        }
                                                                  />
                                                            </Col>
                                                      </Row>
                                                </Card.Body>
                                          </Card>
                                    </Col>
                              </Row>
                        </div>
                  </Form>
            </>
      );
};

export default CatalogueEdit;
