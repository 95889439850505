import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Card, Col, ListGroup, ListGroupItem, Row } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { Link, useParams } from 'react-router-dom';
import ItemShow from '../../components/Form/ItemShow';
import CardHeader from '../../components/commons/CardHeader';
import LinkButton from '../../components/commons/LinkButton';
import { Loading } from '../../components/Loading';

const CandidateShow = () => {
      const { candidateId } = useParams();
      const [candidate, setCandidate] = useState([]);
      const [loading, setLoading] = useState(false);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/candidates/${candidateId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setCandidate(response.data.candidate);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);
      return (
            <>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader title="Candidate Details">
                                          <LinkButton
                                                link="/dashboard/candidates"
                                                title="Back"
                                          />
                                    </CardHeader>
                                    <Card.Body>
                                          {!loading ? (
                                                <Row>
                                                      <Col lg={5}>
                                                            <ListGroup>
                                                                  <ItemShow
                                                                        title="Apply For"
                                                                        value={
                                                                              candidate.post_name
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Department"
                                                                        value={
                                                                              candidate.department_name
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Full Name"
                                                                        value={
                                                                              candidate.name
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Email"
                                                                        value={
                                                                              candidate.email
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Phone"
                                                                        value={
                                                                              candidate.phone_number
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Current Address"
                                                                        value={
                                                                              candidate.current_address
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Permanent Address"
                                                                        value={
                                                                              candidate.permanent_address
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Date of Birth"
                                                                        value={
                                                                              candidate.date_of_birth
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Gender"
                                                                        value={
                                                                              candidate.gender
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Education"
                                                                        value={
                                                                              candidate.education
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Experience"
                                                                        value={
                                                                              candidate.experience
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Apply On"
                                                                        value={
                                                                              candidate.date
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Remarks"
                                                                        value={
                                                                              candidate.remarks
                                                                        }
                                                                  />
                                                                  {candidate.resume && (
                                                                        <ListGroupItem>
                                                                              <strong>
                                                                                    Resume
                                                                              </strong>{' '}
                                                                              :
                                                                              <span className="ps-3">
                                                                                    <Link
                                                                                          to={
                                                                                                candidate.resume
                                                                                          }
                                                                                          download={
                                                                                                true
                                                                                          }
                                                                                    >
                                                                                          Download
                                                                                    </Link>
                                                                              </span>
                                                                        </ListGroupItem>
                                                                  )}
                                                            </ListGroup>
                                                      </Col>
                                                </Row>
                                          ) : (
                                                <div
                                                      className="text-center d-flex align-items-center justify-content-center w-100"
                                                      style={{
                                                            height: '20vh'
                                                      }}
                                                >
                                                      <Loading />
                                                </div>
                                          )}
                                    </Card.Body>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default CandidateShow;
