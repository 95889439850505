import axios from 'axios';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Card, Col, ListGroup, ListGroupItem, Row } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import {
      CardHeader,
      CardLinks,
      CardTitle
} from '../../styles/components/common/Table';
import { LinkButton } from '../../styles/components/common/Button';
import ItemShow from '../../components/Form/ItemShow';
import { useParams } from 'react-router-dom';
import { Loading } from '../../components/Loading';

const JobShow = () => {
      const { jobId } = useParams();

      const [loading, setLoading] = useState(false);
      const [job, setJob] = useState([]);

      const token = JSON.parse(localStorage.getItem('token'));

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/job_lists/${jobId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setJob(response.data.job);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader>
                                          <CardTitle>Job Details</CardTitle>
                                          <CardLinks>
                                                <LinkButton to="/dashboard/jobs">
                                                      Back
                                                </LinkButton>
                                          </CardLinks>
                                    </CardHeader>
                                    <Card.Body>
                                          {!loading ? (
                                                <Row>
                                                      <Col lg={6}>
                                                            <ListGroup>
                                                                  <ItemShow
                                                                        title="Title"
                                                                        value={
                                                                              job.title
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Education"
                                                                        value={
                                                                              job.education
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Experince"
                                                                        value={
                                                                              job.experience
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Type"
                                                                        value={
                                                                              job.type
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Salary"
                                                                        value={
                                                                              job.salary
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Deadline"
                                                                        value={
                                                                              job.deadline
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Summary"
                                                                        value={
                                                                              job.summary
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Description"
                                                                        value={
                                                                              <div
                                                                                    dangerouslySetInnerHTML={{
                                                                                          __html: job.description
                                                                                    }}
                                                                              ></div>
                                                                        }
                                                                  />
                                                            </ListGroup>
                                                      </Col>
                                                      <Col lg={6}>
                                                            <ListGroup>
                                                                  {job.image && (
                                                                        <ListGroupItem className="text-center">
                                                                              <img
                                                                                    src={
                                                                                          job.image
                                                                                    }
                                                                                    alt={
                                                                                          job.title
                                                                                    }
                                                                                    className="border p-2 rounded"
                                                                                    style={{
                                                                                          width: '150px',
                                                                                          height: '150px',
                                                                                          objectFit:
                                                                                                'contain'
                                                                                    }}
                                                                              />
                                                                              <div className="mt-2">
                                                                                    Banner
                                                                                    Image
                                                                              </div>
                                                                        </ListGroupItem>
                                                                  )}
                                                                  <ItemShow
                                                                        title="Status"
                                                                        value={
                                                                              job.status ===
                                                                              1 ? (
                                                                                    <>
                                                                                          <label className="text-success p-2">
                                                                                                Active
                                                                                          </label>
                                                                                    </>
                                                                              ) : (
                                                                                    <>
                                                                                          <label className="text-danger p-2">
                                                                                                Disable
                                                                                          </label>
                                                                                    </>
                                                                              )
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Created By"
                                                                        value={
                                                                              job.user_name
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Date"
                                                                        value={
                                                                              job.date
                                                                        }
                                                                  />
                                                            </ListGroup>
                                                      </Col>
                                                </Row>
                                          ) : (
                                                <>
                                                      <div
                                                            className="text-center d-flex align-items-center justify-content-center w-100"
                                                            style={{
                                                                  height: '30vh'
                                                            }}
                                                      >
                                                            <Loading />
                                                      </div>
                                                </>
                                          )}
                                    </Card.Body>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default JobShow;
