import React from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';

const AmountWord = ({
      amountStatus,
      handleAmountNumber,
      amountNumber
}) => {
      return (
            <>
                  {amountStatus && (
                        <Card className="mt-3">
                              <Card.Body>
                                    <Form.Control
                                          as="textarea"
                                          onChange={(event) =>
                                                handleAmountNumber(
                                                      event.target.value
                                                )
                                          }
                                          value={amountNumber}
                                    />
                              </Card.Body>
                        </Card>
                  )}
            </>
      );
};

export default AmountWord;
