import React from 'react';
import { Card, Form, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { EditIcon, ShowIcon } from '../../components/icons';
import axios from 'axios';
import { useEffect } from 'react';
import { toast } from 'react-hot-toast';
import Progress from 'react-progress-2';
import { useState } from 'react';
import {
      CardHeader,
      CardLinks,
      CardTitle
} from '../../styles/components/common/Table';
import { LinkButton } from '../../styles/components/common/Button';
import TableSearch from '../../components/Form/Search';
import TableFooter from '../../components/Form/TableFooter';
import ProductImage from '../../components/Form/ProductImage';
import DeleteButton from '../../components/commons/DeleteButton';

const CatalogueList = () => {
      const [loading, setLoading] = useState(false);
      const [catalogues, setCatalogues] = useState([]);
      const [totalCatalogues, setTotalCatalogues] = useState(0);
      const [perPages, setPerPages] = useState(0);
      const [currentPage, setCurrentPage] = useState(0);

      const token = JSON.parse(localStorage.getItem('token'));
      const [limit, setLimit] = useState(10);
      const [search, setSearch] = useState('');

      const fetchCatalogue = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/catalogues?limit=${limit}&search=${search}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        setCatalogues(response.data.catalogues);
                        setTotalCatalogues(response.data.totalCatalogues);
                        setPerPages(response.data.itemsCountPerPage);
                        setCurrentPage(response.data.currentPage);
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            fetchCatalogue();
      }, [limit, search]);

      async function getData(pageNumber = 1) {
            window.screenTo(0, 0);
            Progress.show();
            const url = `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/catalogues?page=${pageNumber}&limit=${limit}&search=${search}`;
            await axios(url, {
                  headers: {
                        Authorization: `Bearer ${token}`
                  }
            })
                  .then((response) => {
                        setCatalogues(response.data.catalogues);
                        setTotalCatalogues(response.data.totalCatalogues);
                        setPerPages(response.data.itemsCountPerPage);
                        setCurrentPage(response.data.currentPage);
                        Progress.hide();
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      }

      const handleStatus = async (catalogue) => {
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/catalogues/${catalogue.slug}/status`,
                        {},
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              toast.success(response.data.message);
                        }
                        if (response.data.result === 'error') {
                              toast.error(response.data.message);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };

      const handleDelete = async (catalogue) => {
            setLoading(true);
            await axios
                  .delete(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/catalogues/${catalogue.slug}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setCatalogues([]);
                              fetchCatalogue();
                              toast.success(response.data.message);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      return (
            <>
                  <div className="row">
                        <div className="col-md-12">
                              <Card>
                                    <CardHeader>
                                          <CardTitle>Catalogue List</CardTitle>
                                          <CardLinks>
                                                <LinkButton to="/dashboard/catalogues/create">
                                                      Add New
                                                </LinkButton>
                                          </CardLinks>
                                    </CardHeader>
                                    <Card.Body>
                                          <TableSearch
                                                setLimit={setLimit}
                                                setSearch={setSearch}
                                          />
                                          <Table hover bordered>
                                                <thead>
                                                      <tr>
                                                            <th width="60px">
                                                                  #
                                                            </th>
                                                            <th>Title</th>
                                                            <th width="80px">
                                                                  Status
                                                            </th>
                                                            <th width="120px">
                                                                  Action
                                                            </th>
                                                      </tr>
                                                </thead>
                                                <tbody>
                                                      {!loading ? (
                                                            catalogues.map(
                                                                  (
                                                                        catalogue,
                                                                        index
                                                                  ) => (
                                                                        <>
                                                                              <tr
                                                                                    key={
                                                                                          index
                                                                                    }
                                                                              >
                                                                                    <td>
                                                                                          {
                                                                                                ++index
                                                                                          }
                                                                                    </td>
                                                                                    <td>
                                                                                          <ProductImage
                                                                                                title={
                                                                                                      catalogue.title
                                                                                                }
                                                                                                category={
                                                                                                      catalogue.type
                                                                                                }
                                                                                                image={
                                                                                                      catalogue.image
                                                                                                }
                                                                                          />
                                                                                    </td>
                                                                                    <td>
                                                                                          <Form.Check
                                                                                                type="switch"
                                                                                                id="custom-switch"
                                                                                                defaultChecked={
                                                                                                      catalogue.status ===
                                                                                                      1
                                                                                                            ? true
                                                                                                            : false
                                                                                                }
                                                                                                onBlur={() =>
                                                                                                      handleStatus(
                                                                                                            catalogue
                                                                                                      )
                                                                                                }
                                                                                          />
                                                                                    </td>
                                                                                    <td>
                                                                                          <Link
                                                                                                to={`/dashboard/catalogues/${catalogue.slug}/edit`}
                                                                                                className="text-secondary"
                                                                                          >
                                                                                                <EditIcon />
                                                                                          </Link>
                                                                                          <Link
                                                                                                to={`/dashboard/catalogues/${catalogue.slug}/show`}
                                                                                                className="text-success mx-2"
                                                                                          >
                                                                                                <ShowIcon />
                                                                                          </Link>
                                                                                          <DeleteButton
                                                                                                item={
                                                                                                      catalogue
                                                                                                }
                                                                                                handleDelete={
                                                                                                      handleDelete
                                                                                                }
                                                                                                loading={
                                                                                                      loading
                                                                                                }
                                                                                                setLoading={
                                                                                                      setLoading
                                                                                                }
                                                                                          />
                                                                                    </td>
                                                                              </tr>
                                                                        </>
                                                                  )
                                                            )
                                                      ) : (
                                                            <>
                                                                  <tr>
                                                                        <td
                                                                              colSpan={
                                                                                    4
                                                                              }
                                                                              className="text-center"
                                                                        >
                                                                              No
                                                                              Catalogue
                                                                              Found
                                                                        </td>
                                                                  </tr>
                                                            </>
                                                      )}
                                                </tbody>
                                          </Table>
                                          <TableFooter
                                                perPages={perPages}
                                                totalItems={totalCatalogues}
                                                currentPage={currentPage}
                                                getData={getData}
                                          />
                                    </Card.Body>
                              </Card>
                        </div>
                  </div>
            </>
      );
};

export default CatalogueList;
