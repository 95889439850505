import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Card, Col, ListGroup, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import LinkButton from '../../components/commons/LinkButton';
import CardHeader from '../../components/commons/CardHeader';
import ItemShow from '../../components/Form/ItemShow';

const ContactFormShow = () => {
      const { contactId } = useParams();
      const [contact, setContact] = useState([]);
      const [loadingData, setLoadingData] = useState(false);

      const loadData = async () => {
            setLoadingData(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/contact_forms/${contactId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setContact(response.data.contact);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoadingData(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader title="Contact Details">
                                          <LinkButton
                                                link="/dashboard/contact_requests"
                                                title="Back"
                                          />
                                    </CardHeader>
                                    <Card.Body>
                                          {!loadingData ? (
                                                <Row>
                                                      <Col lg="8">
                                                            <ListGroup>
                                                                  <ItemShow
                                                                        title="Full Name"
                                                                        value={
                                                                              contact.name
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Email Address"
                                                                        value={
                                                                              contact.email
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Phone"
                                                                        value={
                                                                              contact.phone_number
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Date"
                                                                        value={
                                                                              contact.date
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Message"
                                                                        value={
                                                                              contact.message
                                                                        }
                                                                  />
                                                            </ListGroup>
                                                      </Col>
                                                </Row>
                                          ) : null}
                                    </Card.Body>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default ContactFormShow;
