import React from 'react';
import { useParams } from 'react-router-dom';

const QuotationView = () => {
      const { quotationId } = useParams();

      return (
            <>
                  <iframe
                        src={`https://api.bathnroom.com/quotations/${quotationId}/show`}
                        frameborder="0"
                        style={{ width: '100%', height: 'calc(100vh - 10px)' }}
                  ></iframe>
            </>
      );
};

export default QuotationView;
