import React, { useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import {
      CardHeader,
      CardLinks,
      CardTitle
} from '../../styles/components/common/Table';
import { LinkButton } from '../../styles/components/common/Button';
import Input from '../../components/Form/Input';
import FormSeo from '../../components/commons/FormSeo';
import FormImage from '../../components/Form/image';

import $ from 'jquery';
import axios from 'axios';

import { toast } from 'react-hot-toast';
import FormButton from '../../components/Form/Button';
import { InputSelect } from '../../components/Form/Select';
import Textarea from '../../components/Form/Textarea';

const ShowroomCreate = () => {
      const [loading, setLoading] = useState('');
      const [title, setTitle] = useState('');
      const [slug, setSlug] = useState('');
      const [image, setImage] = useState('');
      const [imageUrl, setImageUrl] = useState('');
      const [seoTitle, setSeoTitle] = useState(null);
      const [seoKeyword, setSeoKeyword] = useState(null);
      const [seoDescription, setSeoDescription] = useState(null);
      const [bannerImage, setBannerImage] = useState('');
      const [bannerImageUrl, setBannerImageUrl] = useState(null);
      const [summary, setSummary] = useState('');
      const [address, setAddress] = useState('');
      const [phoneNumber, setPhoneNumber] = useState('');
      const [email, setEmail] = useState('');
      const [video, setVideo] = useState('');
      const [status, setStatus] = useState('');

      const token = JSON.parse(localStorage.getItem('token'));

      const handleSubmit = async (event) => {
            event.preventDefault();
            setLoading(true);

            const data = {
                  title: title,
                  slug: slug,
                  banner_image: bannerImage,
                  summary: summary,
                  address: address,
                  phone_number: phoneNumber,
                  email: email,
                  video: video,
                  status: status,
                  image: image,
                  seo_title: seoTitle,
                  seo_description: seoDescription,
                  seo_keyword: seoKeyword
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/showrooms`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                                    'Content-Type': 'multipart/form-data'
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              toast.success(response.data.message);
                              $('form').trigger('reset');
                              setImageUrl('');
                              setBannerImageUrl('');
                        }
                        if (response.data.result === 'error') {
                              if (response.data.message.title) {
                                    toast.error(response.data.message.title);
                              }
                              if (response.data.message.slug) {
                                    toast.error(response.data.message.slug);
                              }
                              if (response.data.message.video) {
                                    toast.error(response.data.message.video);
                              }
                              if (response.data.message.email) {
                                    toast.error(response.data.message.email);
                              }
                              if (response.data.message.address) {
                                    toast.error(response.data.message.address);
                              }
                              if (response.data.message.summary) {
                                    toast.error(response.data.message.summary);
                              }
                              if (response.data.message.phone_number) {
                                    toast.error(
                                          response.data.message.phone_number
                                    );
                              }
                              if (response.data.message.banner_image) {
                                    toast.error(
                                          response.data.message.banner_image
                                    );
                              }
                              if (response.data.message.image) {
                                    toast.error(response.data.message.image);
                              }
                              if (response.data.message.seo_title) {
                                    toast.error(
                                          response.data.message.seo_title
                                    );
                              }
                              if (response.data.message.seo_keyword) {
                                    toast.error(
                                          response.data.message.seo_keyword
                                    );
                              }
                              if (response.data.message.seo_description) {
                                    toast.error(
                                          response.data.message.seo_description
                                    );
                              }
                              if (response.data.message.status) {
                                    toast.error(response.data.message.status);
                              }
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      return (
            <>
                  <Form onSubmit={handleSubmit}>
                        <Row>
                              <Col lg={12}>
                                    <Card>
                                          <CardHeader>
                                                <CardTitle>
                                                      Create new Showroom
                                                </CardTitle>
                                                <CardLinks>
                                                      <LinkButton to="/dashboard/showrooms">
                                                            Back
                                                      </LinkButton>
                                                </CardLinks>
                                          </CardHeader>

                                          <Card.Body>
                                                <Row className="g-3">
                                                      <Input
                                                            title="Name"
                                                            classes={8}
                                                            required={true}
                                                            handleChange={
                                                                  setTitle
                                                            }
                                                      />
                                                      <Input
                                                            title="Slug"
                                                            classes={4}
                                                            required={true}
                                                            handleChange={
                                                                  setSlug
                                                            }
                                                      />
                                                      <Input
                                                            title="Address"
                                                            required={true}
                                                            classes={4}
                                                            handleChange={
                                                                  setAddress
                                                            }
                                                      />
                                                      <Input
                                                            title="Email"
                                                            required={true}
                                                            classes={4}
                                                            handleChange={
                                                                  setEmail
                                                            }
                                                      />
                                                      <Input
                                                            title="Phone No."
                                                            required={true}
                                                            classes={4}
                                                            handleChange={
                                                                  setPhoneNumber
                                                            }
                                                      />
                                                      <InputSelect
                                                            title="Status"
                                                            classes={4}
                                                            setStatus={
                                                                  setStatus
                                                            }
                                                      />
                                                      <Input
                                                            title="Video"
                                                            classes={8}
                                                            required={true}
                                                            handleChange={
                                                                  setVideo
                                                            }
                                                      />
                                                </Row>
                                          </Card.Body>
                                    </Card>

                                    <Card className="mt-4">
                                          <Card.Body>
                                                <Row className="g-3">
                                                      <Textarea
                                                            required={true}
                                                            title="Summary"
                                                            handleChange={
                                                                  setSummary
                                                            }
                                                      />
                                                </Row>
                                          </Card.Body>
                                    </Card>

                                    <Card className="mt-4">
                                          <Card.Body>
                                                <Row className="g-3">
                                                      <FormImage
                                                            title="Featured Image"
                                                            setImage={setImage}
                                                            setImageUrl={
                                                                  setImageUrl
                                                            }
                                                            required={true}
                                                            imageUrl={imageUrl}
                                                      />
                                                      <FormImage
                                                            title="Banner Image"
                                                            setImage={
                                                                  setBannerImage
                                                            }
                                                            setImageUrl={
                                                                  setBannerImageUrl
                                                            }
                                                            required={true}
                                                            imageUrl={
                                                                  bannerImageUrl
                                                            }
                                                      />
                                                </Row>
                                          </Card.Body>
                                    </Card>

                                    <FormSeo
                                          setSeoDescription={setSeoDescription}
                                          setSeoTitle={setSeoTitle}
                                          setSeoKeyword={setSeoKeyword}
                                    />

                                    <Card className="mt-4 mb-5">
                                          <Card.Body>
                                                <Row>
                                                      <Col lg={12}>
                                                            <FormButton
                                                                  title="Submit"
                                                                  loading={
                                                                        loading
                                                                  }
                                                            />
                                                      </Col>
                                                </Row>
                                          </Card.Body>
                                    </Card>
                              </Col>
                        </Row>
                  </Form>
            </>
      );
};

export default ShowroomCreate;
