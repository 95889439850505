import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Table } from 'react-bootstrap';
import {
      CardHeader,
      CardLinks,
      CardTitle
} from '../../styles/components/common/Table';

import Progress from 'react-progress-2';
import { LinkButton } from '../../styles/components/common/Button';
import TableSearch from '../../components/Form/Search';
import axios from 'axios';
import toast from 'react-hot-toast';
import { Loading } from '../../components/Loading';
import ProductImage from '../../components/Form/ProductImage';
import { Link } from 'react-router-dom';
import { EditIcon, ShowIcon } from '../../components/icons';
import TableFooter from '../../components/Form/TableFooter';
import DeleteButton from '../../components/commons/DeleteButton';

function ContactList() {
      const [loading, setLoading] = useState(false);
      const [contacts, setContacts] = useState([]);
      const [totalContacts, setTotalContacts] = useState(0);
      const [perPages, setPerPages] = useState(0);
      const [currentPage, setCurrentPage] = useState(0);

      const token = JSON.parse(localStorage.getItem('token'));
      const [limit, setLimit] = useState(10);
      const [search, setSearch] = useState('');

      const loadData = async () => {
            setLoading(true);

            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/contact_persons?limit=${limit}&search=${search}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`
                              }
                        }
                  )
                  .then((response) => {
                        setContacts(response.data.contacts);
                        setTotalContacts(response.data.totalContacts);
                        setPerPages(response.data.itemsCountPerPage);
                        setCurrentPage(response.data.currentPage);
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, [limit, search]);

      async function getData(pageNumber = 1) {
            window.scrollTo(0, 0);
            setLoading(true);
            Progress.show();
            const url = `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/contact_persons?page=${pageNumber}&limit=${limit}&search=${search}`;
            await axios(url, {
                  headers: {
                        Authorization: `Bearer ${token}`
                  }
            })
                  .then((response) => {
                        setContacts(response.data.contacts);
                        setTotalContacts(response.data.totalContacts);
                        setPerPages(response.data.itemsCountPerPage);
                        setCurrentPage(response.data.currentPage);
                        Progress.hide();
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      }

      const handleDelete = async (contact) => {
            setLoading(true);
            await axios
                  .delete(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/contact_persons/${contact.slug}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              loadData();
                              toast.success(response.data.message);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      return (
            <>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader>
                                          <CardTitle>Contact List</CardTitle>
                                          <CardLinks>
                                                <LinkButton to="/dashboard/contacts/create">
                                                      Add New
                                                </LinkButton>
                                          </CardLinks>
                                    </CardHeader>
                                    <Card.Body>
                                          <TableSearch
                                                setLimit={setLimit}
                                                setSearch={setSearch}
                                          />
                                          <Table hover bordered>
                                                <thead>
                                                      <tr>
                                                            <th>#</th>
                                                            <th>Full Name</th>
                                                            <th>
                                                                  Contact
                                                                  Details
                                                            </th>
                                                            <th>Company</th>
                                                            <th width="120px">
                                                                  Action
                                                            </th>
                                                      </tr>
                                                </thead>
                                                <tbody>
                                                      {!loading ? (
                                                            contacts?.length >
                                                            0 ? (
                                                                  contacts.map(
                                                                        (
                                                                              contact,
                                                                              index
                                                                        ) => (
                                                                              <>
                                                                                    <tr
                                                                                          key={
                                                                                                index
                                                                                          }
                                                                                    >
                                                                                          <td>
                                                                                                {
                                                                                                      contact.index
                                                                                                }
                                                                                          </td>
                                                                                          <td>
                                                                                                <ProductImage
                                                                                                      title={
                                                                                                            contact.contact_person
                                                                                                      }
                                                                                                      category={
                                                                                                            'UserId: ' +
                                                                                                            contact.user_name
                                                                                                      }
                                                                                                />
                                                                                          </td>
                                                                                          <td>
                                                                                                <ProductImage
                                                                                                      title={
                                                                                                            contact.phone_number
                                                                                                      }
                                                                                                      category={
                                                                                                            'Address: ' +
                                                                                                            contact.address
                                                                                                      }
                                                                                                />
                                                                                          </td>
                                                                                          <td>
                                                                                                <ProductImage
                                                                                                      title={
                                                                                                            contact.company_name
                                                                                                                  ? contact.company_name
                                                                                                                  : '-'
                                                                                                      }
                                                                                                      category={
                                                                                                            'Position: ' +
                                                                                                            contact.position
                                                                                                      }
                                                                                                />
                                                                                          </td>
                                                                                          <td className="text-center">
                                                                                                <Link
                                                                                                      to={`/dashboard/contacts/${contact.slug}/edit`}
                                                                                                      className="text-secondary"
                                                                                                >
                                                                                                      <EditIcon />
                                                                                                </Link>
                                                                                                <Link
                                                                                                      to={`/dashboard/contacts/${contact.slug}/show`}
                                                                                                      className="text-success mx-2"
                                                                                                >
                                                                                                      <ShowIcon />
                                                                                                </Link>
                                                                                                <DeleteButton
                                                                                                      item={
                                                                                                            contact
                                                                                                      }
                                                                                                      loading={
                                                                                                            loading
                                                                                                      }
                                                                                                      setLoading={
                                                                                                            setLoading
                                                                                                      }
                                                                                                      handleDelete={
                                                                                                            handleDelete
                                                                                                      }
                                                                                                />
                                                                                          </td>
                                                                                    </tr>
                                                                              </>
                                                                        )
                                                                  )
                                                            ) : (
                                                                  <tr>
                                                                        <td
                                                                              colSpan={
                                                                                    5
                                                                              }
                                                                              className="text-center"
                                                                        >
                                                                              No
                                                                              Contact
                                                                              Found
                                                                        </td>
                                                                  </tr>
                                                            )
                                                      ) : (
                                                            <tr>
                                                                  <td
                                                                        colSpan={
                                                                              5
                                                                        }
                                                                        className="text-center"
                                                                  >
                                                                        <Loading />
                                                                  </td>
                                                            </tr>
                                                      )}
                                                </tbody>
                                          </Table>
                                          <TableFooter
                                                perPages={perPages}
                                                totalItems={totalContacts}
                                                currentPage={currentPage}
                                                getData={getData}
                                          />
                                    </Card.Body>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
}

export default ContactList;
