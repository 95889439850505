import React from 'react';
import NavSeparator from '../Navigation/NavSeparator';
import {
      MetisMenuIcon,
      MetisMenuLink,
      MetisMenuTitle
} from '../../styles/layouts/navigation';
import { SettingIcon } from '../icons';
import { NavLink } from 'react-router-dom';
import CheckMultiplePermission from '../roles/CheckMultiplePermission';

const SystemManage = ({ userPermissions }) => {
      return (
            <>
                  <CheckMultiplePermission
                        userPermissions={userPermissions}
                        permissions={[
                              'utilities-list',
                              'company-profile',
                              'general-information',
                              'database-backup',
                              'mail-setting',
                              'notification-setting'
                        ]}
                  >
                        <NavSeparator title="System Setting" />
                        <li>
                              <MetisMenuLink className="has-arrow" href="#">
                                    <MetisMenuIcon>
                                          <SettingIcon />
                                    </MetisMenuIcon>
                                    <MetisMenuTitle>
                                          <span>System Manage</span>
                                    </MetisMenuTitle>
                              </MetisMenuLink>
                              <ul>
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={['general-information']}
                                    >
                                          <li>
                                                <NavLink to="/dashboard/general-information">
                                                      General Information
                                                </NavLink>
                                          </li>
                                    </CheckMultiplePermission>

                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={['company-profile']}
                                    >
                                          <li>
                                                <NavLink to="/dashboard/company-profile">
                                                      Company Profile
                                                </NavLink>
                                          </li>
                                    </CheckMultiplePermission>

                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={['database-backup']}
                                    >
                                          <li>
                                                <NavLink to="/dashboard/database-backup">
                                                      Database Backup
                                                </NavLink>
                                          </li>
                                    </CheckMultiplePermission>

                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={['utilities-list']}
                                    >
                                          <li>
                                                <NavLink to="/dashboard/utilities">
                                                      Utilities
                                                </NavLink>
                                          </li>
                                    </CheckMultiplePermission>
                              </ul>
                        </li>
                        <CheckMultiplePermission
                              userPermissions={userPermissions}
                              permissions={[
                                    'notification-setting',
                                    'mail-setting'
                              ]}
                        >
                              <li>
                                    <MetisMenuLink
                                          className="has-arrow"
                                          href="#"
                                    >
                                          <MetisMenuIcon>
                                                <SettingIcon />
                                          </MetisMenuIcon>
                                          <MetisMenuTitle>
                                                <span>Notification Manage</span>
                                          </MetisMenuTitle>
                                    </MetisMenuLink>
                                    <ul>
                                          <CheckMultiplePermission
                                                userPermissions={
                                                      userPermissions
                                                }
                                                permissions={[
                                                      'notification-setting'
                                                ]}
                                          >
                                                <li>
                                                      <NavLink to="/dashboard/notification-setting">
                                                            Notification Setting
                                                      </NavLink>
                                                </li>
                                          </CheckMultiplePermission>

                                          <CheckMultiplePermission
                                                userPermissions={
                                                      userPermissions
                                                }
                                                permissions={['mail-setting']}
                                          >
                                                <li>
                                                      <NavLink to="/dashboard/mail-setting">
                                                            Mail Setting
                                                      </NavLink>
                                                </li>
                                          </CheckMultiplePermission>
                                    </ul>
                              </li>
                        </CheckMultiplePermission>
                  </CheckMultiplePermission>
            </>
      );
};

export default SystemManage;
