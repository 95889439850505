import React, { useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { CardHeader, CardTitle } from '../../styles/components/common/Table';
import MultipleImage from '../../components/Form/image/MultipleImage';
import FormButton from '../../components/Form/Button';
import axios from 'axios';
import toast from 'react-hot-toast';

const ImageCreate = () => {
      const [files, setFiles] = useState([]);
      const [images, setImages] = useState([]);
      const [loading, setLoading] = useState(false);
      const token = JSON.parse(localStorage.getItem('token'));

      const handleForm = async (event) => {
            event.preventDefault();

            setLoading(true);
            const data = {
                  files: files
            };
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/products/images/update`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                                    'Content-Type': 'multipart/form-data'
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              toast.success(response.data.message);
                              setFiles([]);
                              setImages([]);
                        }

                        if (response.data.result === 'error') {
                              if (response.data.errorMessage) {
                                    toast.error(response.data.errorMessage);
                              }
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      return (
            <>
                  <Row className='g-4'>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader>
                                          <CardTitle>
                                                Update Product Image
                                          </CardTitle>
                                    </CardHeader>
                                    <Card.Body>
                                          <Form onSubmit={handleForm}>
                                                <Row>
                                                      <MultipleImage
                                                            title="Upload Multiple Images File"
                                                            setImages={
                                                                  setImages
                                                            }
                                                            images={images}
                                                            classes={12}
                                                            setImageFiles={
                                                                  setFiles
                                                            }
                                                      />
                                                      <Col lg={12}>
                                                            <FormButton
                                                                  title="Submit"
                                                                  loading={
                                                                        loading
                                                                  }
                                                            />
                                                      </Col>
                                                </Row>
                                          </Form>
                                    </Card.Body>
                              </Card>
                        </Col>
                        <Col lg={12}>
                              <Card>
                                    <Card.Body>
                                          <h5>Terms & Condition for image</h5>
                                          <ul>
                                                <li>
                                                      Image name should be
                                                      similar with the existing
                                                      product list.
                                                </li>
                                                <li>
                                                      Image size should have 500
                                                      x 500 size.
                                                </li>
                                                <li>
                                                      jpg, png, jpeg, webp file
                                                      has been support.
                                                </li>
                                                <li>
                                                      Don't upload the unkown
                                                      image which is not associated with any product.
                                                </li>
                                                <li>
                                                      Image should be in lower
                                                      case. Image name should be
                                                      similar in image column.
                                                </li>
                                                <li>
                                                      Use '-' instead of space
                                                      and don't forget the
                                                      extension of image
                                                </li>
                                          </ul>
                                    </Card.Body>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default ImageCreate;
