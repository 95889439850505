import React from 'react';
import { Card, Col, Image, ListGroup, Row } from 'react-bootstrap';
import {
      CardHeader,
      CardLinks,
      CardTitle
} from '../../styles/components/common/Table';
import { LinkButton } from '../../styles/components/common/Button';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import ItemShow from '../../components/Form/ItemShow';
import { Loading } from '../../components/Loading';

const ShowroomCollectionShow = () => {
      const { collectionId } = useParams();

      const [loading, setLoading] = useState(false);
      const [collection, setCollection] = useState([]);

      const token = JSON.parse(localStorage.getItem('token'));

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/showroom_collections/${collectionId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setCollection(response.data.collection);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <Row className="g-4">
                        <Col lg={12}>
                              <Card>
                                    <CardHeader>
                                          <CardTitle>
                                                Showroom Collection Details
                                          </CardTitle>
                                          <CardLinks>
                                                <LinkButton to="/dashboard/showroom_collections">
                                                      Back
                                                </LinkButton>
                                          </CardLinks>
                                    </CardHeader>
                                    <Card.Body>
                                          {!loading ? (
                                                <Row>
                                                      <Col lg={6}>
                                                            <ListGroup>
                                                                  <ItemShow
                                                                        title=" Title"
                                                                        value={
                                                                              collection.title
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Showroom"
                                                                        value={
                                                                              collection.showroom
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Layout"
                                                                        value={
                                                                              collection.layout
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Created At"
                                                                        value={`Rs ${collection.date}`}
                                                                  />
                                                                  <ItemShow
                                                                        title="Created By"
                                                                        value={`Rs ${collection.user_name}`}
                                                                  />
                                                            </ListGroup>
                                                      </Col>
                                                      <Col lg={6}>
                                                            <div className="d-flex gap-3">
                                                                  <div>
                                                                        <Image
                                                                              src={
                                                                                    collection.image
                                                                              }
                                                                              alt={
                                                                                    collection.title
                                                                              }
                                                                              style={{
                                                                                    width: '220px',
                                                                                    height: '220px',
                                                                                    objectFit:
                                                                                          'contain'
                                                                              }}
                                                                        />
                                                                        <div>
                                                                              Feature
                                                                              Image
                                                                        </div>
                                                                  </div>
                                                            </div>
                                                            <div className="mt-3">
                                                                  <div
                                                                        dangerouslySetInnerHTML={{
                                                                              __html: collection.summary
                                                                        }}
                                                                  ></div>
                                                            </div>
                                                      </Col>
                                                </Row>
                                          ) : (
                                                <>
                                                      <div
                                                            className="text-center d-flex align-items-center justify-content-center w-100"
                                                            style={{
                                                                  height: '30vh'
                                                            }}
                                                      >
                                                            <Loading />
                                                      </div>
                                                </>
                                          )}
                                    </Card.Body>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default ShowroomCollectionShow;
