import React, { useState, useEffect } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import {
      CardHeader,
      CardLinks,
      CardTitle
} from '../../styles/components/common/Table';
import { LinkButton } from '../../styles/components/common/Button';

import $ from 'jquery';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import Input from '../../components/Form/Input';
import Select from '../../components/Form/Select';
import FormButton from '../../components/Form/Button';
import Textarea from '../../components/Form/Textarea';
import FormSelect from '../../components/Form/FormSelect';
import { useParams } from 'react-router-dom';

const options = [
      {
            id: 'Body',
            title: 'Body'
      },
      {
            id: 'Header',
            title: 'Header'
      }
];

const tags = [
      {
            id: 'Meta',
            title: 'Meta'
      },
      {
            id: 'Script',
            title: 'Script'
      },
      {
            id: 'Script Link',
            title: 'Script Link'
      }
];

const PluginEdit = () => {
      const { pluginId } = useParams();

      const [loadingData, setLoadingData] = useState(false);
      const [plugin, setPlugin] = useState([]);

      const [title, setTitle] = useState('');
      const [code, setCode] = useState('');
      const [type, setType] = useState('');
      const [tagType, setTagType] = useState('');
      const [status, setStatus] = useState('');
      const [loading, setLoading] = useState(false);

      const token = JSON.parse(localStorage.getItem('token'));

      const loadData = async () => {
            setLoadingData(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/plugins/${pluginId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setPlugin(response.data.plugin);
                              setTitle(response.data.plugin.title);
                              setStatus(response.data.plugin.status);
                              setType(response.data.plugin.type);
                              setTagType(response.data.plugin.tag_type);
                              setCode(response.data.plugin.code);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoadingData(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      const handleForm = async (event) => {
            event.preventDefault();
            setLoading(true);
            const data = {
                  title: title,
                  code: code,
                  type: type,
                  tag_type: tagType,
                  status: status
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/plugins/${pluginId}`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                                    'Content-Type': 'multipart/form-data'
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              toast.success(response.data.message);
                        }
                        if (response.data.result === 'error') {
                              if (response.data.message.title) {
                                    toast.error(response.data.message.title);
                              }
                              if (response.data.message.type) {
                                    toast.error(response.data.message.type);
                              }
                              if (response.data.message.code) {
                                    toast.error(response.data.message.code);
                              }
                              if (response.data.message.status) {
                                    toast.error(response.data.message.status);
                              }
                              if (response.data.message.tag_type) {
                                    toast.error(response.data.message.tag_type);
                              }
                        }
                        setLoading(false);
                  });
      };

      return (
            <>
                  <Form onSubmit={handleForm}>
                        <Row>
                              <Col lg={12}>
                                    <Card>
                                          <CardHeader>
                                                <CardTitle>
                                                      Create New Plugin
                                                </CardTitle>
                                                <CardLinks>
                                                      <LinkButton to="/dashboard/plugins">
                                                            Back
                                                      </LinkButton>
                                                </CardLinks>
                                          </CardHeader>
                                          <Card.Body>
                                                <Row className="g-3">
                                                      <Input
                                                            title="Title"
                                                            classes={8}
                                                            required={true}
                                                            value={title}
                                                            handleChange={(
                                                                  event
                                                            ) =>
                                                                  setTitle(
                                                                        event
                                                                              .target
                                                                              .value
                                                                  )
                                                            }
                                                      />
                                                      <FormSelect
                                                            loading={
                                                                  loadingData
                                                            }
                                                            title="Plugin Type"
                                                            selected={type}
                                                            required={true}
                                                            options={options}
                                                            setData={setType}
                                                            classes={4}
                                                      />
                                                      <FormSelect
                                                            loading={
                                                                  loadingData
                                                            }
                                                            title="Tag Type"
                                                            selected={tagType}
                                                            required={true}
                                                            options={tags}
                                                            setData={setTagType}
                                                            classes={4}
                                                      />
                                                      <Select
                                                            title="Status"
                                                            classes={4}
                                                            selected={status}
                                                            setStatus={
                                                                  setStatus
                                                            }
                                                      />
                                                      <Textarea
                                                            title="Code"
                                                            required={true}
                                                            value={code}
                                                            handleChange={
                                                                  setCode
                                                            }
                                                      />
                                                </Row>
                                          </Card.Body>
                                    </Card>

                                    <Card className="mt-4 mb-5">
                                          <Card.Body>
                                                <Row>
                                                      <Col lg={12}>
                                                            <FormButton
                                                                  title="Submit"
                                                                  loading={
                                                                        loading
                                                                  }
                                                            />
                                                      </Col>
                                                </Row>
                                          </Card.Body>
                                    </Card>
                              </Col>
                        </Row>
                  </Form>
            </>
      );
};

export default PluginEdit;
