import React from 'react';
import { useState } from 'react';
import { Card, Col, ListGroup, ListGroupItem, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import {
      CardHeader,
      CardLinks,
      CardTitle
} from '../../styles/components/common/Table';

import Progress from 'react-progress-2';
import 'react-progress-2/main.css';
import { LinkButton } from '../../styles/components/common/Button';
import ItemShow from '../../components/Form/ItemShow';
import ProductList from '../../components/ProductList';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { useEffect } from 'react';
import { Loading } from '../../components/Loading';

const CategoryShow = () => {
      const { categoryId } = useParams();

      const [category, setCategory] = useState([]);

      const [loading, setLoading] = useState(false);
      const [products, setProducts] = useState([]);
      const [totalProducts, setTotalProducts] = useState(0);
      const [perPages, setPerPages] = useState(0);
      const [currentPage, setCurrentPage] = useState(0);

      const token = JSON.parse(localStorage.getItem('token'));
      const [limit, setLimit] = useState(10);
      const [search, setSearch] = useState('');

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/categories/${categoryId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setCategory(response.data.category);
                        }
                  });

            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      const [loadingProduct, setLoadingProduct] = useState(false);

      const loadProduct = async () => {
            setLoadingProduct(true);

            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/categories/${categoryId}/create?limit=${limit}&search=${search}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setProducts(response.data.products);
                              setTotalProducts(response.data.totalProducts);
                              setPerPages(response.data.itemsCountPerPage);
                              setCurrentPage(response.data.currentPage);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });

            setLoadingProduct(false);
      };

      useEffect(() => {
            loadProduct();
      }, [limit, search]);

      async function getProductData(pageNumber = 1) {
            window.scrollTo(0, 0);
            Progress.show();

            const url = `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/categories/${categoryId}/create?page=${pageNumber}&limit=${limit}&search=${search}`;
            await axios(url, {
                  headers: {
                        Authorization: `Bearer ${token}`
                  }
            })
                  .then((response) => {
                        setProducts(response.data.products);
                        setTotalProducts(response.data.totalProducts);
                        setPerPages(response.data.itemsCountPerPage);
                        setCurrentPage(response.data.currentPage);
                        Progress.hide();
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      }

      return (
            <>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader>
                                          <CardTitle>
                                                Category Details
                                          </CardTitle>
                                          <CardLinks>
                                                <LinkButton to="/dashboard/categories">
                                                      Back
                                                </LinkButton>
                                          </CardLinks>
                                    </CardHeader>
                                    <Card.Body>
                                          {!loading ? (
                                                <Row>
                                                      <Col lg={6}>
                                                            <ListGroup>
                                                                  <ItemShow
                                                                        title="Title"
                                                                        value={
                                                                              category.title
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Products"
                                                                        value={
                                                                              category.products
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Website"
                                                                        value={
                                                                              category.website
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Description"
                                                                        value={
                                                                              category.description
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="SEO Title"
                                                                        value={
                                                                              category.seo_title
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="SEO Keyword"
                                                                        value={
                                                                              category.seo_keyword
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="SEO Description"
                                                                        value={
                                                                              category.seo_description
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Created By"
                                                                        value={
                                                                              category.user_name
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Created Date"
                                                                        value={
                                                                              category.date
                                                                        }
                                                                  />
                                                            </ListGroup>
                                                      </Col>
                                                      <Col lg={6}>
                                                            <ListGroup>
                                                                  {category.image && (
                                                                        <ListGroupItem className="text-center">
                                                                              <img
                                                                                    src={
                                                                                          category.image
                                                                                    }
                                                                                    alt={
                                                                                          category.title
                                                                                    }
                                                                                    className="border p-2 rounded"
                                                                                    style={{
                                                                                          width: '150px',
                                                                                          height: '150px',
                                                                                          objectFit:
                                                                                                'contain'
                                                                                    }}
                                                                              />
                                                                              <div className="mt-2">
                                                                                    Featured
                                                                                    Image
                                                                              </div>
                                                                        </ListGroupItem>
                                                                  )}
                                                                  {category.brand_banner && (
                                                                        <ListGroupItem>
                                                                              <img
                                                                                    src={
                                                                                          category.brand_banner
                                                                                    }
                                                                                    alt={
                                                                                          category.title
                                                                                    }
                                                                                    className="border p-2 rounded"
                                                                                    style={{
                                                                                          width: '150px',
                                                                                          height: '150px',
                                                                                          objectFit:
                                                                                                'contain'
                                                                                    }}
                                                                              />
                                                                              <div>
                                                                                    Category
                                                                                    Banner
                                                                              </div>
                                                                        </ListGroupItem>
                                                                  )}
                                                            </ListGroup>
                                                      </Col>
                                                </Row>
                                          ) : (
                                                <>
                                                      <div
                                                            className="text-center d-flex align-items-center justify-content-center w-100"
                                                            style={{
                                                                  height: '30vh'
                                                            }}
                                                      >
                                                            <Loading />
                                                      </div>
                                                </>
                                          )}
                                    </Card.Body>
                              </Card>
                              {!loadingProduct && (
                                    <ProductList
                                          loading={loadingProduct}
                                          loadData={loadProduct}
                                          setLoading={setLoadingProduct}
                                          products={products}
                                          setProducts={setProducts}
                                          totalProducts={totalProducts}
                                          perPages={perPages}
                                          currentPage={currentPage}
                                          setLimit={setLimit}
                                          setSearch={setSearch}
                                          getProductData={getProductData}
                                    />
                              )}
                        </Col>
                  </Row>
            </>
      );
};

export default CategoryShow;
