import { event } from 'jquery';
import React, { useState } from 'react';
import { Col, FormSelect, Row } from 'react-bootstrap';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import {
      ProductListContent,
      ProductListItems,
      ProductListTitle,
      QuoteCountBox
} from '../../styles/backend/product';

import Offcanvas from 'react-bootstrap/Offcanvas';
import BrandFilter from './filters/BrandFilter';
import CategoryFilter from './filters/CategoryFilter';

const FilterProduct = ({
      loading,
      categories,
      brands,
      quotations,
      handleBrandChange,
      handleCategoryChange,
      totalProducts
}) => {
      const [show, setShow] = useState(false);

      const handleClose = () => setShow(false);
      const handleShow = () => setShow(true);

      return (
            <>
                  <div className="d-flex justify-content-between w-100 align-items-center">
                        <QuoteCountBox onClick={handleShow} role="button">
                              <div>Filter</div>
                              <div>
                                    <span className="material-symbols-outlined">
                                          filter_alt
                                    </span>
                              </div>
                        </QuoteCountBox>
                        <div>{totalProducts} products found</div>
                        <QuoteCountBox href="/dashboard/quotations/checkout">
                              <div>{quotations.length}</div>
                              <div>
                                    <span className="material-symbols-outlined">
                                          add_notes
                                    </span>
                              </div>
                        </QuoteCountBox>
                  </div>

                  <Offcanvas show={show} onHide={handleClose}>
                        <Offcanvas.Header closeButton>
                              <Offcanvas.Title>Filter By</Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body className="p-0">
                              <ProductListItems>
                                    <ProductListTitle>
                                          <h2>Category</h2>
                                    </ProductListTitle>
                                    <ProductListContent>
                                          <CategoryFilter
                                                handleChangeCategory={
                                                      handleCategoryChange
                                                }
                                                loading={loading}
                                                categories={categories}
                                          />
                                    </ProductListContent>
                              </ProductListItems>
                              <ProductListItems>
                                    <ProductListTitle>
                                          <h2>Brand</h2>
                                    </ProductListTitle>
                                    <ProductListContent>
                                          <BrandFilter
                                                loading={loading}
                                                brands={brands}
                                                changeChecked={
                                                      handleBrandChange
                                                }
                                                type="brand"
                                          />
                                    </ProductListContent>
                              </ProductListItems>
                        </Offcanvas.Body>
                  </Offcanvas>
            </>
      );
};

export default FilterProduct;
