import React from 'react';
import CheckMultiplePermission from '../roles/CheckMultiplePermission';
import {
      MenuSeparator,
      MetisMenuIcon,
      MetisMenuLink,
      MetisMenuTitle
} from '../../styles/layouts/navigation';
import { NavLink } from 'react-router-dom';

const FrontManage = ({ userPermissions }) => {
      return (
            <>
                  <CheckMultiplePermission
                        userPermissions={userPermissions}
                        permissions={[
                              'visitor-list',
                              'visitor-edit',
                              'visitor-create',
                              'visitor-show',
                              'general-call-list',
                              'general-call-create',
                              'general-call-edit',
                              'general-call-show',
                              'contact-list',
                              'contact-create',
                              'contact-show',
                              'contact-edit'
                        ]}
                  >
                        <MenuSeparator>Frontend cms</MenuSeparator>
                        <li>
                              <MetisMenuLink className="has-arrow" href="#">
                                    <MetisMenuIcon>
                                          <span className="material-symbols-outlined">
                                                visibility
                                          </span>
                                    </MetisMenuIcon>
                                    <MetisMenuTitle>
                                          <span>Front Desk</span>
                                    </MetisMenuTitle>
                              </MetisMenuLink>
                              <ul>
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                'visitor-create',
                                                'visitor-edit',
                                                'visitor-create',
                                                'visitor-show'
                                          ]}
                                    >
                                          <li>
                                                <NavLink to="/dashboard/visitors">
                                                      Visitor Book
                                                </NavLink>
                                          </li>
                                    </CheckMultiplePermission>
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                'general-call-list',
                                                'general-call-create',
                                                'general-call-show',
                                                'general-call-edit'
                                          ]}
                                    >
                                          <li>
                                                <NavLink to="/dashboard/generalcalls">
                                                      Phone Call Logs
                                                </NavLink>
                                          </li>
                                    </CheckMultiplePermission>
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                'contact-list',
                                                'contact-create',
                                                'contact-show',
                                                'contact-edit'
                                          ]}
                                    >
                                          <li>
                                                <NavLink to="/dashboard/contacts">
                                                      Contact List
                                                </NavLink>
                                          </li>
                                    </CheckMultiplePermission>
                              </ul>
                        </li>
                  </CheckMultiplePermission>
                  <CheckMultiplePermission
                        userPermissions={userPermissions}
                        permissions={[
                              'company-list',
                              'company-edit',
                              'company-create',
                              'company-delete'
                        ]}
                  >
                        <li>
                              <MetisMenuLink className="has-arrow" href="#">
                                    <MetisMenuIcon>
                                          <span className="material-symbols-outlined">
                                                account_balance
                                          </span>
                                    </MetisMenuIcon>
                                    <MetisMenuTitle>
                                          <span>Company List</span>
                                    </MetisMenuTitle>
                              </MetisMenuLink>
                              <ul>
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                'company-create',
                                                'company-edit',
                                                'company-create',
                                                'company-delete'
                                          ]}
                                    >
                                          <li>
                                                <NavLink to="/dashboard/companies">
                                                      All Company
                                                </NavLink>
                                          </li>
                                    </CheckMultiplePermission>
                              </ul>
                        </li>
                  </CheckMultiplePermission>
            </>
      );
};

export default FrontManage;
