import React from 'react';
import { Card, Col, Row, Table } from 'react-bootstrap';
import { CardHeader, CardTitle } from '../../styles/components/common/Table';
import TableSearch from '../../components/Form/Search';
import ItemStatus from '../../components/Form/Status';
import ProductImage from '../../components/Form/ProductImage';

import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import Progress from 'react-progress-2';
import { Loading } from '../../components/Loading';
import TableFooter from '../../components/Form/TableFooter';
import DeleteButton from '../../components/commons/DeleteButton';

const SubscriberList = () => {
      const [loading, setLoading] = useState(false);
      const [subscribers, setSubscribers] = useState([]);
      const [totalSubscribers, setTotalSubscribers] = useState(0);
      const [perPages, setPerPages] = useState(0);
      const [currentPage, setCurrentPage] = useState(0);

      const token = JSON.parse(localStorage.getItem('token'));
      const [limit, setLimit] = useState(10);
      const [search, setSearch] = useState('');

      const loadData = async () => {
            setLoading(true);
            await axios(
                  `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/subscribers?limit=${limit}&search=${search}`,
                  {
                        headers: {
                              Authorization: `Bearer ${token}`
                        }
                  }
            )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setSubscribers(response.data.subscribers);
                              setTotalSubscribers(
                                    response.data.totalSubscribers
                              );
                              setPerPages(response.data.itemsCountPerPage);
                              setCurrentPage(response.data.currentPage);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };
      useEffect(() => {
            loadData();
      }, [limit, search]);

      async function getData(pageNumber = 1) {
            window.scrollTo(0, 0);
            setLoading(true);
            Progress.show();
            const url = `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/subscribers?page=${pageNumber}&limit=${limit}&search=${search}`;
            await axios(url, {
                  headers: {
                        Authorization: `Bearer ${token}`
                  }
            })
                  .then((response) => {
                        setSubscribers(response.data.subscribers);
                        setTotalSubscribers(response.data.totalSubscribers);
                        setPerPages(response.data.itemsCountPerPage);
                        setCurrentPage(response.data.currentPage);
                        Progress.hide();
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      }

      const handleDelete = async (event, subscriber) => {
            event.preventDefault();
            setLoading(true);
            await axios
                  .delete(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/subscribers/${subscriber.slug}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setSubscribers([]);
                              loadData();
                              toast.success(response.data.message);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      const handleStatus = async (subscriber) => {
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/subscribers/${subscriber.slug}/status`,
                        {},
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              toast.success(response.data.message);
                        }
                        if (response.data.result === 'error') {
                              toast.error(response.data.message);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };

      return (
            <>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader>
                                          <CardTitle>Subscriber List</CardTitle>
                                    </CardHeader>
                                    <Card.Body>
                                          <TableSearch
                                                setLimit={setLimit}
                                                setSearch={setSearch}
                                          />

                                          <Table hover bordered>
                                                <thead>
                                                      <tr>
                                                            <th
                                                                  className="text-center"
                                                                  width="80px"
                                                            >
                                                                  #
                                                            </th>
                                                            <th>
                                                                  Email Address
                                                            </th>
                                                            <th width="120px">
                                                                  Status
                                                            </th>
                                                            <th width="120px">
                                                                  Action
                                                            </th>
                                                      </tr>
                                                </thead>
                                                <tbody>
                                                      {!loading ? (
                                                            subscribers.length >
                                                            0 ? (
                                                                  subscribers.map(
                                                                        (
                                                                              subscriber,
                                                                              index
                                                                        ) => (
                                                                              <>
                                                                                    <tr
                                                                                          key={
                                                                                                index
                                                                                          }
                                                                                    >
                                                                                          <td className="text-center">
                                                                                                {
                                                                                                      subscriber.index
                                                                                                }
                                                                                          </td>
                                                                                          <td>
                                                                                                <ProductImage
                                                                                                      title={
                                                                                                            subscriber.email
                                                                                                      }
                                                                                                      category={
                                                                                                            subscriber.date
                                                                                                      }
                                                                                                />
                                                                                          </td>
                                                                                          <td>
                                                                                                <ItemStatus
                                                                                                      item={
                                                                                                            subscriber
                                                                                                      }
                                                                                                      handleStatus={
                                                                                                            handleStatus
                                                                                                      }
                                                                                                />
                                                                                          </td>
                                                                                          <td className="text-center">
                                                                                                <DeleteButton
                                                                                                      item={
                                                                                                            subscriber
                                                                                                      }
                                                                                                      handleDelete={
                                                                                                            handleDelete
                                                                                                      }
                                                                                                      loading={
                                                                                                            loading
                                                                                                      }
                                                                                                      setLoading={
                                                                                                            setLoading
                                                                                                      }
                                                                                                />
                                                                                          </td>
                                                                                    </tr>
                                                                              </>
                                                                        )
                                                                  )
                                                            ) : (
                                                                  <>
                                                                        <tr>
                                                                              <td
                                                                                    colSpan="5"
                                                                                    className="text-center"
                                                                              >
                                                                                    No
                                                                                    Subscriber
                                                                                    Found.
                                                                              </td>
                                                                        </tr>
                                                                  </>
                                                            )
                                                      ) : (
                                                            <>
                                                                  <tr>
                                                                        <td
                                                                              colSpan="5"
                                                                              className="text-center"
                                                                        >
                                                                              <Loading />
                                                                        </td>
                                                                  </tr>
                                                            </>
                                                      )}
                                                </tbody>
                                          </Table>

                                          <TableFooter
                                                perPages={perPages}
                                                totalItems={totalSubscribers}
                                                currentPage={currentPage}
                                                getData={getData}
                                          />
                                    </Card.Body>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default SubscriberList;
