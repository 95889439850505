import React from 'react';
import { Col, Form } from 'react-bootstrap';

const Input = ({
      classes,
      title,
      value,
      handleChange,
      required,
      type,
      onBlur,
      onClick,
      disabled
}) => {
      return (
            <>
                  <Col lg={classes ? classes : 6}>
                        <Form.Label>
                              {title}{' '}
                              {required && (
                                    <span className="text-danger">*</span>
                              )}
                        </Form.Label>
                        <Form.Control
                              defaultValue={value}
                              onChange={(event) =>
                                    handleChange(event.target.value)
                              }
                              type={type ? type : 'text'}
                              onClick={onClick}
                              onBlur={onBlur}
                              disabled={disabled}
                        />
                  </Col>
            </>
      );
};

export default Input;
