import React from 'react';

const ProfileTitle = ({ title }) => {
      return (
            <>
                  <h5 className="mb-4">{title}</h5>
            </>
      );
};

export default ProfileTitle;
