import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Card, Col, ListGroup, ListGroupItem, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import {
      CardHeader,
      CardLinks,
      CardTitle
} from '../../styles/components/common/Table';
import { LinkButton } from '../../styles/components/common/Button';
import ItemShow from '../../components/Form/ItemShow';
import { toast } from 'react-hot-toast';
import { Loading } from '../../components/Loading';

const BannerShow = () => {
      const { bannerId } = useParams();

      const [loading, setLoading] = useState(false);
      const [banner, setBanner] = useState([]);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/banners/${bannerId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setBanner(response.data.banner);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader>
                                          <CardTitle>Banner Details</CardTitle>
                                          <CardLinks>
                                                <LinkButton to="/dashboard/banners">
                                                      Back
                                                </LinkButton>
                                          </CardLinks>
                                    </CardHeader>
                                    <Card.Body>
                                          {!loading ? (
                                                <Row>
                                                      <Col lg={6}>
                                                            <ListGroup>
                                                                  <ItemShow
                                                                        title="Title"
                                                                        value={
                                                                              banner.title
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Description"
                                                                        value={
                                                                              banner.description
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Status"
                                                                        value={
                                                                              banner.status ===
                                                                              1 ? (
                                                                                    <>
                                                                                          <label className="text-success p-2">
                                                                                                Active
                                                                                          </label>
                                                                                    </>
                                                                              ) : (
                                                                                    <>
                                                                                          <label className="text-danger p-2">
                                                                                                Disable
                                                                                          </label>
                                                                                    </>
                                                                              )
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Created By"
                                                                        value={
                                                                              banner.user_name
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Date"
                                                                        value={
                                                                              banner.date
                                                                        }
                                                                  />
                                                            </ListGroup>
                                                      </Col>
                                                      <Col lg={6}>
                                                            <ListGroup>
                                                                  <ListGroupItem className="text-center">
                                                                        <img
                                                                              src={
                                                                                    banner.image
                                                                              }
                                                                              alt={
                                                                                    banner.title
                                                                              }
                                                                              className="border p-2 rounded"
                                                                              style={{
                                                                                    width: '150px',
                                                                                    height: '150px',
                                                                                    objectFit:
                                                                                          'contain'
                                                                              }}
                                                                        />
                                                                        <div className="mt-2">
                                                                              Banner
                                                                              Image
                                                                        </div>
                                                                  </ListGroupItem>
                                                            </ListGroup>
                                                      </Col>
                                                </Row>
                                          ) : (
                                                <>
                                                      <div
                                                            className="text-center d-flex align-items-center justify-content-center w-100"
                                                            style={{
                                                                  height: '30vh'
                                                            }}
                                                      >
                                                            <Loading />
                                                      </div>
                                                </>
                                          )}
                                    </Card.Body>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default BannerShow;
