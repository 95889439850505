import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';

import toast from 'react-hot-toast';
import Progress from 'react-progress-2';
import CardHeader from '../../components/commons/CardHeader';
import CardBody from '../../components/commons/CardBody';
import ViewButton from '../../components/commons/ViewButton';
import DeleteButton from '../../components/commons/DeleteButton';
import ProductImage from '../../components/Form/ProductImage';

const ContactFormList = () => {
      const [loading, setLoading] = useState(false);
      const [contacts, setContacts] = useState([]);
      const [totalItems, setTotalItems] = useState(0);
      const [perPages, setPerPages] = useState(0);
      const [currentPage, setCurrentPage] = useState(0);

      const token = JSON.parse(localStorage.getItem('token'));
      const [limit, setLimit] = useState(10);
      const [search, setSearch] = useState('');

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/contact_forms?limit=${limit}&search=${search}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        setContacts(response.data.contacts);
                        setTotalItems(response.data.totalItems);
                        setPerPages(response.data.itemsCountPerPage);
                        setCurrentPage(response.data.currentPage);
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };
      useEffect(() => {
            loadData();
      }, [limit, search]);

      async function getData(pageNumber = 1) {
            window.scrollTo(0, 0);
            setLoading(true);
            Progress.show();
            const url = `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/contact_forms?page=${pageNumber}&limit=${limit}&search=${search}`;
            await axios(url, {
                  headers: {
                        Authorization: `Bearer ${token}`
                  }
            })
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setContacts(response.data.contacts);
                              setTotalItems(response.data.totalItems);
                              setPerPages(response.data.itemsCountPerPage);
                              setCurrentPage(response.data.currentPage);
                        }
                        Progress.hide();
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      }

      const handleDelete = async (contact) => {
            const response = await axios
                  .delete(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/contact_forms/${contact.slug}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setContacts((contacts) =>
                                    contacts.filter(
                                          (item) => item.id !== contact.id
                                    )
                              );
                              loadData();
                              toast.success(response.data.message);
                              return true;
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            return response;
      };

      return (
            <>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader title="contact List" />
                                    <CardBody
                                          perPages={perPages}
                                          totalItems={totalItems}
                                          currentPage={currentPage}
                                          setSearch={setSearch}
                                          getData={getData}
                                          setLimit={setLimit}
                                    >
                                          <thead>
                                                <tr>
                                                      <th>#</th>
                                                      <th>Full Name</th>
                                                      <th>Phone</th>
                                                      <th>Date</th>
                                                      <th width="120px">
                                                            Action
                                                      </th>
                                                </tr>
                                          </thead>
                                          <tbody>
                                                {!loading ? (
                                                      contacts?.length > 0 ? (
                                                            contacts.map(
                                                                  (
                                                                        contact,
                                                                        index
                                                                  ) => (
                                                                        <>
                                                                              <tr
                                                                                    key={
                                                                                          index
                                                                                    }
                                                                              >
                                                                                    <td className="text-center">
                                                                                          {
                                                                                                ++index
                                                                                          }
                                                                                    </td>
                                                                                    <td>
                                                                                          <ProductImage
                                                                                                title={
                                                                                                      contact.name
                                                                                                }
                                                                                                category={`Email: ${contact.email}`}
                                                                                          />
                                                                                    </td>
                                                                                    <td>
                                                                                          {
                                                                                                contact.phone_number
                                                                                          }
                                                                                    </td>
                                                                                    <td>
                                                                                          {
                                                                                                contact.date
                                                                                          }
                                                                                    </td>
                                                                                    <td className="text-center">
                                                                                          <ViewButton
                                                                                                classes="pe-2"
                                                                                                link={`/dashboard/contact_requests/${contact.slug}/show`}
                                                                                          />
                                                                                          <DeleteButton
                                                                                                item={
                                                                                                      contact
                                                                                                }
                                                                                                handleDelete={
                                                                                                      handleDelete
                                                                                                }
                                                                                                loading={
                                                                                                      loading
                                                                                                }
                                                                                                setLoading={
                                                                                                      setLoading
                                                                                                }
                                                                                          />
                                                                                    </td>
                                                                              </tr>
                                                                        </>
                                                                  )
                                                            )
                                                      ) : (
                                                            <tr>
                                                                  <td
                                                                        colSpan={
                                                                              5
                                                                        }
                                                                        className="text-center"
                                                                  >
                                                                        No
                                                                        contact
                                                                        Found
                                                                  </td>
                                                            </tr>
                                                      )
                                                ) : (
                                                      <>
                                                            <tr>
                                                                  <td
                                                                        colSpan={
                                                                              5
                                                                        }
                                                                        className="text-center"
                                                                  >
                                                                        Loading...
                                                                  </td>
                                                            </tr>
                                                      </>
                                                )}
                                          </tbody>
                                    </CardBody>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default ContactFormList;
