import React from 'react';
import {
      SearchContainer,
      SearchEntity
} from '../../../styles/components/common/Search';
import { FormControl, FormSelect } from 'react-bootstrap';

const TableSearch = ({ setLimit, setSearch }) => {
      return (
            <>
                  <SearchContainer>
                        <SearchEntity>
                              <div>Show</div>
                              <div className="px-3">
                                    <FormSelect
                                          onChange={(event) =>
                                                setLimit(event.target.value)
                                          }
                                    >
                                          <option value={10}>10</option>
                                          <option value={25}>25</option>
                                          <option value={50}>50</option>
                                          <option value={100}>100</option>
                                    </FormSelect>
                              </div>
                              <div>Entities</div>
                        </SearchEntity>
                        <div>
                              <FormControl
                                    type="text"
                                    placeholder="Search"
                                    onChange={(event) =>
                                          setSearch(event.target.value)
                                    }
                              />
                        </div>
                  </SearchContainer>
            </>
      );
};

export default TableSearch;
