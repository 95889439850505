import { Accordion, Row } from 'react-bootstrap';
import Input from '../Form/Input';
import Textarea from '../Form/Textarea';
import FormImage from '../Form/image';

const FormSeo = ({
      setSeoTitle,
      title,
      setSeoDescription,
      description,
      setSeoImage,
      setSeoKeyword,
      keyword,
      setSeoImageUrl,
      seoImageUrl
}) => {
      return (
            <>
                  <Accordion className="mt-4">
                        <Accordion.Item eventKey="2">
                              <Accordion.Header>Manage Seo</Accordion.Header>
                              <Accordion.Body>
                                    <Row className="g-3">
                                          <Input
                                                value={title}
                                                title="Seo Title"
                                                handleChange={setSeoTitle}
                                          />
                                          <Input
                                                value={keyword}
                                                title="Seo Keyword"
                                                handleChange={setSeoKeyword}
                                          />
                                          <Textarea
                                                value={description}
                                                title="Seo Description"
                                                handleChange={setSeoDescription}
                                          />
                                          {seoImageUrl && (
                                                <FormImage
                                                      title="Seo Image"
                                                      setImage={setSeoImage}
                                                      setImageUrl={
                                                            setSeoImageUrl
                                                      }
                                                      imageUrl={seoImageUrl}
                                                />
                                          )}
                                    </Row>
                              </Accordion.Body>
                        </Accordion.Item>
                  </Accordion>
            </>
      );
};

export default FormSeo;
