import React from 'react';
import { NavLink } from 'react-router-dom';

const MenuItem = ({ link, title }) => {
      return (
            <>
                  <NavLink to={link} className="list-group-item text-start">
                        {title}
                  </NavLink>
            </>
      );
};

export default MenuItem;
