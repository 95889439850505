import React, { Suspense } from 'react';
import {
      Route,
      BrowserRouter as Router,
      Routes,
      useLocation
} from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import ScrollToTop from '../components/ScrollToTop';
import PublicRouter from '../routers';

import 'react-progress-2/main.css';
import Progress from 'react-progress-2';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-loading-skeleton/dist/skeleton.css';
import { ThemeProvider } from 'styled-components';
import { lightTheme } from '../styles/components/themes';

import { GlobalStyle } from '../styles/components/globalStyle';
import './App.css';
import Login from '../auth/login';
import PrivateLayout from '../layouts/backend/PrivateLayout';

const App = () => {
      return (
            <>
                  <Progress.Component
                        style={{ background: '#99999978', height: '5px' }}
                        thumbStyle={{
                              background: 'rgb(232, 107, 25)',
                              height: '5px'
                        }}
                  />
                  <ThemeProvider theme={lightTheme}>
                        <ScrollToTop />
                        <Toaster position="bottom-center" />
                        <Suspense fallback="Loading">
                              {useLocation().pathname === '/' ? (
                                    <Routes>
                                          <Route path="/" element={<Login />} />
                                    </Routes>
                              ) : (
                                    <PrivateLayout>
                                          <PublicRouter />
                                    </PrivateLayout>
                              )}
                        </Suspense>
                  </ThemeProvider>
            </>
      );
};

export default App;
