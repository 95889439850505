import axios from 'axios';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Card, Col, ListGroup, Row } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import {
      CardHeader,
      CardLinks,
      CardTitle
} from '../../styles/components/common/Table';
import { LinkButton } from '../../styles/components/common/Button';
import ItemShow from '../../components/Form/ItemShow';
import { Loading } from '../../components/Loading';

const PageShow = () => {
      const { pageId } = useParams();

      const [page, setPage] = useState([]);
      const [loading, setLoading] = useState(false);
      const token = JSON.parse(localStorage.getItem('token'));

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/pages/${pageId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setPage(response.data.page);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader>
                                          <CardTitle>Page Detail</CardTitle>
                                          <CardLinks>
                                                <LinkButton to="/dashboard/pages">
                                                      Back
                                                </LinkButton>
                                          </CardLinks>
                                    </CardHeader>
                                    <Card.Body>
                                          {!loading ? (
                                                <Row>
                                                      <Col lg={6}>
                                                            <ListGroup>
                                                                  <ItemShow
                                                                        title="Title"
                                                                        value={
                                                                              page.title
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="SEO Title"
                                                                        value={
                                                                              page.seo_title
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="SEO Keyword"
                                                                        value={
                                                                              page.seo_keyword
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="SEO Description"
                                                                        value={
                                                                              page.seo_description
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Created By"
                                                                        value={
                                                                              page.user_name
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Date"
                                                                        value={
                                                                              page.date
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Status"
                                                                        value={
                                                                              page.status ===
                                                                              1 ? (
                                                                                    <>
                                                                                          <label className="text-success p-2">
                                                                                                Active
                                                                                          </label>
                                                                                    </>
                                                                              ) : (
                                                                                    <>
                                                                                          <label className="text-danger p-2">
                                                                                                Disable
                                                                                          </label>
                                                                                    </>
                                                                              )
                                                                        }
                                                                  />
                                                            </ListGroup>
                                                      </Col>
                                                      <Col lg={6}>
                                                            <ListGroup>
                                                                  <ItemShow
                                                                        title="Description"
                                                                        value={
                                                                              <div
                                                                                    dangerouslySetInnerHTML={{
                                                                                          __html: page.description
                                                                                    }}
                                                                              ></div>
                                                                        }
                                                                  />
                                                            </ListGroup>
                                                      </Col>
                                                </Row>
                                          ) : (
                                                <>
                                                      <div
                                                            className="text-center d-flex align-items-center justify-content-center w-100"
                                                            style={{
                                                                  height: '30vh'
                                                            }}
                                                      >
                                                            <Loading />
                                                      </div>
                                                </>
                                          )}
                                    </Card.Body>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default PageShow;
