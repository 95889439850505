import React from 'react';
import { Link } from 'react-router-dom';
import { ShowIcon } from '../../icons';

const ViewButton = ({ link, classes }) => {
      return (
            <>
                  <Link to={link} className={`text-success mx-2 ${classes}`}>
                        <ShowIcon />
                  </Link>
            </>
      );
};

export default ViewButton;
