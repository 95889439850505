import React from 'react';
import { Accordion } from 'react-bootstrap';
import {
      CategoryFilterBody,
      CategoryFilterContainer,
      CategoryFilterHeader
} from '../../../styles/backend/product';
import CategoryCheck from './CategoryCheck';

const CategoryFilter = ({ loading, categories, handleChangeCategory }) => {
      return (
            <>
                  <CategoryFilterContainer flush>
                        {!loading ? (
                              categories.length > 0 ? (
                                    categories.map((category, index) => (
                                          <Accordion.Item
                                                eventKey={category.id}
                                                key={index}
                                          >
                                                <CategoryFilterHeader>
                                                      {category.title}
                                                </CategoryFilterHeader>
                                                <CategoryFilterBody>
                                                      <ul>
                                                            {category
                                                                  .subCategory
                                                                  .length >
                                                            0 ? (
                                                                  category.subCategory.map(
                                                                        (
                                                                              subCategory,
                                                                              key
                                                                        ) =>
                                                                              subCategory
                                                                                    .childCategory
                                                                                    .length >
                                                                              0 ? (
                                                                                    <CategoryFilterContainer
                                                                                          flush
                                                                                    >
                                                                                          <Accordion.Item
                                                                                                eventKey={
                                                                                                      subCategory.id
                                                                                                }
                                                                                          >
                                                                                                <CategoryFilterHeader>
                                                                                                      {
                                                                                                            subCategory.title
                                                                                                      }
                                                                                                </CategoryFilterHeader>
                                                                                                <CategoryFilterBody>
                                                                                                      <ul>
                                                                                                            {subCategory.childCategory.map(
                                                                                                                  (
                                                                                                                        childCategory,
                                                                                                                        indexKey
                                                                                                                  ) => (
                                                                                                                        <li
                                                                                                                              key={
                                                                                                                                    indexKey
                                                                                                                              }
                                                                                                                        >
                                                                                                                              <CategoryCheck
                                                                                                                                    category={
                                                                                                                                          childCategory
                                                                                                                                    }
                                                                                                                                    handleChangeCategory={
                                                                                                                                          handleChangeCategory
                                                                                                                                    }
                                                                                                                                    type="childCategory"
                                                                                                                              />
                                                                                                                        </li>
                                                                                                                  )
                                                                                                            )}
                                                                                                      </ul>
                                                                                                </CategoryFilterBody>
                                                                                          </Accordion.Item>
                                                                                    </CategoryFilterContainer>
                                                                              ) : (
                                                                                    <li
                                                                                          key={
                                                                                                key
                                                                                          }
                                                                                    >
                                                                                          <CategoryCheck
                                                                                                category={
                                                                                                      subCategory
                                                                                                }
                                                                                                type="subCategory"
                                                                                                handleChangeCategory={
                                                                                                      handleChangeCategory
                                                                                                }
                                                                                          />
                                                                                    </li>
                                                                              )
                                                                  )
                                                            ) : (
                                                                  <li
                                                                        key={
                                                                              index
                                                                        }
                                                                  >
                                                                        <CategoryCheck
                                                                              category={
                                                                                    category
                                                                              }
                                                                              type="category"
                                                                              handleChangeCategory={
                                                                                    handleChangeCategory
                                                                              }
                                                                        />
                                                                  </li>
                                                            )}
                                                      </ul>
                                                </CategoryFilterBody>
                                          </Accordion.Item>
                                    ))
                              ) : (
                                    <>No categories found</>
                              )
                        ) : (
                              <>Loading</>
                        )}
                  </CategoryFilterContainer>
            </>
      );
};

export default CategoryFilter;
