import React from 'react';
import { Card, Col, ListGroup, Row } from 'react-bootstrap';
import {
      CardHeader,
      CardLinks,
      CardTitle
} from '../../styles/components/common/Table';
import ItemShow from '../../components/Form/ItemShow';
import { useState } from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import axios from 'axios';
import { LinkButton } from '../../styles/components/common/Button';
import { Loading } from '../../components/Loading';

const ComplainTypeShow = () => {
      const { complainTypeId } = useParams();

      const [type, setType] = useState([]);
      const [loading, setLoading] = useState(false);

      const token = JSON.parse(localStorage.getItem('token'));

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/complain_types/${complainTypeId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setType(response.data.type);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <Row>
                        <Col>
                              <Card>
                                    <CardHeader>
                                          <CardTitle>
                                                Complain Type Detail
                                          </CardTitle>
                                          <CardLinks>
                                                <LinkButton to="/dashboard/complains/types">
                                                      Back
                                                </LinkButton>
                                          </CardLinks>
                                    </CardHeader>
                                    <Card.Body>
                                          {!loading ? (
                                                <Row>
                                                      <Col lg={6}>
                                                            <ListGroup>
                                                                  <ItemShow
                                                                        title="Title"
                                                                        value={
                                                                              type.title
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Status"
                                                                        value={
                                                                              type.status ===
                                                                              1 ? (
                                                                                    <label className="text-success">
                                                                                          Active
                                                                                    </label>
                                                                              ) : (
                                                                                    <label className="text-danger">
                                                                                          Disabled
                                                                                    </label>
                                                                              )
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Date"
                                                                        value={
                                                                              type.date
                                                                        }
                                                                  />
                                                            </ListGroup>
                                                      </Col>
                                                </Row>
                                          ) : (
                                                <>
                                                      <div
                                                            className="text-center d-flex align-items-center justify-content-center w-100"
                                                            style={{
                                                                  height: '30vh'
                                                            }}
                                                      >
                                                            <Loading />
                                                      </div>
                                                </>
                                          )}
                                    </Card.Body>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default ComplainTypeShow;
