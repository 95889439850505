import axios from 'axios';
import React from 'react';
import { useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import {
      CardHeader,
      CardLinks,
      CardTitle
} from '../../styles/components/common/Table';
import { LinkButton } from '../../styles/components/common/Button';
import Input from '../../components/Form/Input';
import Select from '../../components/Form/Select';
import FormButton from '../../components/Form/Button';
import $ from 'jquery';
import { toast } from 'react-hot-toast';

const SubscriberCreate = () => {
      const [email, setEmail] = useState('');
      const [status, setStatus] = useState('');
      const [loading, setLoading] = useState(false);

      const token = JSON.parse(localStorage.getItem('token'));

      const handleForm = async (event) => {
            event.preventDefault();
            setLoading(true);
            const data = {
                  email: email,
                  status: status
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/subscribers`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                                    'Content-Type': 'multipart/form-data'
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              $('form').trigger('reset');
                              toast.success(response.data.message);
                        }
                        if (response.data.result === 'error') {
                              if (response.data.message.email) {
                                    toast.error(response.data.message.email);
                              }
                              if (response.data.message.status) {
                                    toast.error(response.data.message.status);
                              }
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      return (
            <>
                  <Form onSubmit={handleForm}>
                        <Row>
                              <Col lg={12}>
                                    <Card>
                                          <CardHeader>
                                                <CardTitle>
                                                      Create New Subscriber
                                                </CardTitle>
                                                <CardLinks>
                                                      <LinkButton to="/dashboard/subscribers">
                                                            Back
                                                      </LinkButton>
                                                </CardLinks>
                                          </CardHeader>
                                          <Card.Body>
                                                <Row className="g-3">
                                                      <Input
                                                            title="Email Address"
                                                            classes={8}
                                                            required={true}
                                                            type="email"
                                                            handleChange={
                                                                  setEmail
                                                            }
                                                      />
                                                      <Select
                                                            title="Status"
                                                            classes={4}
                                                            setStatus={
                                                                  setStatus
                                                            }
                                                      />
                                                      <Col lg={12}>
                                                            <FormButton
                                                                  title="Submit"
                                                                  loading={
                                                                        loading
                                                                  }
                                                            />
                                                      </Col>
                                                </Row>
                                          </Card.Body>
                                    </Card>
                              </Col>
                        </Row>
                  </Form>
            </>
      );
};

export default SubscriberCreate;
