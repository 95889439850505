import React, { useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import {
      CardHeader,
      CardLinks,
      CardTitle
} from '../../styles/components/common/Table';
import { LinkButton } from '../../styles/components/common/Button';
import MultipleImage from '../../components/Form/image/MultipleImage';
import FormButton from '../../components/Form/Button';
import axios from 'axios';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';

const ProductImportUpdate = () => {
      const [files, setFiles] = useState([]);
      const [images, setImages] = useState([]);
      const [loading, setLoading] = useState(false);
      const token = JSON.parse(localStorage.getItem('token'));

      const handleForm = async (event) => {
            event.preventDefault();

            setLoading(true);
            const data = {
                  files: files
            };
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/products/imports/update`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                                    'Content-Type': 'multipart/form-data'
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              toast.success(response.data.message);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      return (
            <>
                  <Row className="g-4">
                        <Col lg={12}>
                              <Card>
                                    <CardHeader>
                                          <CardTitle>
                                                Update Product Import
                                          </CardTitle>
                                          <CardLinks>
                                                <LinkButton to="/dashboard/products">
                                                      Back
                                                </LinkButton>
                                          </CardLinks>
                                    </CardHeader>
                                    <Card.Body>
                                          <Form onSubmit={handleForm}>
                                                <Row>
                                                      <MultipleImage
                                                            title="Upload Multiple Excel File"
                                                            imageType="xslx"
                                                            setImages={
                                                                  setImages
                                                            }
                                                            images={files}
                                                            classes={12}
                                                            setImageFiles={
                                                                  setFiles
                                                            }
                                                      />
                                                      <Col lg={12}>
                                                            <FormButton
                                                                  title="Submit"
                                                                  loading={
                                                                        loading
                                                                  }
                                                            />
                                                      </Col>
                                                </Row>
                                          </Form>
                                    </Card.Body>
                              </Card>
                        </Col>
                        <Col lg={12}>
                              <Card>
                                    <Card.Body>
                                          <h5>
                                                Terms & Condition for updating
                                                product
                                          </h5>
                                          <ul>
                                                <li>
                                                      Download the brand wise
                                                      existing product on Excel
                                                      file{' '}
                                                      <Link
                                                            href="/dashboard/brands/products/exports"
                                                            download
                                                      >
                                                            click here.
                                                      </Link>
                                                </li>
                                                <li>
                                                      Don't change product ID.
                                                </li>
                                                <li>
                                                      If we change the product
                                                      name then we have to
                                                      change the slug
                                                      accordingly.
                                                </li>
                                                <li>
                                                      Product slug, Product code
                                                      should be unique and can
                                                      be changeable.
                                                </li>
                                                <li>
                                                      Brand name, Category name
                                                      should be same as list.
                                                </li>
                                                <li>
                                                      Size should be in the
                                                      previous format (first
                                                      size x second size (if) x
                                                      third size (if))
                                                </li>
                                                <li>
                                                      The size unit and product
                                                      unit should be the same as
                                                      the website
                                                </li>
                                                <li>
                                                      Mrp price, Dealer price,
                                                      Offer Price, Discount
                                                      Price, In Stock should be
                                                      in number, if don't have
                                                      value then leave default
                                                      value as 0.
                                                </li>
                                                <li>
                                                      Use color code for color
                                                </li>
                                                <li>
                                                      Image should be in lower
                                                      case. Image name should be
                                                      similar in image column.
                                                </li>
                                                <li>
                                                      Use '-' instead of space
                                                      and don't forget the
                                                      extension of image
                                                </li>
                                                <li>
                                                      After importing the excel
                                                      file. You are ready to
                                                      import the image file in
                                                      website{' '}
                                                      <Link to="/dashboard/images/create">
                                                            click here
                                                      </Link>
                                                </li>
                                          </ul>
                                    </Card.Body>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default ProductImportUpdate;
