import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Table } from 'react-bootstrap';

import { toast } from 'react-hot-toast';
import Progress from 'react-progress-2';
import {
      CardHeader,
      CardLinks,
      CardTitle
} from '../../styles/components/common/Table';
import { LinkButton } from '../../styles/components/common/Button';
import TableSearch from '../../components/Form/Search';
import { EditIcon, ShowIcon } from '../../components/icons';
import ItemStatus from '../../components/Form/Status';
import { Link } from 'react-router-dom';
import ProductImage from '../../components/Form/ProductImage';
import TableFooter from '../../components/Form/TableFooter';
import { Loading } from '../../components/Loading';
import DeleteButton from '../../components/commons/DeleteButton';

const BrandList = () => {
      const [loading, setLoading] = useState(false);
      const [brands, setBrands] = useState([]);
      const [totalBrands, setTotalBrands] = useState(0);
      const [perPages, setPerPages] = useState(0);
      const [currentPage, setCurrentPage] = useState(0);

      const token = JSON.parse(localStorage.getItem('token'));
      const [limit, setLimit] = useState(10);
      const [search, setSearch] = useState('');

      const fetchBrands = async () => {
            setLoading(true);

            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/brands?limit=${limit}&search=${search}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`
                              }
                        }
                  )
                  .then((response) => {
                        setBrands(response.data.brands);
                        setTotalBrands(response.data.totalBrands);
                        setPerPages(response.data.itemsCountPerPage);
                        setCurrentPage(response.data.currentPage);
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            fetchBrands();
      }, [limit, search]);

      const handleStatus = async (brand) => {
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/brands/${brand.slug}/status`,
                        {},
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              toast.success(response.data.message);
                        }
                        if (response.data.result === 'error') {
                              toast.error(response.data.message);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };

      async function getBrandData(pageNumber = 1) {
            window.scrollTo(0, 0);
            setLoading(true);
            Progress.show();
            const url = `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/brands?page=${pageNumber}&limit=${limit}&search=${search}`;
            await axios(url, {
                  headers: {
                        Authorization: `Bearer ${token}`
                  }
            })
                  .then((response) => {
                        setBrands(response.data.brands);
                        setTotalBrands(response.data.totalBrands);
                        setPerPages(response.data.itemsCountPerPage);
                        setCurrentPage(response.data.currentPage);
                        Progress.hide();
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      }

      const handleDelete = async (brand) => {
            setLoading(true);
            await axios
                  .delete(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/brands/${brand.slug}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setBrands([]);
                              fetchBrands();
                              toast.success(response.data.message);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      return (
            <>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader>
                                          <CardTitle>Brand List</CardTitle>
                                          <CardLinks>
                                                <LinkButton to="/dashboard/brands/create">
                                                      Add New
                                                </LinkButton>
                                                <LinkButton to="/dashboard/brands/products/exports">
                                                      Export Product
                                                </LinkButton>
                                          </CardLinks>
                                    </CardHeader>

                                    <Card.Body>
                                          <TableSearch
                                                setLimit={setLimit}
                                                setSearch={setSearch}
                                          />
                                          <Table hover bordered>
                                                <thead>
                                                      <tr>
                                                            <th>#</th>
                                                            <th>Brand Name</th>
                                                            <th>Products</th>
                                                            <th>Status</th>
                                                            <th width="120px">
                                                                  Action
                                                            </th>
                                                      </tr>
                                                </thead>
                                                <tbody>
                                                      {!loading ? (
                                                            brands.length >
                                                            0 ? (
                                                                  brands.map(
                                                                        (
                                                                              brand,
                                                                              index
                                                                        ) => (
                                                                              <>
                                                                                    <tr
                                                                                          key={
                                                                                                index
                                                                                          }
                                                                                    >
                                                                                          <td>
                                                                                                {
                                                                                                      brand.index
                                                                                                }
                                                                                          </td>
                                                                                          <td>
                                                                                                <ProductImage
                                                                                                      title={
                                                                                                            brand.title
                                                                                                      }
                                                                                                      image={
                                                                                                            brand.image
                                                                                                      }
                                                                                                />
                                                                                          </td>
                                                                                          <td>
                                                                                                {brand.products
                                                                                                      ? brand.products
                                                                                                      : 0}
                                                                                          </td>
                                                                                          <td>
                                                                                                <ItemStatus
                                                                                                      item={
                                                                                                            brand
                                                                                                      }
                                                                                                      handleStatus={
                                                                                                            handleStatus
                                                                                                      }
                                                                                                />
                                                                                          </td>
                                                                                          <td className="text-center">
                                                                                                <Link
                                                                                                      to={`/dashboard/brands/${brand.slug}/edit`}
                                                                                                      className="text-secondary"
                                                                                                >
                                                                                                      <EditIcon />
                                                                                                </Link>
                                                                                                <Link
                                                                                                      to={`/dashboard/brands/${brand.slug}/show`}
                                                                                                      className="text-success mx-2"
                                                                                                >
                                                                                                      <ShowIcon />
                                                                                                </Link>
                                                                                                <DeleteButton
                                                                                                      item={
                                                                                                            brand
                                                                                                      }
                                                                                                      handleDelete={
                                                                                                            handleDelete
                                                                                                      }
                                                                                                      loading={
                                                                                                            loading
                                                                                                      }
                                                                                                      setLoading={
                                                                                                            setLoading
                                                                                                      }
                                                                                                />
                                                                                          </td>
                                                                                    </tr>
                                                                              </>
                                                                        )
                                                                  )
                                                            ) : (
                                                                  <>
                                                                        <tr>
                                                                              <td
                                                                                    colSpan={
                                                                                          5
                                                                                    }
                                                                                    className="text-center"
                                                                              >
                                                                                    No
                                                                                    Brand
                                                                                    Found
                                                                              </td>
                                                                        </tr>
                                                                  </>
                                                            )
                                                      ) : (
                                                            <>
                                                                  <tr>
                                                                        <td
                                                                              colSpan={
                                                                                    5
                                                                              }
                                                                              className="text-center"
                                                                        >
                                                                              <Loading />
                                                                        </td>
                                                                  </tr>
                                                            </>
                                                      )}
                                                </tbody>
                                          </Table>

                                          <TableFooter
                                                perPages={perPages}
                                                totalItems={totalBrands}
                                                currentPage={currentPage}
                                                getData={getBrandData}
                                          />
                                    </Card.Body>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default BrandList;
