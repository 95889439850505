import React, { useEffect, useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import {
      CardHeader,
      CardLinks,
      CardTitle
} from '../../styles/components/common/Table';
import { LinkButton } from '../../styles/components/common/Button';

import Select from 'react-select';
import FormButton from '../../components/Form/Button';
import Input from '../../components/Form/Input';
import { useParams } from 'react-router-dom';

import axios from 'axios';
import { toast } from 'react-hot-toast';
import Description from '../../components/Form/Description';
import { Loading } from '../../components/Loading';
import FormSelect from '../../components/Form/FormSelect';
import { InputSelect } from '../../components/Form/Select';
import FormImage from '../../components/Form/image';
import MultipleImage from '../../components/Form/image/MultipleImage';
import FormSeo from '../../components/commons/FormSeo';

const ProductEdit = () => {
      const { productId } = useParams();
      const [loadingData, setLoadingData] = useState(false);

      const [colors, setColors] = useState([]);
      const [brands, setBrands] = useState([]);
      const [units, setUnits] = useState([]);
      const [sizes, setSizes] = useState([]);

      const [loading, setLoading] = useState(false);
      const [image, setImage] = useState('');
      const [imageUrl, setImageUrl] = useState('');
      const [category, setCategory] = useState('');
      const [brand, setBrand] = useState('');
      const [title, setTitle] = useState('');
      const [slug, setSlug] = useState('');
      const [firstSize, setFirstSize] = useState('');
      const [secondSize, setSecondSize] = useState('');
      const [thirdSize, setThirdSize] = useState('');
      const [sizeUnit, setSizeUnit] = useState('');
      const [stock, setStock] = useState('');
      const [productCode, setProductCode] = useState('');
      const [unit, setUnit] = useState('');
      const [color, setColor] = useState([]);
      const [mrpPrice, setMrpPrice] = useState('');
      const [offerPrice, setOfferPrice] = useState('');
      const [dealerPrice, setDealerPrice] = useState('');
      const [discountPrice, setDiscountPrice] = useState('');
      const [description, setDescription] = useState('');
      const [seoTitle, setSeoTitle] = useState('');
      const [seoImage, setSeoImage] = useState('');
      const [seoImageUrl, setSeoImageUrl] = useState('');
      const [seoKeyword, setSeoKeyword] = useState('');
      const [seoDescription, setSeoDescription] = useState('');
      const [images, setImages] = useState([]);
      const [imageFiles, setImageFiles] = useState([]);
      const [productImages, setProductImages] = useState([]);
      const [status, setStatus] = useState('');

      const [product, setProduct] = useState([]);

      const [item, setItem] = useState('');
      const [subCategory, setSubCategory] = useState('');
      const [childCategory, setChildCategory] = useState('');

      const [categories, setCategories] = useState([]);
      const [subCategories, setSubCategories] = useState([]);
      const [childCategories, setChildCategories] = useState([]);

      const token = JSON.parse(localStorage.getItem('token'));

      const loadData = async () => {
            setLoadingData(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/products/categories/list`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setCategories(response.data.categories);
                              setBrands(response.data.brands);
                              setColors(response.data.colors);
                              setUnits(response.data.units);
                              setSizes(response.data.sizes);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });

            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/products/${productId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setProduct(response.data.product);
                              setTitle(response.data.product.title);
                              setSlug(response.data.product.slug);
                              setCategory(response.data.product.category_id);
                              setBrand(response.data.product.brand_id);
                              setProductCode(
                                    response.data.product.product_code
                              );
                              setFirstSize(response.data.product.first_size);
                              setSecondSize(response.data.product.second_size);
                              setThirdSize(response.data.product.third_size);
                              setSizeUnit(response.data.product.size_unit);
                              setStock(response.data.product.stock);
                              setUnit(response.data.product.unit);
                              setMrpPrice(response.data.product.mrp_price);
                              setOfferPrice(response.data.product.offer_price);
                              setDealerPrice(
                                    response.data.product.dealer_price
                              );
                              setDiscountPrice(
                                    response.data.product.discount_price
                              );
                              setProductImages(response.data.product.images);
                              setImageUrl(response.data.product.image);
                              setSeoTitle(response.data.product.seo_title);
                              setSeoImageUrl(response.data.product.seo_image);
                              setSeoKeyword(response.data.product.seo_keyword);
                              setDescription(response.data.product.description);
                              setSeoDescription(
                                    response.data.product.seo_description
                              );
                              setStatus(response.data.product.status);

                              setItem(response.data.category);
                              setChildCategories(response.data.childCategories);
                              setChildCategory(response.data.childCategory);
                              setSubCategories(response.data.subCategories);
                              setSubCategory(response.data.subCategory);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoadingData(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      const onChange = (newValue) => {
            setColor(newValue);
      };

      const handleSubmit = async (event) => {
            event.preventDefault();
            setLoading(true);

            const data = {
                  title: title,
                  slug: slug,
                  product_code: productCode,
                  brand: brand,
                  color: color,
                  first_size: firstSize,
                  second_size: secondSize,
                  third_size: thirdSize,
                  size: sizeUnit,
                  category: category,
                  status: status,
                  unit: unit,
                  stock: stock,
                  mrp_price: mrpPrice,
                  offer_price: offerPrice,
                  discount_price: discountPrice,
                  dealer_price: dealerPrice,
                  description: description,
                  image: image,
                  images: imageFiles,
                  seo_title: seoTitle,
                  seo_description: seoDescription,
                  seo_keyword: seoKeyword,
                  seo_image: seoImage
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/products/${productId}`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                                    'Content-Type': 'multipart/form-data'
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              toast.success(response.data.message);
                        }
                        if (response.data.result === 'error') {
                              if (response.data.message.title) {
                                    toast.error(response.data.message.title);
                              }
                              if (response.data.message.slug) {
                                    toast.error(response.data.message.slug);
                              }
                              if (response.data.message.product_code) {
                                    toast.error(
                                          response.data.message.product_code
                                    );
                              }
                              if (response.data.message.brand) {
                                    toast.error(response.data.message.brand);
                              }
                              if (response.data.message.color) {
                                    toast.error(response.data.message.color);
                              }
                              if (response.data.message.first_size) {
                                    toast.error(
                                          response.data.message.first_size
                                    );
                              }
                              if (response.data.message.second_size) {
                                    toast.error(
                                          response.data.message.second_size
                                    );
                              }
                              if (response.data.message.third_size) {
                                    toast.error(
                                          response.data.message.third_size
                                    );
                              }
                              if (response.data.message.size) {
                                    toast.error(response.data.message.size);
                              }
                              if (response.data.message.category) {
                                    toast.error(response.data.message.category);
                              }
                              if (response.data.message.status) {
                                    toast.error(response.data.message.status);
                              }
                              if (response.data.message.unit) {
                                    toast.error(response.data.message.unit);
                              }
                              if (response.data.message.stock) {
                                    toast.error(response.data.message.stock);
                              }
                              if (response.data.message.mrp_price) {
                                    toast.error(
                                          response.data.message.mrp_price
                                    );
                              }
                              if (response.data.message.offer_price) {
                                    toast.error(
                                          response.data.message.offer_price
                                    );
                              }
                              if (response.data.message.discount_price) {
                                    toast.error(
                                          response.data.message.discount_price
                                    );
                              }
                              if (response.data.message.dealer_price) {
                                    toast.error(
                                          response.data.message.dealer_price
                                    );
                              }
                              if (response.data.message.description) {
                                    toast.error(
                                          response.data.message.description
                                    );
                              }
                              if (response.data.message.image) {
                                    toast.error(response.data.message.image);
                              }
                              if (response.data.message.seo_title) {
                                    toast.error(
                                          response.data.message.seo_title
                                    );
                              }
                              if (response.data.message.seo_keyword) {
                                    toast.error(
                                          response.data.message.seo_keyword
                                    );
                              }
                              if (response.data.message.seo_description) {
                                    toast.error(
                                          response.data.message.seo_description
                                    );
                              }
                              if (response.data.message.seo_image) {
                                    toast.error(
                                          response.data.message.seo_image
                                    );
                              }
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      const loadCategory = async (value) => {
            setCategory(value);
            const data = {
                  id: value
            };
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/products/categories/list`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setSubCategories(response.data.categories);
                              setChildCategories([]);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };

      const loadSubCategory = async (value) => {
            setCategory(value);
            const data = {
                  id: value
            };
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/products/categories/list`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setChildCategories(response.data.categories);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };

      return (
            <>
                  <Form onSubmit={handleSubmit}>
                        <Row>
                              <Col lg={12}>
                                    <Card>
                                          <CardHeader>
                                                <CardTitle>
                                                      Edit Product
                                                </CardTitle>
                                                <CardLinks>
                                                      <LinkButton to="/dashboard/products">
                                                            Back
                                                      </LinkButton>
                                                </CardLinks>
                                          </CardHeader>

                                          <Card.Body>
                                                <Row className="g-3">
                                                      <Input
                                                            title="Product Title"
                                                            classes={7}
                                                            value={title}
                                                            required={true}
                                                            handleChange={
                                                                  setTitle
                                                            }
                                                      />
                                                      <Input
                                                            title="Slug"
                                                            classes={5}
                                                            value={slug}
                                                            required={true}
                                                            handleChange={
                                                                  setSlug
                                                            }
                                                      />
                                                      <Input
                                                            title="Product Code"
                                                            classes={4}
                                                            value={productCode}
                                                            required={true}
                                                            handleChange={
                                                                  setProductCode
                                                            }
                                                      />
                                                      <FormSelect
                                                            title="Brand"
                                                            classes={4}
                                                            required={true}
                                                            loading={
                                                                  loadingData
                                                            }
                                                            selected={brand}
                                                            options={brands}
                                                            setData={setBrand}
                                                      />
                                                      <Col lg={4}>
                                                            <Form.Label>
                                                                  Color{' '}
                                                                  <span className="text-danger">
                                                                        *
                                                                  </span>
                                                            </Form.Label>
                                                            {!loadingData ? (
                                                                  <Select
                                                                        isMulti
                                                                        defaultValue={
                                                                              product.color_items &&
                                                                              product.color_items.map(
                                                                                    (
                                                                                          index
                                                                                    ) =>
                                                                                          colors[
                                                                                                index
                                                                                          ]
                                                                              )
                                                                        }
                                                                        onChange={
                                                                              onChange
                                                                        }
                                                                        options={
                                                                              colors
                                                                        }
                                                                        classNamePrefix="select"
                                                                  />
                                                            ) : (
                                                                  <Loading />
                                                            )}
                                                      </Col>
                                                      <Input
                                                            title="First Size"
                                                            value={firstSize}
                                                            classes={4}
                                                            handleChange={
                                                                  setFirstSize
                                                            }
                                                      />
                                                      <Input
                                                            title="Second Size"
                                                            value={secondSize}
                                                            classes={4}
                                                            handleChange={
                                                                  setSecondSize
                                                            }
                                                      />
                                                      <Input
                                                            title="Third Size"
                                                            value={thirdSize}
                                                            classes={4}
                                                            handleChange={
                                                                  setThirdSize
                                                            }
                                                      />
                                                      <FormSelect
                                                            title="Size Unit"
                                                            classes={4}
                                                            selected={sizeUnit}
                                                            loading={
                                                                  loadingData
                                                            }
                                                            setData={
                                                                  setSizeUnit
                                                            }
                                                            required={true}
                                                            options={sizes}
                                                      />
                                                      <FormSelect
                                                            title="Category"
                                                            classes={4}
                                                            selected={item}
                                                            setData={
                                                                  loadCategory
                                                            }
                                                            loading={loading}
                                                            required={true}
                                                            options={categories}
                                                      />
                                                      {subCategories.length >
                                                            0 && (
                                                            <FormSelect
                                                                  title="Sub Category"
                                                                  classes={4}
                                                                  selected={
                                                                        subCategory
                                                                  }
                                                                  loading={
                                                                        loading
                                                                  }
                                                                  required={
                                                                        true
                                                                  }
                                                                  setData={
                                                                        loadSubCategory
                                                                  }
                                                                  options={
                                                                        subCategories
                                                                  }
                                                            />
                                                      )}
                                                      {childCategories.length >
                                                            0 && (
                                                            <FormSelect
                                                                  title="Child Category"
                                                                  classes={4}
                                                                  loading={
                                                                        loading
                                                                  }
                                                                  selected={
                                                                        childCategory
                                                                  }
                                                                  required={
                                                                        true
                                                                  }
                                                                  setData={
                                                                        setCategory
                                                                  }
                                                                  options={
                                                                        childCategories
                                                                  }
                                                            />
                                                      )}
                                                      <InputSelect
                                                            title="Status"
                                                            selected={status}
                                                            classes={4}
                                                            setStatus={
                                                                  setStatus
                                                            }
                                                      />
                                                </Row>
                                          </Card.Body>
                                    </Card>
                                    <Card className="mt-4">
                                          <Card.Body>
                                                <Row className="g-3">
                                                      <FormSelect
                                                            title="Product Unit"
                                                            classes={4}
                                                            loading={
                                                                  loadingData
                                                            }
                                                            selected={unit}
                                                            setData={setUnit}
                                                            required={true}
                                                            options={units}
                                                      />
                                                      <Input
                                                            title="Stock"
                                                            classes={4}
                                                            required={true}
                                                            value={stock}
                                                            handleChange={
                                                                  setStock
                                                            }
                                                      />
                                                      <Input
                                                            title="Mrp Price"
                                                            classes={4}
                                                            required={true}
                                                            value={mrpPrice}
                                                            handleChange={
                                                                  setMrpPrice
                                                            }
                                                      />
                                                      <Input
                                                            title="Offer Price"
                                                            classes={4}
                                                            value={offerPrice}
                                                            handleChange={
                                                                  setOfferPrice
                                                            }
                                                      />
                                                      <Input
                                                            title="Dealer Price"
                                                            classes={4}
                                                            value={dealerPrice}
                                                            handleChange={
                                                                  setDealerPrice
                                                            }
                                                      />
                                                      <Input
                                                            title="Discount Price"
                                                            classes={4}
                                                            value={
                                                                  discountPrice
                                                            }
                                                            handleChange={
                                                                  setDiscountPrice
                                                            }
                                                      />
                                                </Row>
                                          </Card.Body>
                                    </Card>
                                    <Card className="mt-4">
                                          <Card.Body>
                                                <Description
                                                      title="Description"
                                                      required={true}
                                                      description={description}
                                                      setDescription={
                                                            setDescription
                                                      }
                                                />
                                          </Card.Body>
                                    </Card>
                                    <Card className="mt-4">
                                          <Card.Body>
                                                <Row className="g-3">
                                                      <FormImage
                                                            title="Featured Image"
                                                            setImage={setImage}
                                                            setImageUrl={
                                                                  setImageUrl
                                                            }
                                                            required={true}
                                                            imageUrl={imageUrl}
                                                      />
                                                      <MultipleImage
                                                            title="Other Images (Upload multiple images)"
                                                            images={images}
                                                            websiteImages={
                                                                  productImages
                                                            }
                                                            setProductImages={
                                                                  setProductImages
                                                            }
                                                            grid={4}
                                                            classes={8}
                                                            setImages={
                                                                  setImages
                                                            }
                                                            setImageFiles={
                                                                  setImageFiles
                                                            }
                                                      />
                                                </Row>
                                          </Card.Body>
                                    </Card>
                                    <FormSeo
                                          seoImageUrl={seoImageUrl}
                                          setSeoImage={setSeoImage}
                                          setSeoImageUrl={setSeoImageUrl}
                                          setSeoDescription={setSeoDescription}
                                          setSeoTitle={setSeoTitle}
                                          setSeoKeyword={setSeoKeyword}
                                          description={seoDescription}
                                          keyword={seoKeyword}
                                          title={seoTitle}
                                    />
                                    <Card className="mt-4 mb-5">
                                          <Card.Body>
                                                <Row>
                                                      <Col lg={12}>
                                                            <FormButton
                                                                  title="Submit"
                                                                  loading={
                                                                        loading
                                                                  }
                                                            />
                                                      </Col>
                                                </Row>
                                          </Card.Body>
                                    </Card>
                              </Col>
                        </Row>
                  </Form>
            </>
      );
};

export default ProductEdit;
