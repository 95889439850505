import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap');

  :root {
    --border_color: #e6e6e6;
    --link_color: #333333;
    --secondary_color: #25252a;
    --icon_color: #e86b19;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    /* max-width: 1200px; */
}


a {
    text-decoration: none !important;
    color: #68798b;
}

.table thead tr th {
    background-color: #f2f2f2;
}

.table tbody tr {
    vertical-align: middle;
}

.form-control:focus,
.form-select:focus {
    box-shadow: none;
}
`;
