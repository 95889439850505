import { styled } from 'styled-components';

export const FormGroupBody = styled.div`
      padding: 0.25rem 0;
      width: 100%;
      border-radius: 0.25rem;
      background-color: ${(props) => props.theme.white};
`;

export const FormGroupSection = styled.section`
      position: relative;
`;

export const FormGroupUpload = styled.div`
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 9rem;
      border-radius: 0.25rem;
      border-width: 2px;
      border-style: dashed;
      cursor: pointer;
      border-color: rgb(170, 172, 174);

      svg {
            color: rgb(170, 172, 174);
      }

      p {
            margin-top: 1rem;
            font-size: 0.875rem;
            line-height: 1.25rem;
            text-align: center;

            span:first-child {
                  color: ${(props) => props.theme.primaryColor};
                  font-weight: 600;
            }

            span:last-child {
                  font-size: 0.75rem;
                  line-height: 1rem;
            }
      }
`;

export const FormGroupInput = styled.input`
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      overflow: hidden;
      opacity: 0;
`;

export const FormImageGallery = styled.div`
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 20px;
      margin-top: 20px;

      img {
            width: 100%;
            max-height: 120px;
            border-radius: 8px;
            object-fit: contain;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      }
`;

export const FormImageContainer = styled.div`
      position: relative;
`;

export const FormImageRemove = styled.button`
      position: absolute;
      top: 8px;
      right: 8px;
      background-color: #ff0000;
      color: #ffffff;
      padding: 4px 8px;
      border: none;
      border-radius: 4px;
      font-size: 12px;
      cursor: pointer;
      opacity: 0.8;

      &:hover {
            opacity: 1;
      }
`;
