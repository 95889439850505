import React from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Description from '../../../components/Form/Description';

const TermCondition = ({
      loadTerms,
      setLoadTerms,
      handleTerms,
      terms,
      amountStatus,
      setAmountStatus
}) => {
      return (
            <>
                  {loadTerms && (
                        <Card className="mt-3">
                              <Card.Body>
                                    <Description
                                          setDescription={handleTerms}
                                          description={terms}
                                    />
                              </Card.Body>
                        </Card>
                  )}
                  <Row>
                        <Form.Group as={Col} lg={3}>
                              <Link
                                    onClick={() => setLoadTerms(!loadTerms)}
                                    style={{
                                          border: '1px solid #dee2e6',
                                          padding: '6px 10px',
                                          borderRadius: '5px',
                                          backgroundColor: 'white',
                                          marginTop: '30px',
                                          display: 'block',
                                          textAlign: 'center'
                                    }}
                              >
                                    Terms & Condition
                              </Link>
                        </Form.Group>

                        <Form.Group as={Col} lg={3}>
                              <Link
                                    onClick={() =>
                                          setAmountStatus(!amountStatus)
                                    }
                                    style={{
                                          border: '1px solid #dee2e6',
                                          padding: '6px 10px',
                                          borderRadius: '5px',
                                          backgroundColor: 'white',
                                          marginTop: '30px',
                                          display: 'block',
                                          textAlign: 'center'
                                    }}
                              >
                                    Amount In Words
                              </Link>
                        </Form.Group>
                  </Row>
            </>
      );
};

export default TermCondition;
