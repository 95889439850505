import React from 'react';
import NavSeparator from '../Navigation/NavSeparator';
import {
      MetisMenuIcon,
      MetisMenuLink,
      MetisMenuTitle
} from '../../styles/layouts/navigation';
import { NavLink } from 'react-router-dom';
import CheckMultiplePermission from '../roles/CheckMultiplePermission';

const QuotationManage = ({ userPermissions }) => {
      return (
            <>
                  <CheckMultiplePermission
                        userPermissions={userPermissions}
                        permissions={[
                              'quotation-list',
                              'quotation-create',
                              'quotation-edit',
                              'quotation-delete',
                              'quotation-show',
                              'quotation-pending-list',
                              'quotation-approved-list',
                              'quotation-cancelled-list',
                              'quotation-rejected-list'
                        ]}
                  >
                        <NavSeparator title="Quotation Manage" />
                        <li>
                              <MetisMenuLink className="has-arrow" href="#">
                                    <MetisMenuIcon>
                                          <span className="material-symbols-outlined">
                                                format_quote
                                          </span>
                                    </MetisMenuIcon>
                                    <MetisMenuTitle>
                                          <span>Quotation Manage</span>
                                    </MetisMenuTitle>
                              </MetisMenuLink>
                              <ul>
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                'quotation-list',
                                                'quotation-create'
                                          ]}
                                    >
                                          <li>
                                                <NavLink to="/dashboard/quotations">
                                                      Own Quotation
                                                </NavLink>
                                          </li>
                                    </CheckMultiplePermission>
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                'quotation-pending-list'
                                          ]}
                                    >
                                          <li>
                                                <NavLink to="/dashboard/quotations/pending/requests">
                                                      All Pending Request
                                                </NavLink>
                                          </li>
                                    </CheckMultiplePermission>
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                'quotation-approved-list'
                                          ]}
                                    >
                                          <li>
                                                <NavLink to="/dashboard/quotations/approved/requests">
                                                      All Approved Request
                                                </NavLink>
                                          </li>
                                    </CheckMultiplePermission>
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                'quotation-rejected-list'
                                          ]}
                                    >
                                          <li>
                                                <NavLink to="/dashboard/quotations/rejected/requests">
                                                      All Rejected Request
                                                </NavLink>
                                          </li>
                                    </CheckMultiplePermission>
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                'quotation-cancelled-list'
                                          ]}
                                    >
                                          <li>
                                                <NavLink to="/dashboard/quotations/cancelled/requests">
                                                      All Cancelled Request
                                                </NavLink>
                                          </li>
                                    </CheckMultiplePermission>
                              </ul>
                        </li>
                  </CheckMultiplePermission>
            </>
      );
};

export default QuotationManage;
