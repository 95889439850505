import React from 'react';

const SettingIcon = () => {
      return (
            <>
                  <span className="material-symbols-outlined">settings</span>
            </>
      );
};

export default SettingIcon;
