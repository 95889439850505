import React from 'react';
import { ListGroupItem } from 'react-bootstrap';

const ItemShow = ({ title, value }) => {
      console.log(value);
      return (
            <>
                  {(value || value == 0) && (
                        <ListGroupItem>
                              {title} :<strong className="ps-3">{value}</strong>
                        </ListGroupItem>
                  )}
            </>
      );
};

export default ItemShow;
