import React from 'react';
import { MenuSeparator } from '../../styles/layouts/navigation';

const NavSeparator = ({ title }) => {
      return (
            <>
                  <MenuSeparator>{title}</MenuSeparator>
            </>
      );
};

export default NavSeparator;
