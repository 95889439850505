import { Card } from 'react-bootstrap';
import { styled } from 'styled-components';

export const CardHeader = styled(Card.Header)`
      background-color: ${(props) => props.theme.primaryColor};
      color: ${(props) => props.theme.white};
      display: flex;
      justify-content: space-between;
      align-items: center;
`;

export const CardTitle = styled(Card.Title)`
      margin-bottom: 0;
      padding-bottom: 0;
      font-size: 16px;
      font-weight: 500;
`;

export const CardLinks = styled.div`
      display: flex;
      align-items: center;
      gap: 15px;
`;
