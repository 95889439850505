import axios from 'axios';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { Card, Col, Row, Table } from 'react-bootstrap';
import {
      CardHeader,
      CardLinks,
      CardTitle
} from '../../styles/components/common/Table';
import { LinkButton } from '../../styles/components/common/Button';
import TableSearch from '../../components/Form/Search';
import TableFooter from '../../components/Form/TableFooter';
import ProductImage from '../../components/Form/ProductImage';
import { Link } from 'react-router-dom';
import { DeleteIcon, EditIcon } from '../../components/icons';
import Progress from 'react-progress-2';
import { toast } from 'react-hot-toast';

const RoleList = () => {
      const [roles, setRoles] = useState([]);
      const [loading, setLoading] = useState(false);
      const [totalRoles, setTotalRoles] = useState(0);
      const [perPages, setPerPages] = useState(0);
      const [currentPage, setCurrentPage] = useState(0);

      const token = JSON.parse(localStorage.getItem('token'));
      const [limit, setLimit] = useState(10);
      const [search, setSearch] = useState('');

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/roles?limit=${limit}&search=${search}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        setRoles(response.data.roles);
                        setTotalRoles(response.data.totalRoles);
                        setPerPages(response.data.itemsCountPerPage);
                        setCurrentPage(response.data.currentPage);
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, [limit, search]);

      async function getRoleData(pageNumber = 1) {
            window.screenTop(0, 0);
            Progress.show();
            const url = `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/roles?page=${pageNumber}&limit=${limit}&search=${search}`;
            await axios(url, {
                  headers: {
                        Authorization: `Bearer ${token}`
                  }
            })
                  .then((response) => {
                        setRoles(response.data.roles);
                        setTotalRoles(response.data.totalRoles);
                        setPerPages(response.data.itemsCountPerPage);
                        setCurrentPage(response.data.currentPage);
                        Progress.hide();
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      }

      const handleDelete = async (role) => {
            setLoading(true);
            await axios
                  .delete(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/roles/${role.slug}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setRoles([]);
                              loadData();
                              toast.success(response.data.message);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      return (
            <>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader>
                                          <CardTitle>Role List</CardTitle>
                                          <CardLinks>
                                                <LinkButton to="/dashboard/roles/create">
                                                      Add New
                                                </LinkButton>
                                          </CardLinks>
                                    </CardHeader>
                                    <Card.Body>
                                          <TableSearch
                                                setLimit={setLimit}
                                                setSearch={setSearch}
                                          />

                                          <Table hover bordered>
                                                <thead>
                                                      <tr>
                                                            <th
                                                                  className="text-center"
                                                                  width="60px"
                                                            >
                                                                  #
                                                            </th>
                                                            <th> Title</th>
                                                            <th width="120px">
                                                                  Action
                                                            </th>
                                                      </tr>
                                                </thead>
                                                <tbody>
                                                      {!loading ? (
                                                            roles.length > 0 ? (
                                                                  roles.map(
                                                                        (
                                                                              role,
                                                                              index
                                                                        ) => (
                                                                              <>
                                                                                    <tr
                                                                                          key={
                                                                                                index
                                                                                          }
                                                                                    >
                                                                                          <td className="text-center">
                                                                                                {
                                                                                                      role.index
                                                                                                }
                                                                                          </td>
                                                                                          <td>
                                                                                                <ProductImage
                                                                                                      title={
                                                                                                            role.title
                                                                                                      }
                                                                                                />
                                                                                          </td>
                                                                                          <td className="text-center">
                                                                                                <Link
                                                                                                      to={`/dashboard/roles/${role.id}/edit`}
                                                                                                      className="text-secondary"
                                                                                                >
                                                                                                      <EditIcon />
                                                                                                </Link>
                                                                                                <Link
                                                                                                      onClick={() =>
                                                                                                            handleDelete(
                                                                                                                  role
                                                                                                            )
                                                                                                      }
                                                                                                      className="text-danger mx-2"
                                                                                                >
                                                                                                      <DeleteIcon />
                                                                                                </Link>
                                                                                          </td>
                                                                                    </tr>
                                                                              </>
                                                                        )
                                                                  )
                                                            ) : (
                                                                  <>
                                                                        <tr>
                                                                              <td
                                                                                    colSpan="5"
                                                                                    className="text-center"
                                                                              >
                                                                                    No
                                                                                    Blog
                                                                                    Found.
                                                                              </td>
                                                                        </tr>
                                                                  </>
                                                            )
                                                      ) : (
                                                            <>
                                                                  <tr>
                                                                        <td
                                                                              colSpan="5"
                                                                              className="text-center"
                                                                        >
                                                                              Loading...
                                                                        </td>
                                                                  </tr>
                                                            </>
                                                      )}
                                                </tbody>
                                          </Table>
                                          <TableFooter
                                                perPages={perPages}
                                                totalItems={totalRoles}
                                                currentPage={currentPage}
                                                getData={getRoleData}
                                          />
                                    </Card.Body>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default RoleList;
