import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';

const BrandManage = () => {
      const [brands, setBrands] = useState([]);
      const [loading, setLoading] = useState(false);

      useEffect(() => {
            setLoading(true);

            axios.get(
                  `${process.env.REACT_APP_SECRET_KEY}/api/brands/list/orders`
            ).then((response) => {
                  setBrands(response.data.brands);
                  setLoading(false);
            });
      }, []);

      return (
            <>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <Card.Body></Card.Body>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default BrandManage;
