import React from 'react';

const UploadIcon = () => {
      return (
            <>
                  <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="41px"
                        height="30px"
                        viewBox="0 0 40.909 30"
                        className="text-muted-light"
                  >
                        <g transform="translate(0 -73.091)">
                              <path
                                    data-name="Path 2125"
                                    d="M39.129,89.827A8.064,8.064,0,0,0,34.58,86.94,5.446,5.446,0,0,0,30,78.546a5.207,5.207,0,0,0-3.537,1.321,10.921,10.921,0,0,0-10.1-6.776,10.511,10.511,0,0,0-7.713,3.2A10.508,10.508,0,0,0,5.454,84q0,.277.043.916A9.528,9.528,0,0,0,0,93.546a9.193,9.193,0,0,0,2.8,6.743,9.191,9.191,0,0,0,6.744,2.8H32.728a8.172,8.172,0,0,0,6.4-13.264Zm-12.06-.575a.656.656,0,0,1-.479.2H21.818v7.5a.691.691,0,0,1-.681.681H17.045a.691.691,0,0,1-.682-.681v-7.5H11.59a.655.655,0,0,1-.681-.681.8.8,0,0,1,.213-.512L18.6,80.783a.722.722,0,0,1,.98,0l7.5,7.5a.663.663,0,0,1,.191.49A.656.656,0,0,1,27.07,89.252Z"
                                    transform="translate(0)"
                                    fill="currentColor"
                              ></path>
                        </g>
                  </svg>
            </>
      );
};

export default UploadIcon;
