import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { styled } from 'styled-components';

export const Dashboard = styled.div`
      position: relative;
`;

export const MainContent = styled.div`
      position: absolute;
      top: 0;
      left: 250px;
      width: calc(100% - 250px);
      background-color: ${(props) => props.theme.cardBodyColor};
      min-height: 100vh;
      padding: 20px 20px;
      /* padding-bottom: 5rem; */
`;

export const MainContainer = styled.div`
      min-height: calc(665px - 3rem);
`;

export const MainHeader = styled.div`
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 6px 20px;
      background-color: ${(props) => props.theme.white};
      border: 1px solid rgba(0, 0, 0, 0.175);
      border-radius: 0.375rem;
      margin-bottom: 20px;

      span {
            color: ${(props) => props.theme.linkColor};
      }
`;

export const HeaderLeftBar = styled.div`
      display: flex;

      a {
            color: ${(props) => props.theme.linkColor};

            &:hover {
                  color: ${(props) => props.theme.primaryColor};
            }
      }
`;

export const HeaderFunction = styled.div`
      display: flex;
      align-items: center;

      span {
            color: ${(props) => props.theme.primaryColor};
      }
`;

export const HeaderNotification = styled.div`
      padding-left: 10px;
`;

export const DropdownToggle = styled(Dropdown.Toggle)`
      background-color: transparent;
      border: none;
      font-size: 14px;
      font-weight: 500;
      padding: 0;
      padding-right: 10px;
      border-color: #66615b;
      color: #66615b;
      transition: all 150ms linear;

      &:hover,
      &:focus,
      &:active,
      &:first-child:active {
            background-color: transparent;
      }

      &:focus-visible {
            box-shadow: none !important;
      }

      &::after {
            content: none;
      }
`;

export const DropdownMenu = styled(Dropdown.Menu)`
      padding-top: 0;
      padding-bottom: 0;
      width: 350px;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      transition: ease 0.3s;
      border: none;
`;

export const DropdownHeader = styled.div`
      padding: 20px 25px;
      background: linear-gradient(
            90deg,
            ${(props) => props.theme.linkColor} 0%,
            ${(props) => props.theme.primaryColor} 100%
      );
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;

      h4 {
            font-size: 20px;
      }
`;

export const DropdownContent = styled.div`
      padding: 1rem 0.25rem;

      a {
            font-size: 13px;
            color: ${(props) => props.theme.primaryColor};
      }
`;

export const HeaderProfile = styled.div`
      margin-left: 10px;
      border: 1px solid ${(props) => props.theme.cardBodyColor};
      border-radius: 40px;
      position: relative;

      img {
            width: 40px;
            height: 40px;
            object-fit: cover;
            border-radius: 50%;
            padding: 5px;
      }
`;

export const HeaderProfileContent = styled.div`
      position: absolute;
      right: 0;
      top: 48px;
      text-align: left;
      min-width: 200px;
      border-radius: 5px;
      transition: 0.5s;
      padding: 30px 30px 20px;
      background-color: ${(props) => props.theme.linkColor};
      color: ${(props) => props.theme.borderColor};
      visibility: hidden;
      opacity: 0;
      border-radius: 5px;
      width: max-content;

      p {
            font-size: 13px;
      }

      ${HeaderProfile}:hover & {
            visibility: visible;
            opacity: 1;
            z-index: 999;
      }
`;

export const HeaderProfileDetail = styled.div`
      margin-top: 20px;
      border-top: 1px solid #79838b;
      padding-top: 10px;
      display: grid;

      a {
            color: ${(props) => props.theme.borderColor};
            font-size: 13px;
            display: inline-block;
            margin: 8px 0;
      }

      span {
            color: ${(props) => props.theme.borderColor};
            line-height: 1;
            padding-right: 0px;
            margin-right: 10px;
            font-size: 14px;
      }
`;

export const ProfileLink = styled(Link)`
      display: flex;
      align-items: center;
      justify-content: end;

      &:hover,
      &:hover > span {
            color: ${(props) => props.theme.primaryColor};
      }
`;
