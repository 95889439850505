import React from 'react';
import {
      MainDashboard,
      ProfileCardBody,
      ProfileContainer,
      ProfileContent,
      ProfileListGroup,
      ProfileSidebar
} from './styles';
import MenuItem from '../../components/profile/MenuItem';

const ProfileLayout = ({ children }) => {
      return (
            <>
                  <MainDashboard>
                        <ProfileContainer>
                              <ProfileSidebar>
                                    <ProfileCardBody>
                                          <ProfileListGroup>
                                                <MenuItem
                                                      title="Profile"
                                                      link="/dashboard/profile"
                                                />
                                                <MenuItem
                                                      title="Change  Password"
                                                      link="/dashboard/change-password"
                                                />
                                          </ProfileListGroup>
                                    </ProfileCardBody>
                              </ProfileSidebar>
                        </ProfileContainer>
                        <ProfileContent>{children}</ProfileContent>
                  </MainDashboard>
            </>
      );
};

export default ProfileLayout;
