import React, { useEffect, useState } from 'react';
import { Card, Col, FormControl, Row } from 'react-bootstrap';
import { CardBody } from 'reactstrap';
import { Loading } from '../../components/Loading';
import {
      ProductDetail,
      ProductIcon,
      ProductImage,
      ProductPrice,
      ProductSummary,
      ProductTitle,
      ProductContainer,
      ProductRow,
      CategoryListFilter,
      CategoryListItem,
      CategoryListRemove,
      CategoryListRemoveIcon
} from '../../styles/backend/product';

import axios from 'axios';
import Progress from 'react-progress-2';
import 'react-progress-2/main.css';
import FilterProduct from './FilterProduct';
import { toast } from 'react-hot-toast';
import Pagination from 'react-js-pagination';

const QuotationProduct = () => {
      const [customerQuotations, setCustomerQuotations] = useState([]);
      const [customerQuotationCount, setCustomerQuotationCount] = useState(0);
      const [brands, setBrands] = useState([]);
      const [categories, setCategories] = useState([]);

      const [products, setProducts] = useState([]);
      const [totalProducts, setTotalProducts] = useState(0);
      const [perPages, setPerPages] = useState(0);
      const [currentPage, setCurrentPage] = useState(0);

      const [loading, setLoading] = useState(true);

      const [selectedBrands, setSelectedBrands] = useState([]);
      const [selectedBrandLists, setSelectedBrandLists] = useState([]);

      const [selectedCuisines, setSelectedCuisines] = useState([]);
      const [selectedCategories, setSelectedCategories] = useState([]);

      const [search, setSearch] = useState('');

      const token = JSON.parse(localStorage.getItem('token'));

      const handleBrandChange = async (event, type) => {
            const data = {
                  category: event,
                  type: type
            };
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/quotations/filter/products`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setProducts(response.data.products);
                        }
                  });
      };

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/quotations/filter/products`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setProducts(response.data.products);
                              setTotalProducts(response.data.totalProducts);
                              setPerPages(response.data.itemsCountPerPage);
                              setCurrentPage(response.data.currentPage);
                        }
                  });

            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/quotations/list/create`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setBrands(response.data.brands);
                              setCategories(response.data.categories);
                        }
                  });

            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/customers/quotations`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setCustomerQuotations(response.data.quotations);
                              setCustomerQuotationCount(
                                    response.data.quotations.length
                              );
                        }
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      function handleCustomerQuotation(product) {
            const productList = products;
            const checkProducts = productList.map((item) =>
                  item.id === product.id
                        ? { ...product, status: !item.status }
                        : item
            );
            setProducts(checkProducts);
      }

      const addQuotation = async (product, status) => {
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/customers/quotations/${product.slug}/add`,
                        {
                              status: status
                        },
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              toast.success(response.data.message);
                              setCustomerQuotations(response.data.quotations);
                              setCustomerQuotationCount(
                                    response.data.quotations.length
                              );
                              handleCustomerQuotation(product);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };

      async function getData(pageNumber = 1) {
            window.scrollTo(0, 0);
            setLoading(true);
            Progress.show();

            try {
                  const filters = [];

                  if (selectedBrands.length) {
                        filters.push(`brands=${selectedBrands.join(',')}`);
                  }
                  if (selectedCuisines.length) {
                        filters.push(
                              `categories=${selectedCuisines.join(',')}`
                        );
                  }

                  const filterQueryString =
                        filters.length > 0 ? `&${filters.join('&')}` : '';
                  const url = `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/quotations/filter/products?${filterQueryString}&search=${search}&page=${pageNumber}`;

                  const response = await axios.get(url, {
                        headers: {
                              Authorization: `Bearer ${JSON.parse(
                                    localStorage.getItem('token')
                              )}`
                        }
                  });

                  setProducts(response.data.products);
                  setTotalProducts(response.data.totalProducts);
                  setPerPages(response.data.itemsCountPerPage);
                  setCurrentPage(response.data.currentPage);

                  Progress.hide();
                  setLoading(false);
            } catch (error) {
                  toast.error(error.message);
                  Progress.hide();
                  setLoading(false);
            }
      }

      const handleChangeCategory = (id, type) => {
            const updatedCategories = categories.map((item) => {
                  if (type === 'category') {
                        return item.id === id
                              ? { ...item, checked: !item.checked }
                              : item;
                  } else if (type === 'subCategory') {
                        const updatedSubCategory = item.subCategory.map(
                              (subCategory) => {
                                    return subCategory.id === id
                                          ? {
                                                  ...subCategory,
                                                  checked: !subCategory.checked
                                            }
                                          : subCategory;
                              }
                        );
                        return { ...item, subCategory: updatedSubCategory };
                  } else if (type === 'childCategory') {
                        const updatedSubCategory = item.subCategory.map(
                              (subCategory) => {
                                    const updatedChildCategory =
                                          subCategory.childCategory.map(
                                                (childCategory) => {
                                                      return childCategory.id ===
                                                            id
                                                            ? {
                                                                    ...childCategory,
                                                                    checked: !childCategory.checked
                                                              }
                                                            : childCategory;
                                                }
                                          );
                                    return {
                                          ...subCategory,
                                          childCategory: updatedChildCategory
                                    };
                              }
                        );
                        return { ...item, subCategory: updatedSubCategory };
                  }
                  return item;
            });

            setCategories(updatedCategories);
      };

      const handleChangeBrand = (id, type) => {
            const brandsStateList = brands;
            const changeCheckedBrand = brandsStateList.map((item) => {
                  if (type === 'brand') {
                        return item.id === id
                              ? { ...item, checked: !item.checked }
                              : item;
                  }
                  return item;
            });
            setBrands(changeCheckedBrand);
      };

      const changePrice = async () => {
            const filters = [];

            const brandsChecked = brands
                  .filter((item) => item.checked)
                  .map((item) => ({
                        id: item.id,
                        title: item.title,
                        type: 'brand'
                  }));

            const selectedBrandIds = brandsChecked.map((item) => item.id);
            setSelectedBrands(selectedBrandIds);
            setSelectedBrandLists(brandsChecked);

            if (selectedBrandIds.length) {
                  filters.push(`brands=${selectedBrandIds.join(',')}`);
            }

            const cuisinesChecked = categories
                  .filter((item) => item.checked)
                  .map((item) => ({
                        id: item.id,
                        title: item.title,
                        type: 'category'
                  }));

            const subCategoriesChecked = categories
                  .flatMap((item) =>
                        item.subCategory.filter((subItem) => subItem.checked)
                  )
                  .map((subItem) => ({
                        id: subItem.id,
                        title: subItem.title,
                        type: 'subCategory'
                  }));

            const childCategoriesChecked = categories
                  .flatMap((item) =>
                        item.subCategory.flatMap((subItem) =>
                              subItem.childCategory.filter(
                                    (childItem) => childItem.checked
                              )
                        )
                  )
                  .map((childItem) => ({
                        id: childItem.id,
                        title: childItem.title,
                        type: 'childCategory'
                  }));

            const allCheckedIds = [
                  ...cuisinesChecked,
                  ...subCategoriesChecked,
                  ...childCategoriesChecked
            ];

            const selectedCuisinesIds = allCheckedIds.map((item) => item.id);
            setSelectedCuisines(selectedCuisinesIds);
            setSelectedCategories(allCheckedIds);

            if (allCheckedIds.length) {
                  filters.push(`categories=${selectedCuisinesIds.join(',')}`);
            }

            if (search) {
                  filters.push(`search=${search}`);
            }

            const apiUrl = `${
                  process.env.REACT_APP_SECRET_KEY
            }/api/dashboard/quotations/filter/products${
                  filters.length > 0 ? '?' + filters.join('&') : '?'
            }`;

            await axios
                  .get(apiUrl, {
                        headers: {
                              Authorization: `Bearer ${token}`
                        }
                  })
                  .then((response) => {
                        setProducts(response.data.products);
                        setTotalProducts(response.data.totalProducts);
                        setPerPages(response.data.itemsCountPerPage);
                        setCurrentPage(response.data.currentPage);
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };

      const handleSearch = async (event) => {
            setSearch(event.target.value);
      };

      useEffect(() => {
            changePrice();
      }, [brands, categories]);

      useEffect(() => {
            const delay = 500;

            const timer = setTimeout(() => {
                  changePrice();
            }, delay);

            return () => clearTimeout(timer);
      }, [search]);

      return (
            <>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardBody>
                                          <FilterProduct
                                                brands={brands}
                                                handleBrandChange={
                                                      handleChangeBrand
                                                }
                                                handleCategoryChange={
                                                      handleChangeCategory
                                                }
                                                totalProducts={totalProducts}
                                                categories={categories}
                                                quotations={customerQuotations}
                                          />
                                          <div className="mt-3">
                                                <FormControl
                                                      placeholder="Search by product name"
                                                      value={search}
                                                      onChange={handleSearch}
                                                />
                                          </div>

                                          <CategoryListFilter>
                                                {selectedBrandLists.length >
                                                      0 &&
                                                      selectedBrandLists.map(
                                                            (brand, index) => (
                                                                  <li
                                                                        key={
                                                                              index
                                                                        }
                                                                  >
                                                                        <CategoryListItem>
                                                                              {
                                                                                    brand.title
                                                                              }
                                                                              <CategoryListRemove
                                                                                    onClick={() =>
                                                                                          handleChangeBrand(
                                                                                                brand.id,
                                                                                                brand.type
                                                                                          )
                                                                                    }
                                                                              >
                                                                                    <CategoryListRemoveIcon>
                                                                                          <i className="bx bx-x"></i>
                                                                                    </CategoryListRemoveIcon>
                                                                              </CategoryListRemove>
                                                                        </CategoryListItem>
                                                                  </li>
                                                            )
                                                      )}
                                                {selectedCategories.length >
                                                      0 &&
                                                      selectedCategories.map(
                                                            (item, index) => (
                                                                  <li
                                                                        key={
                                                                              index
                                                                        }
                                                                  >
                                                                        <CategoryListItem>
                                                                              {
                                                                                    item.title
                                                                              }
                                                                              <CategoryListRemove
                                                                                    onClick={() =>
                                                                                          handleChangeCategory(
                                                                                                item.id,
                                                                                                item.type
                                                                                          )
                                                                                    }
                                                                              >
                                                                                    <CategoryListRemoveIcon>
                                                                                          <i className="bx bx-x"></i>
                                                                                    </CategoryListRemoveIcon>
                                                                              </CategoryListRemove>
                                                                        </CategoryListItem>
                                                                  </li>
                                                            )
                                                      )}
                                          </CategoryListFilter>
                                          <ProductRow className="g-4">
                                                {!loading ? (
                                                      products.length > 0 ? (
                                                            products.map(
                                                                  (
                                                                        product,
                                                                        index
                                                                  ) => (
                                                                        <Col
                                                                              md={
                                                                                    6
                                                                              }
                                                                              lg={
                                                                                    3
                                                                              }
                                                                              sm={
                                                                                    6
                                                                              }
                                                                              className="col-6"
                                                                        >
                                                                              <ProductContainer
                                                                                    key={
                                                                                          index
                                                                                    }
                                                                              >
                                                                                    <ProductImage>
                                                                                          <img
                                                                                                src={
                                                                                                      product.image
                                                                                                }
                                                                                                alt={
                                                                                                      product.title
                                                                                                }
                                                                                          />
                                                                                    </ProductImage>
                                                                                    <ProductDetail>
                                                                                          <ProductTitle>
                                                                                                {
                                                                                                      product.title
                                                                                                }
                                                                                          </ProductTitle>
                                                                                          <ProductSummary>
                                                                                                <ProductPrice>
                                                                                                      Brand:{' '}
                                                                                                      {
                                                                                                            product.brand
                                                                                                      }
                                                                                                </ProductPrice>
                                                                                                <ProductPrice>
                                                                                                      Product
                                                                                                      Code:{' '}
                                                                                                      {
                                                                                                            product.product_code
                                                                                                      }
                                                                                                </ProductPrice>
                                                                                                <ProductPrice>
                                                                                                      Rs.{' '}
                                                                                                      {
                                                                                                            product.price
                                                                                                      }
                                                                                                </ProductPrice>
                                                                                          </ProductSummary>
                                                                                    </ProductDetail>
                                                                                    <ProductIcon>
                                                                                          <button
                                                                                                className={
                                                                                                      product.status
                                                                                                            ? 'added'
                                                                                                            : ''
                                                                                                }
                                                                                                onClick={() =>
                                                                                                      addQuotation(
                                                                                                            product,
                                                                                                            product.status
                                                                                                      )
                                                                                                }
                                                                                          >
                                                                                                {product.status ===
                                                                                                true
                                                                                                      ? 'Remove'
                                                                                                      : 'Add to Quote'}
                                                                                          </button>
                                                                                    </ProductIcon>
                                                                              </ProductContainer>
                                                                        </Col>
                                                                  )
                                                            )
                                                      ) : (
                                                            <>
                                                                  No Product
                                                                  Found
                                                            </>
                                                      )
                                                ) : (
                                                      <>
                                                            <div className="text-center w-100">
                                                                  <Loading />
                                                            </div>
                                                      </>
                                                )}
                                                {products.length > 0 && (
                                                      <Col
                                                            lg={12}
                                                            className="product__filter"
                                                      >
                                                            <Pagination
                                                                  activePage={
                                                                        currentPage
                                                                  }
                                                                  totalItemsCount={
                                                                        totalProducts
                                                                  }
                                                                  itemsCountPerPage={
                                                                        perPages
                                                                  }
                                                                  onChange={(
                                                                        pageNumber
                                                                  ) =>
                                                                        getData(
                                                                              pageNumber
                                                                        )
                                                                  }
                                                                  itemClass="page-item"
                                                                  linkClass="page-link"
                                                                  firstPageText="First"
                                                                  lastPageText="Last"
                                                            />
                                                      </Col>
                                                )}
                                          </ProductRow>
                                    </CardBody>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default QuotationProduct;
