import React, { useState } from 'react';
import { Card, Col, ListGroup, ListGroupItem, Row } from 'react-bootstrap';
import {
      CardHeader,
      CardLinks,
      CardTitle
} from '../../styles/components/common/Table';
import { LinkButton } from '../../styles/components/common/Button';
import ItemShow from '../../components/Form/ItemShow';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { Loading } from '../../components/Loading';

const CompanyShow = () => {
      const { companyId } = useParams();

      const [company, setCompany] = useState([]);
      const [loading, setLoading] = useState(false);

      const token = JSON.parse(localStorage.getItem('token'));

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/shops/${companyId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setCompany(response.data.shop);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader>
                                          <CardTitle>Company Details</CardTitle>
                                          <CardLinks>
                                                <LinkButton to="/dashboard/companies">
                                                      Back
                                                </LinkButton>
                                          </CardLinks>
                                    </CardHeader>
                                    <Card.Body>
                                          <Row>
                                                {!loading ? (
                                                      <>
                                                            <Col lg={6}>
                                                                  <ListGroup>
                                                                        <ItemShow
                                                                              title="Company Name"
                                                                              value={
                                                                                    company?.name
                                                                              }
                                                                        />
                                                                        <ItemShow
                                                                              title="Phone No."
                                                                              value={
                                                                                    company.phone_number
                                                                              }
                                                                        />
                                                                        <ItemShow
                                                                              title="Email"
                                                                              value={
                                                                                    company.email
                                                                              }
                                                                        />
                                                                        <ItemShow
                                                                              title="Company Name"
                                                                              value={
                                                                                    company.company_name
                                                                              }
                                                                        />
                                                                        <ItemShow
                                                                              title="Position"
                                                                              value={
                                                                                    company.position
                                                                              }
                                                                        />
                                                                        <ItemShow
                                                                              title="Address"
                                                                              value={
                                                                                    company.address
                                                                              }
                                                                        />
                                                                        <ItemShow
                                                                              title="PAN No."
                                                                              value={
                                                                                    company.pan_number
                                                                              }
                                                                        />
                                                                        <ItemShow
                                                                              title="User Id"
                                                                              value={
                                                                                    company.user_name
                                                                              }
                                                                        />
                                                                        <ItemShow
                                                                              title="Created Date"
                                                                              value={
                                                                                    company.date
                                                                              }
                                                                        />
                                                                  </ListGroup>
                                                            </Col>
                                                            <Col lg={6}>
                                                                  <ListGroup>
                                                                        <ListGroupItem className="text-center">
                                                                              <img
                                                                                    src={
                                                                                          company.image
                                                                                    }
                                                                                    alt={
                                                                                          company.title
                                                                                    }
                                                                                    className="border p-2 rounded"
                                                                                    style={{
                                                                                          width: '150px',
                                                                                          height: '150px',
                                                                                          objectFit:
                                                                                                'contain'
                                                                                    }}
                                                                              />
                                                                              <div className="mt-2">
                                                                                    Logo
                                                                                    Image
                                                                              </div>
                                                                        </ListGroupItem>
                                                                  </ListGroup>
                                                            </Col>
                                                      </>
                                                ) : (
                                                      <>
                                                            <div
                                                                  className="text-center d-flex align-items-center justify-content-center w-100"
                                                                  style={{
                                                                        height: '30vh'
                                                                  }}
                                                            >
                                                                  <Loading />
                                                            </div>
                                                      </>
                                                )}
                                          </Row>
                                    </Card.Body>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default CompanyShow;
