import React, { useState } from 'react';
import { Card, Col, ListGroup, Row } from 'react-bootstrap';
import {
      CardHeader,
      CardLinks,
      CardTitle
} from '../../styles/components/common/Table';
import { LinkButton } from '../../styles/components/common/Button';
import ItemShow from '../../components/Form/ItemShow';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { Loading } from '../../components/Loading';

const ContactShow = () => {
      const { contactId } = useParams();

      const [contact, setContact] = useState([]);
      const [loading, setLoading] = useState(false);

      const token = JSON.parse(localStorage.getItem('token'));

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/contact_persons/${contactId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setContact(response.data.contact);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader>
                                          <CardTitle>Contact Details</CardTitle>
                                          <CardLinks>
                                                <LinkButton to="/dashboard/contacts">
                                                      Back
                                                </LinkButton>
                                          </CardLinks>
                                    </CardHeader>
                                    <Card.Body>
                                          <Row>
                                                {!loading ? (
                                                      <>
                                                            <Col lg={6}>
                                                                  <ListGroup>
                                                                        <ItemShow
                                                                              title="Full Name"
                                                                              value={
                                                                                    contact?.first_name +
                                                                                    ' ' +
                                                                                    contact?.last_name
                                                                              }
                                                                        />
                                                                        <ItemShow
                                                                              title="Phone No."
                                                                              value={
                                                                                    contact.phone_number
                                                                              }
                                                                        />
                                                                        <ItemShow
                                                                              title="Email"
                                                                              value={
                                                                                    contact.email
                                                                              }
                                                                        />
                                                                        <ItemShow
                                                                              title="Position"
                                                                              value={
                                                                                    contact.position
                                                                              }
                                                                        />
                                                                        <ItemShow
                                                                              title="Address"
                                                                              value={
                                                                                    contact.address
                                                                              }
                                                                        />
                                                                        <ItemShow
                                                                              title="Company Name"
                                                                              value={
                                                                                    contact.company_name
                                                                              }
                                                                        />
                                                                        <ItemShow
                                                                              title="PAN No."
                                                                              value={
                                                                                    contact.pan_number
                                                                              }
                                                                        />
                                                                        <ItemShow
                                                                              title="Project"
                                                                              value={
                                                                                    contact.project
                                                                              }
                                                                        />
                                                                        <ItemShow
                                                                              title="User Id"
                                                                              value={
                                                                                    contact.user_name
                                                                              }
                                                                        />
                                                                        <ItemShow
                                                                              title="Created Date"
                                                                              value={
                                                                                    contact.date
                                                                              }
                                                                        />
                                                                  </ListGroup>
                                                            </Col>
                                                      </>
                                                ) : (
                                                      <>
                                                            <div
                                                                  className="text-center d-flex align-items-center justify-content-center w-100"
                                                                  style={{
                                                                        height: '30vh'
                                                                  }}
                                                            >
                                                                  <Loading />
                                                            </div>
                                                      </>
                                                )}
                                          </Row>
                                    </Card.Body>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default ContactShow;
