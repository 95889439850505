import { styled } from 'styled-components';

export const SearchContainer = styled.div`
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1rem;
`;

export const SearchEntity = styled.div`
      display: flex;
      align-items: center;
`;
