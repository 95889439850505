import React, { useState } from 'react';
import { Card, Col, ListGroup, ListGroupItem, Row } from 'react-bootstrap';
import {
      CardHeader,
      CardLinks,
      CardTitle
} from '../../styles/components/common/Table';
import { LinkButton } from '../../styles/components/common/Button';
import ItemShow from '../../components/Form/ItemShow';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';

import Progress from 'react-progress-2';
import 'react-progress-2/main.css';
import axios from 'axios';
import ProductList from '../../components/ProductList';
import { toast } from 'react-hot-toast';

const BrandShow = () => {
      const { brandId } = useParams();

      const [brand, setBrand] = useState([]);

      const [loading, setLoading] = useState(false);
      const [loadingData, setLoadingData] = useState(false);
      const [products, setProducts] = useState([]);
      const [totalProducts, setTotalProducts] = useState(0);
      const [perPages, setPerPages] = useState(0);
      const [currentPage, setCurrentPage] = useState(0);

      const token = JSON.parse(localStorage.getItem('token'));
      const [limit, setLimit] = useState(10);
      const [search, setSearch] = useState('');

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/brands/${brandId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setBrand(response.data.brand);
                        }
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      const loadProduct = async () => {
            setLoadingData(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/brands/${brandId}/edit?limit=${limit}&search=${search}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setProducts(response.data.products);
                              setTotalProducts(response.data.totalProducts);
                              setPerPages(response.data.itemsCountPerPage);
                              setCurrentPage(response.data.currentPage);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoadingData(false);
      };

      useEffect(() => {
            loadProduct();
      }, [limit, search]);

      async function getProductData(pageNumber = 1) {
            window.scrollTo(0, 0);
            Progress.show();

            const url = `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/brands/${brandId}/edit?page=${pageNumber}&limit=${limit}&search=${search}`;
            await axios(url, {
                  headers: {
                        Authorization: `Bearer ${token}`
                  }
            })
                  .then((response) => {
                        setProducts(response.data.products);
                        setTotalProducts(response.data.totalProducts);
                        setPerPages(response.data.itemsCountPerPage);
                        setCurrentPage(response.data.currentPage);
                        Progress.hide();
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      }

      return (
            <>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader>
                                          <CardTitle>Brand Details</CardTitle>
                                          <CardLinks>
                                                <LinkButton to="/dashboard/brands">
                                                      Back
                                                </LinkButton>
                                          </CardLinks>
                                    </CardHeader>
                                    <Card.Body>
                                          <Row>
                                                <Col lg={6}>
                                                      <ListGroup>
                                                            <ItemShow
                                                                  title="Title"
                                                                  value={
                                                                        brand.title
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Products"
                                                                  value={
                                                                        brand.products
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Website"
                                                                  value={
                                                                        brand.website
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Description"
                                                                  value={
                                                                        brand.description
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="SEO Title"
                                                                  value={
                                                                        brand.seo_title
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="SEO Keyword"
                                                                  value={
                                                                        brand.seo_keyword
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="SEO Description"
                                                                  value={
                                                                        brand.seo_description
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Created By"
                                                                  value={
                                                                        brand.user_name
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Created Date"
                                                                  value={
                                                                        brand.date
                                                                  }
                                                            />
                                                      </ListGroup>
                                                </Col>
                                                <Col lg={6}>
                                                      <ListGroup>
                                                            <ListGroupItem className="text-center">
                                                                  <img
                                                                        src={
                                                                              brand.image
                                                                        }
                                                                        alt={
                                                                              brand.title
                                                                        }
                                                                        className="border p-2 rounded"
                                                                        style={{
                                                                              width: '150px',
                                                                              height: '150px',
                                                                              objectFit:
                                                                                    'contain'
                                                                        }}
                                                                  />
                                                                  <div className="mt-2">
                                                                        Featured
                                                                        Image
                                                                  </div>
                                                            </ListGroupItem>
                                                            {brand.brand_banner && (
                                                                  <ListGroupItem>
                                                                        <img
                                                                              src={
                                                                                    brand.brand_banner
                                                                              }
                                                                              alt={
                                                                                    brand.title
                                                                              }
                                                                              className="border p-2 rounded"
                                                                              style={{
                                                                                    width: '150px',
                                                                                    height: '150px',
                                                                                    objectFit:
                                                                                          'contain'
                                                                              }}
                                                                        />
                                                                        <div>
                                                                              Brand
                                                                              Banner
                                                                        </div>
                                                                  </ListGroupItem>
                                                            )}
                                                            {brand.seo_image && (
                                                                  <ListGroupItem>
                                                                        <img
                                                                              src={
                                                                                    brand.seo_image
                                                                              }
                                                                              alt={
                                                                                    brand.title
                                                                              }
                                                                              style={{
                                                                                    width: '150px',
                                                                                    height: '150px',
                                                                                    objectFit:
                                                                                          'contain'
                                                                              }}
                                                                              className="border p-2 rounded"
                                                                        />
                                                                        <div>
                                                                              SEO
                                                                              Image
                                                                        </div>
                                                                  </ListGroupItem>
                                                            )}
                                                      </ListGroup>
                                                </Col>
                                          </Row>
                                    </Card.Body>
                              </Card>

                              <ProductList
                                    loadData={loadProduct}
                                    loading={loadingData}
                                    setLoading={setLoadingData}
                                    products={products}
                                    totalProducts={totalProducts}
                                    perPages={perPages}
                                    setProducts={setProducts}
                                    currentPage={currentPage}
                                    setSearch={setSearch}
                                    setLimit={setLimit}
                                    getProductData={getProductData}
                              />
                        </Col>
                  </Row>
            </>
      );
};

export default BrandShow;
