import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Table } from 'react-bootstrap';

import { toast } from 'react-hot-toast';
import Pagination from 'react-js-pagination';
import Progress from 'react-progress-2';
import 'react-progress-2/main.css';
import { CardHeader, CardTitle } from '../../styles/components/common/Table';
import TableSearch from '../../components/Form/Search';
import ProductImage from '../../components/Form/ProductImage';
import ViewButton from '../../components/commons/ViewButton';
import DeleteButton from '../../components/commons/DeleteButton';

const PendingOrder = () => {
      const [loading, setLoading] = useState(false);
      const [orders, setOrders] = useState([]);
      const [totalOrders, setTotalOrders] = useState(0);
      const [perPages, setPerPages] = useState(0);
      const [currentPage, setCurrentPage] = useState(0);

      const token = JSON.parse(localStorage.getItem('token'));

      const loadData = async () => {
            setLoading(true);
            axios.get(
                  `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/orders/pending`,
                  {
                        headers: {
                              Authorization: `Bearer ${JSON.parse(
                                    localStorage.getItem('token')
                              )}`
                        }
                  }
            )
                  .then((response) => {
                        setOrders(response.data.orders);
                        setTotalOrders(response.data.totalOrders);
                        setPerPages(response.data.itemsCountPerPage);
                        setCurrentPage(response.data.currentPage);
                        setLoading(false);
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
      };
      useEffect(() => {
            loadData();
      }, []);

      async function getProductData(pageNumber = 1) {
            window.scrollTo(0, 0);
            Progress.show();
            // console.log(pageNumber);
            const url = `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/orders/pending?page=${pageNumber}`;
            await axios(url, {
                  headers: {
                        Authorization: `Bearer ${token}`
                  }
            }).then((response) => {
                  setOrders(response.data.orders);
                  setTotalOrders(response.data.totalOrders);
                  setPerPages(response.data.itemsCountPerPage);
                  setCurrentPage(response.data.currentPage);
                  Progress.hide();
            });
      }

      const handleDelete = async (order) => {
            const response = await axios
                  .delete(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/orders/${order.slug}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setOrders((orders) =>
                                    orders.filter(
                                          (item) => item.id !== order.id
                                    )
                              );
                              loadData();
                              toast.success(response.data.message);
                              return true;
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            return response;
      };

      return (
            <>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader>
                                          <CardTitle>
                                                Pending Order List
                                          </CardTitle>
                                    </CardHeader>
                                    <Card.Body>
                                          <TableSearch />
                                          <Table hover bordered>
                                                <thead>
                                                      <tr>
                                                            <th>#</th>
                                                            <th>Order By</th>
                                                            <th>
                                                                  Contact
                                                                  Details
                                                            </th>
                                                            <th>Items</th>
                                                            <th>Status</th>
                                                            <th width="100px">
                                                                  Action
                                                            </th>
                                                      </tr>
                                                </thead>
                                                <tbody>
                                                      {!loading ? (
                                                            orders?.length >
                                                            0 ? (
                                                                  orders.map(
                                                                        (
                                                                              order,
                                                                              index
                                                                        ) => (
                                                                              <>
                                                                                    <tr
                                                                                          key={
                                                                                                index
                                                                                          }
                                                                                    >
                                                                                          <td>
                                                                                                {
                                                                                                      order.index
                                                                                                }
                                                                                          </td>
                                                                                          <td>
                                                                                                <ProductImage
                                                                                                      title={
                                                                                                            order.name
                                                                                                      }
                                                                                                      category={
                                                                                                            order.category
                                                                                                      }
                                                                                                />
                                                                                          </td>
                                                                                          <td>
                                                                                                <ProductImage
                                                                                                      title={
                                                                                                            order.phone_number
                                                                                                      }
                                                                                                      category={
                                                                                                            order.address
                                                                                                      }
                                                                                                />
                                                                                          </td>
                                                                                          <td>
                                                                                                <ProductImage
                                                                                                      title={
                                                                                                            order.phone_number
                                                                                                      }
                                                                                                      category={
                                                                                                            order.address
                                                                                                      }
                                                                                                />
                                                                                          </td>
                                                                                          <td>
                                                                                                <ProductImage
                                                                                                      title={
                                                                                                            order.status
                                                                                                      }
                                                                                                      category={`Payment: ${order.payment}`}
                                                                                                />
                                                                                          </td>
                                                                                          <td>
                                                                                                <ViewButton
                                                                                                      link={`/dashboard/pending/orders/${order.slug}/show`}
                                                                                                />
                                                                                                <DeleteButton
                                                                                                      item={
                                                                                                            order
                                                                                                      }
                                                                                                      loading={
                                                                                                            loading
                                                                                                      }
                                                                                                      setLoading={
                                                                                                            setLoading
                                                                                                      }
                                                                                                      handleDelete={
                                                                                                            handleDelete
                                                                                                      }
                                                                                                />
                                                                                          </td>
                                                                                    </tr>
                                                                              </>
                                                                        )
                                                                  )
                                                            ) : (
                                                                  <>
                                                                        <tr>
                                                                              <td
                                                                                    colSpan={
                                                                                          6
                                                                                    }
                                                                                    className="text-center"
                                                                              >
                                                                                    No
                                                                                    Order
                                                                                    Found
                                                                              </td>
                                                                        </tr>
                                                                  </>
                                                            )
                                                      ) : (
                                                            <>
                                                                  <tr>
                                                                        <td
                                                                              colSpan={
                                                                                    6
                                                                              }
                                                                              className="text-center"
                                                                        >
                                                                              No
                                                                              Order
                                                                              Found
                                                                        </td>
                                                                  </tr>
                                                            </>
                                                      )}
                                                </tbody>
                                          </Table>

                                          <div className="clearfix">
                                                <div className="float-start">
                                                      Showing {perPages} items
                                                      from {totalOrders} items
                                                </div>
                                                <div className="float-end">
                                                      {totalOrders > 10 && (
                                                            <Pagination
                                                                  activePage={
                                                                        currentPage
                                                                  }
                                                                  totalItemsCount={
                                                                        totalOrders
                                                                  }
                                                                  itemsCountPerPage={
                                                                        perPages
                                                                  }
                                                                  onChange={(
                                                                        pageNumber
                                                                  ) =>
                                                                        getProductData(
                                                                              pageNumber
                                                                        )
                                                                  }
                                                                  itemClass="page-item"
                                                                  linkClass="page-link"
                                                                  firstPageText="First"
                                                                  lastPageText="Last"
                                                            />
                                                      )}
                                                </div>
                                          </div>
                                    </Card.Body>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default PendingOrder;
