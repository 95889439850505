import React from 'react';
import { Card, Col, Image, ListGroup, Row } from 'react-bootstrap';
import {
      CardHeader,
      CardLinks,
      CardTitle
} from '../../styles/components/common/Table';
import { LinkButton } from '../../styles/components/common/Button';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import ItemShow from '../../components/Form/ItemShow';
import { Loading } from '../../components/Loading';

const ProductShow = () => {
      const { productId } = useParams();

      const [loading, setLoading] = useState(false);
      const [product, setProduct] = useState([]);

      const token = JSON.parse(localStorage.getItem('token'));

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/products/${productId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setProduct(response.data.product);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <Row className="g-4">
                        <Col lg={12}>
                              <Card>
                                    <CardHeader>
                                          <CardTitle>Product Details</CardTitle>
                                          <CardLinks>
                                                <LinkButton to="/dashboard/products">
                                                      Back
                                                </LinkButton>
                                                <LinkButton
                                                      to={`/dashboard/trending_products/${productId}/create`}
                                                >
                                                      Create trending
                                                </LinkButton>
                                          </CardLinks>
                                    </CardHeader>
                                    <Card.Body>
                                          {!loading ? (
                                                <Row>
                                                      <Col lg={6}>
                                                            <div className="d-flex gap-3">
                                                                  <div>
                                                                        <Image
                                                                              src={
                                                                                    product.image
                                                                              }
                                                                              alt={
                                                                                    product.title
                                                                              }
                                                                              style={{
                                                                                    width: '220px',
                                                                                    height: '220px',
                                                                                    objectFit:
                                                                                          'contain'
                                                                              }}
                                                                        />
                                                                        <div>
                                                                              Feature
                                                                              Image
                                                                        </div>
                                                                  </div>
                                                                  {product.seo_image && (
                                                                        <>
                                                                              <div>
                                                                                    <Image
                                                                                          src={
                                                                                                product.seo_image
                                                                                          }
                                                                                          alt={
                                                                                                product.title
                                                                                          }
                                                                                          style={{
                                                                                                width: '220px',
                                                                                                height: '220px',
                                                                                                objectFit:
                                                                                                      'contain'
                                                                                          }}
                                                                                    />
                                                                                    <div>
                                                                                          SEO
                                                                                          Image
                                                                                    </div>
                                                                              </div>
                                                                        </>
                                                                  )}
                                                            </div>
                                                            <div className="mt-3">
                                                                  <div
                                                                        dangerouslySetInnerHTML={{
                                                                              __html: product.description
                                                                        }}
                                                                  ></div>
                                                            </div>
                                                      </Col>
                                                      <Col lg={6}>
                                                            <ListGroup>
                                                                  <ItemShow
                                                                        title="Product Title"
                                                                        value={
                                                                              product.title
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Product Code"
                                                                        value={
                                                                              product.product_code
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Brand"
                                                                        value={
                                                                              product.brand_name
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Category"
                                                                        value={
                                                                              product.category_name
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Color"
                                                                        value={
                                                                              !loading &&
                                                                              product.colors && (
                                                                                    <>
                                                                                          {product.colors.map(
                                                                                                (
                                                                                                      color,
                                                                                                      index
                                                                                                ) => (
                                                                                                      <label
                                                                                                            className="pe-2"
                                                                                                            key={
                                                                                                                  index
                                                                                                            }
                                                                                                      >{`${color.title}, `}</label>
                                                                                                )
                                                                                          )}
                                                                                    </>
                                                                              )
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Size"
                                                                        value={`${product.size} ${product.size_unit}`}
                                                                  />
                                                                  <ItemShow
                                                                        title="Stock"
                                                                        value={
                                                                              product.stock
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Mrp Price"
                                                                        value={`Rs ${product.mrpPrice} / ${product.unit}`}
                                                                  />
                                                                  <ItemShow
                                                                        title="Discount Price"
                                                                        value={`Rs ${product.discountPrice}`}
                                                                  />
                                                                  <ItemShow
                                                                        title="Offer Price"
                                                                        value={`Rs ${product.offerPrice}`}
                                                                  />
                                                                  <ItemShow
                                                                        title="Dealer Price"
                                                                        value={`Rs ${product.dealerPrice}`}
                                                                  />
                                                                  <ItemShow
                                                                        title="Seo Title"
                                                                        value={
                                                                              product.seo_title
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Seo Keyword"
                                                                        value={
                                                                              product.seo_keyword
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Seo Description"
                                                                        value={
                                                                              product.seo_description
                                                                        }
                                                                  />
                                                            </ListGroup>
                                                      </Col>
                                                </Row>
                                          ) : (
                                                <>
                                                      <Loading />
                                                </>
                                          )}
                                    </Card.Body>
                              </Card>
                        </Col>
                        {!loading && product.images && (
                              <>
                                    <Col lg={12}>
                                          <Card>
                                                <Card.Body>
                                                      {!loading &&
                                                            product.images &&
                                                            product.images
                                                                  .length > 0 &&
                                                            product.images.map(
                                                                  (
                                                                        image,
                                                                        index
                                                                  ) => (
                                                                        <div
                                                                              key={
                                                                                    index
                                                                              }
                                                                        >
                                                                              <Image
                                                                                    src={
                                                                                          image.image
                                                                                    }
                                                                                    style={{
                                                                                          width: '220px',
                                                                                          height: '220px',
                                                                                          objectFit:
                                                                                                'contain'
                                                                                    }}
                                                                              />
                                                                        </div>
                                                                  )
                                                            )}
                                                </Card.Body>
                                          </Card>
                                    </Col>
                              </>
                        )}
                  </Row>
            </>
      );
};

export default ProductShow;
