import React from 'react';
import {
      QuotationBusinessDetail,
      QuotationContactPerson
} from '../../styles/backend/quotation';
import Select from 'react-select';

const CustomerInformation = ({
      loading,
      contacts,
      handleContactDetail,
      address,
      setAddress,
      contactNumber,
      setContactNumber,
      emailAddress,
      setEmailAddress,
      panNumber,
      setPanNumber,
      projectName,
      setProjectName,
      remarks,
      setRemarks,
      companyName,
      setCompanyName,
      contactPerson,
      setContactPerson
}) => {
      const onChange = (newValue) => {
            handleContactDetail(newValue);
      };

      return (
            <>
                  <div className="col-lg-6">
                        <div>
                              <h4>Quotation To</h4>
                              <Select onChange={onChange} options={contacts} />
                        </div>
                  </div>
                  <div className="col-lg-6"></div>
                  <div className="col-lg-12">
                        <QuotationContactPerson className="row">
                              <div className="col-lg-6">
                                    <QuotationBusinessDetail>
                                          <div>
                                                <span>Contact Person</span>
                                                <input
                                                      className="form-control"
                                                      type="text"
                                                      disabled
                                                      value={contactPerson}
                                                      onChange={(event) =>
                                                            setContactPerson(
                                                                  event.target
                                                                        .value
                                                            )
                                                      }
                                                />
                                          </div>
                                          <div>
                                                <span>Contact No</span>
                                                <input
                                                      className="form-control"
                                                      type="text"
                                                      value={contactNumber}
                                                      onChange={(event) =>
                                                            setContactNumber(
                                                                  event.target
                                                                        .value
                                                            )
                                                      }
                                                />
                                          </div>
                                          <div>
                                                <span>Email Address</span>
                                                <input
                                                      className="form-control"
                                                      type="text"
                                                      value={emailAddress}
                                                      onChange={(event) =>
                                                            setEmailAddress(
                                                                  event.target
                                                                        .value
                                                            )
                                                      }
                                                />
                                          </div>
                                          <div>
                                                <span>Address</span>
                                                <input
                                                      className="form-control"
                                                      type="text"
                                                      value={address}
                                                      onChange={(event) =>
                                                            setAddress(
                                                                  event.target
                                                                        .value
                                                            )
                                                      }
                                                />
                                          </div>
                                    </QuotationBusinessDetail>
                              </div>
                              <div className="col-lg-6">
                                    <QuotationBusinessDetail>
                                          <div>
                                                <span>Company Name</span>
                                                <input
                                                      className="form-control"
                                                      type="text"
                                                      value={companyName}
                                                      onChange={(event) =>
                                                            setCompanyName(
                                                                  event.target
                                                                        .value
                                                            )
                                                      }
                                                />
                                          </div>
                                          <div>
                                                <span>PAN No</span>
                                                <input
                                                      className="form-control"
                                                      type="text"
                                                      value={panNumber}
                                                      onChange={(event) =>
                                                            setPanNumber(
                                                                  event.target
                                                                        .value
                                                            )
                                                      }
                                                />
                                          </div>
                                          <div>
                                                <span>Project Name</span>
                                                <input
                                                      className="form-control"
                                                      type="text"
                                                      value={projectName}
                                                      onChange={(event) =>
                                                            setProjectName(
                                                                  event.target
                                                                        .value
                                                            )
                                                      }
                                                />
                                          </div>
                                          <div>
                                                <span>Remarks</span>
                                                <input
                                                      className="form-control"
                                                      type="text"
                                                      value={remarks}
                                                      onChange={(event) =>
                                                            setRemarks(
                                                                  event.target
                                                                        .value
                                                            )
                                                      }
                                                />
                                          </div>
                                    </QuotationBusinessDetail>
                              </div>
                        </QuotationContactPerson>
                  </div>
            </>
      );
};

export default CustomerInformation;
