import React from 'react';
import CheckMultiplePermission from '../roles/CheckMultiplePermission';
import NavSeparator from '../Navigation/NavSeparator';
import {
      MetisMenuIcon,
      MetisMenuLink,
      MetisMenuTitle
} from '../../styles/layouts/navigation';
import { NavLink } from 'react-router-dom';

const ContentManage = ({ userPermissions }) => {
      return (
            <>
                  <CheckMultiplePermission
                        userPermissions={userPermissions}
                        permissions={[
                              'catalogue-list',
                              'catalogue-create',
                              'catalogue-edit',
                              'catalogue-show',
                              'blog-list',
                              'blog-edit',
                              'blog-create',
                              'blog-show',
                              'blog-category-list',
                              'blog-category-create',
                              'blog-category-show',
                              'blog-category-edit',
                              'faq-index',
                              'faq-edit',
                              'faq-create',
                              'faq-show',
                              'faq-type-index',
                              'faq-type-create',
                              'faq-type-show',
                              'faq-type-edit',
                              'faq-request-list',
                              'faq-request-show',
                              'page-list',
                              'page-edit',
                              'page-show',
                              'page-create'
                        ]}
                  >
                        <NavSeparator title="Content Manage" />
                        <li>
                              <MetisMenuLink to="/dashboard/catalogues">
                                    <MetisMenuIcon>
                                          <span className="material-symbols-outlined">
                                                widgets
                                          </span>
                                    </MetisMenuIcon>
                                    <MetisMenuTitle>
                                          <span>Catalogue Manage</span>
                                    </MetisMenuTitle>
                              </MetisMenuLink>
                        </li>
                        <CheckMultiplePermission
                              userPermissions={userPermissions}
                              permissions={[
                                    'blog-list',
                                    'blog-edit',
                                    'blog-create',
                                    'blog-show',
                                    'blog-category-list',
                                    'blog-category-create',
                                    'blog-category-show',
                                    'blog-category-edit'
                              ]}
                        >
                              <li>
                                    <MetisMenuLink
                                          className="has-arrow"
                                          href="#"
                                    >
                                          <MetisMenuIcon>
                                                <span className="material-symbols-outlined">
                                                      feed
                                                </span>
                                          </MetisMenuIcon>
                                          <MetisMenuTitle>
                                                <span>Blog</span>
                                          </MetisMenuTitle>
                                    </MetisMenuLink>
                                    <ul>
                                          <CheckMultiplePermission
                                                userPermissions={
                                                      userPermissions
                                                }
                                                permissions={[
                                                      'blog-list',
                                                      'blog-edit',
                                                      'blog-create',
                                                      'blog-show'
                                                ]}
                                          >
                                                <li>
                                                      <NavLink to="/dashboard/blogs">
                                                            Blog Posts
                                                      </NavLink>
                                                </li>
                                          </CheckMultiplePermission>
                                          <CheckMultiplePermission
                                                userPermissions={
                                                      userPermissions
                                                }
                                                permissions={[
                                                      'blog-category-list',
                                                      'blog-category-create',
                                                      'blog-category-show',
                                                      'blog-category-edit'
                                                ]}
                                          >
                                                <li>
                                                      <NavLink to="/dashboard/blogs/categories">
                                                            Blog Category
                                                      </NavLink>
                                                </li>
                                          </CheckMultiplePermission>
                                    </ul>
                              </li>
                        </CheckMultiplePermission>

                        <CheckMultiplePermission
                              userPermissions={userPermissions}
                              permissions={[
                                    'faq-index',
                                    'faq-edit',
                                    'faq-create',
                                    'faq-show',
                                    'faq-type-index',
                                    'faq-type-create',
                                    'faq-type-show',
                                    'faq-type-edit',
                                    'faq-request-list',
                                    'faq-request-show'
                              ]}
                        >
                              <li>
                                    <MetisMenuLink
                                          className="has-arrow"
                                          href="#"
                                    >
                                          <MetisMenuIcon>
                                                <span className="material-symbols-outlined">
                                                      feed
                                                </span>
                                          </MetisMenuIcon>
                                          <MetisMenuTitle>
                                                <span>Faq Manage</span>
                                          </MetisMenuTitle>
                                    </MetisMenuLink>
                                    <ul>
                                          <CheckMultiplePermission
                                                userPermissions={
                                                      userPermissions
                                                }
                                                permissions={[
                                                      'faq-index',
                                                      'faq-edit',
                                                      'faq-create',
                                                      'faq-show'
                                                ]}
                                          >
                                                <li>
                                                      <NavLink to="/dashboard/faqs">
                                                            All Faq
                                                      </NavLink>
                                                </li>
                                          </CheckMultiplePermission>

                                          <CheckMultiplePermission
                                                userPermissions={
                                                      userPermissions
                                                }
                                                permissions={[
                                                      'faq-type-index',
                                                      'faq-type-create',
                                                      'faq-type-show',
                                                      'faq-type-edit'
                                                ]}
                                          >
                                                <li>
                                                      <NavLink to="/dashboard/faqs/types">
                                                            Faq Type
                                                      </NavLink>
                                                </li>
                                          </CheckMultiplePermission>

                                          <CheckMultiplePermission
                                                userPermissions={
                                                      userPermissions
                                                }
                                                permissions={[
                                                      'faq-request-list',
                                                      'faq-request-show'
                                                ]}
                                          >
                                                <li>
                                                      <NavLink to="/dashboard/faq_requests">
                                                            Faq Request List
                                                      </NavLink>
                                                </li>
                                          </CheckMultiplePermission>
                                    </ul>
                              </li>
                        </CheckMultiplePermission>

                        <CheckMultiplePermission
                              userPermissions={userPermissions}
                              permissions={[
                                    'page-list',
                                    'page-edit',
                                    'page-show',
                                    'page-create'
                              ]}
                        >
                              <li>
                                    <MetisMenuLink
                                          className="has-arrow"
                                          href="#"
                                    >
                                          <MetisMenuIcon>
                                                <span className="material-symbols-outlined">
                                                      feed
                                                </span>
                                          </MetisMenuIcon>
                                          <MetisMenuTitle>
                                                <span>Page Manage</span>
                                          </MetisMenuTitle>
                                    </MetisMenuLink>
                                    <ul>
                                          <CheckMultiplePermission
                                                userPermissions={
                                                      userPermissions
                                                }
                                                permissions={[
                                                      'page-list',
                                                      'page-edit',
                                                      'page-show',
                                                      'page-create'
                                                ]}
                                          >
                                                <li>
                                                      <NavLink to="/dashboard/pages">
                                                            Page List
                                                      </NavLink>
                                                </li>
                                          </CheckMultiplePermission>
                                    </ul>
                              </li>
                        </CheckMultiplePermission>
                  </CheckMultiplePermission>
            </>
      );
};

export default ContentManage;
