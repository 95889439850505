import React, { useEffect, useState } from 'react';
import { Card, Col, Container, Form, FormControl, Row } from 'react-bootstrap';
import { QuotationTable } from '../../styles/components/forms/Table';

import $ from 'jquery';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import Input from '../../components/Form/Input';
import FormSelect from '../../components/Form/FormSelect';
import TermCondition from './components/TermCondition';
import AmountWord from './components/AmountWord';

const options = [
      {
            id: 'Approved',
            title: 'Approved'
      },
      {
            id: 'Pending',
            title: 'Pending'
      },
      {
            id: 'Rejected',
            title: 'Rejected'
      },
      {
            id: 'Cancelled',
            title: 'Cancelled'
      }
];

const QuotationShow = () => {
      const { quotationId } = useParams();

      const [shop, setShop] = useState('');
      const [shops, setShops] = useState([]);

      const [items, setItems] = useState([]);
      const [quotations, setQuotations] = useState([]);
      const [loading, setLoading] = useState(false);
      const [quantity, setQuantity] = useState(0);

      const [status, setStatus] = useState('');

      const [subTotal, setSubTotal] = useState(0);
      const [totalAmount, setTotalAmount] = useState(0);
      const [totalVatAmount, setTotalVatAmount] = useState(0);

      const [terms, setTerms] = useState('');
      const [loadTerms, setLoadTerms] = useState(false);

      const [amountNumber, setAmountNumber] = useState('');
      const [amountStatus, setAmountStatus] = useState(false);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/quotation_requests/${quotationId}/edit`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`
                              }
                        }
                  )
                  .then((response) => {
                        setQuotations(response.data.quotation);
                        setStatus(response.data.quotation.status);
                        setQuantity(response.data.items.length);
                        setItems(response.data.items);
                        setSubTotal(response.data.subTotal);
                        setTotalAmount(response.data.totalAmount);
                        setTotalVatAmount(response.data.totalVatAmount);
                        setTerms(response.data.terms);
                        setShop(response.data.quotation.shop_id);
                        setAmountNumber(response.data.quotation.amount_number);
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });

            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/shops/getShops/list`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setShops(response.data.shops);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      const updateQuantity = async (itemId, newQuantity, index) => {
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/quotations/update-order-quantity/${itemId}`,
                        { quantity: newQuantity },
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              const updatedItems = [...items];
                              updatedItems[index].amount = response.data.amount;
                              updatedItems[index].approved_price =
                                    response.data.approved_price;
                              setItems(updatedItems);

                              setSubTotal(response.data.subTotal);
                              setTotalAmount(response.data.totalAmount);
                              setTotalVatAmount(response.data.totalVatAmount);

                              // toast.success(response.data.message);
                        } else if (response.data.result === 'error') {
                              if (response.data.message) {
                                    toast.error(response.data.message.quantity);
                              }
                        }
                  })
                  .catch((error) => {
                        toast.error('Error updating quantity:', error);
                  });
      };

      const handleQuantityChange = async (event, index) => {
            const { value } = event.target;

            const itemId = items[index].slug;
            updateQuantity(itemId, value, index);
      };

      const updatePrice = async (itemId, newPrice, index) => {
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/quotations/update-order-price/${itemId}`,
                        { price: newPrice },
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              const updatedItems = [...items];
                              updatedItems[index].amount = response.data.amount;
                              updatedItems[index].approved_discount =
                                    response.data.discount;
                              updatedItems[index].approved_price =
                                    response.data.approved_price;
                              setItems(updatedItems);

                              $('#discount' + index).val(
                                    response.data.discount
                              );

                              setSubTotal(response.data.subTotal);
                              setTotalAmount(response.data.totalAmount);
                              setTotalVatAmount(response.data.totalVatAmount);
                              // toast.success(response.data.message);
                        }
                        if (response.data.result === 'error') {
                              if (response.data.message) {
                                    toast.error(response.data.message.quantity);
                              }
                        }
                  })
                  .catch((error) => {
                        toast.error('Error updating price:', error);
                  });
      };

      const handlePriceChange = async (event, index) => {
            const { value } = event.target;

            const itemId = items[index].slug;
            updatePrice(itemId, value, index);
      };

      const updateDiscount = async (itemId, newDiscount, index) => {
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/quotations/update-order-discount/${itemId}`,
                        { discount: newDiscount },
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              const updatedItems = [...items];
                              updatedItems[index].amount = response.data.amount;
                              updatedItems[index].approved_price =
                                    response.data.approved_price;
                              setItems(updatedItems);
                              $('#amount' + index).val(
                                    response.data.approved_price
                              );

                              setSubTotal(response.data.subTotal);
                              setTotalAmount(response.data.totalAmount);
                              setTotalVatAmount(response.data.totalVatAmount);
                              // toast.success(response.data.message);
                        }
                        if (response.data.result === 'error') {
                              if (response.data.message) {
                                    toast.error(response.data.message.discount);
                              }
                        }
                  })
                  .catch((error) => {
                        toast.error('Error updating discount:', error);
                  });
      };

      const handleDiscountChange = async (event, index) => {
            const { value } = event.target;

            const itemId = items[index].slug;
            updateDiscount(itemId, value, index);
      };

      const handleCompany = async (value) => {
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/quotations/${quotationId}/company/setup`,
                        {
                              shop: value
                        },
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`
                              }
                        }
                  )
                  .then((response) => {})
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };

      const handleStartingDate = async (value) => {
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/quotations/${quotationId}/starting/date`,
                        {
                              date: value
                        },
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              // toast.success(response.data.message);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };

      const handleDeadlineDate = async (value) => {
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/quotations/${quotationId}/deadline/date`,
                        {
                              date: value
                        },
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              // toast.success(response.data.message);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };

      const handleStatusDate = async (value) => {
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/quotations/${quotationId}/status/date`,
                        {
                              date: value
                        },
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              // toast.success(response.data.message);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };

      useEffect(() => {
            if (status != '') {
                  handleStatusDate(status);
            }
      }, [status]);

      useEffect(() => {
            if (shop != '') {
                  handleCompany(shop);
            }
      }, [shop]);

      const handleTerms = async (value) => {
            const data = {
                  description: value ? value : quotations.terms
            };
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/quotations/${quotationId}/terms`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`
                              }
                        }
                  )
                  .then((response) => {})
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };

      const handleAmountNumber = async (value) => {
            const data = {
                  word_amount: value ? value : quotations.terms
            };
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/quotations/${quotationId}/amount`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`
                              }
                        }
                  )
                  .then((response) => {})
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };

      return (
            <>
                  <Container
                        className="p-0 "
                        style={{
                              backgroundColor: '#f2f2f2',
                              minHeight: '100vh'
                        }}
                        fluid
                  >
                        <Row>
                              <Col lg={12}>
                                    <Card>
                                          <Card.Body>
                                                <QuotationTable bordered>
                                                      <thead>
                                                            <tr>
                                                                  <th width="60px"></th>
                                                                  <th>Item</th>
                                                                  <th width="120px">
                                                                        Alloted
                                                                        Qty
                                                                  </th>
                                                                  <th width="120px">
                                                                        Discount
                                                                        %
                                                                  </th>
                                                                  <th width="120px">
                                                                        Rate
                                                                  </th>
                                                                  <th width="120px">
                                                                        Total
                                                                        Amount
                                                                  </th>
                                                            </tr>
                                                      </thead>
                                                      <tbody>
                                                            {!loading ? (
                                                                  items.length >
                                                                  0 ? (
                                                                        items.map(
                                                                              (
                                                                                    item,
                                                                                    index
                                                                              ) => (
                                                                                    <>
                                                                                          <tr
                                                                                                className={
                                                                                                      index %
                                                                                                            2 ==
                                                                                                      1
                                                                                                            ? 'bg-gray'
                                                                                                            : ''
                                                                                                }
                                                                                          >
                                                                                                <td>
                                                                                                      {
                                                                                                            item.index
                                                                                                      }
                                                                                                </td>
                                                                                                <td>
                                                                                                      {
                                                                                                            item.product_name
                                                                                                      }
                                                                                                </td>
                                                                                                <td>
                                                                                                      <FormControl
                                                                                                            onChange={(
                                                                                                                  event
                                                                                                            ) =>
                                                                                                                  handleQuantityChange(
                                                                                                                        event,
                                                                                                                        index
                                                                                                                  )
                                                                                                            }
                                                                                                            defaultValue={
                                                                                                                  item.approved_quantity
                                                                                                            }
                                                                                                            type="text"
                                                                                                      />
                                                                                                </td>
                                                                                                <td>
                                                                                                      <input
                                                                                                            type="text"
                                                                                                            id={`discount${index}`}
                                                                                                            onChange={(
                                                                                                                  event
                                                                                                            ) =>
                                                                                                                  handleDiscountChange(
                                                                                                                        event,
                                                                                                                        index
                                                                                                                  )
                                                                                                            }
                                                                                                            className="form-control"
                                                                                                            defaultValue={
                                                                                                                  item.approved_discount
                                                                                                            }
                                                                                                      />
                                                                                                </td>
                                                                                                <td>
                                                                                                      <FormControl
                                                                                                            onChange={(
                                                                                                                  event
                                                                                                            ) =>
                                                                                                                  handlePriceChange(
                                                                                                                        event,
                                                                                                                        index
                                                                                                                  )
                                                                                                            }
                                                                                                            id={`amount${index}`}
                                                                                                            defaultValue={
                                                                                                                  item.approved_price
                                                                                                            }
                                                                                                            type="text"
                                                                                                      />
                                                                                                </td>
                                                                                                <td>
                                                                                                      <input
                                                                                                            className={`form-control`}
                                                                                                            type="text"
                                                                                                            style={{
                                                                                                                  width: '120px'
                                                                                                            }}
                                                                                                            value={
                                                                                                                  item.amount
                                                                                                            }
                                                                                                            readOnly
                                                                                                      />
                                                                                                </td>
                                                                                          </tr>
                                                                                          <tr
                                                                                                className={
                                                                                                      index %
                                                                                                            2 ==
                                                                                                      1
                                                                                                            ? 'bg-gray'
                                                                                                            : ''
                                                                                                }
                                                                                          >
                                                                                                <td></td>
                                                                                                <td>
                                                                                                      <ul>
                                                                                                            <li>
                                                                                                                  MRP
                                                                                                                  Price:{' '}
                                                                                                                  <strong>
                                                                                                                        Rs.{' '}
                                                                                                                        {
                                                                                                                              item.product_mrp
                                                                                                                        }
                                                                                                                  </strong>
                                                                                                            </li>
                                                                                                            <li>
                                                                                                                  MRP
                                                                                                                  Without
                                                                                                                  VAT:{' '}
                                                                                                                  <strong>
                                                                                                                        Rs.
                                                                                                                        {
                                                                                                                              item.amount_without_vat
                                                                                                                        }
                                                                                                                  </strong>
                                                                                                            </li>
                                                                                                            <li className="mt-3">
                                                                                                                  Request
                                                                                                                  Price:{' '}
                                                                                                                  <strong>
                                                                                                                        Rs.{' '}
                                                                                                                        {
                                                                                                                              item.requested_price
                                                                                                                        }
                                                                                                                  </strong>
                                                                                                            </li>
                                                                                                            <li>
                                                                                                                  Req.
                                                                                                                  Discount:{' '}
                                                                                                                  <strong>
                                                                                                                        {
                                                                                                                              item.requested_discount
                                                                                                                        }

                                                                                                                        %{' '}
                                                                                                                        (Rs.{' '}
                                                                                                                        {
                                                                                                                              item.request_discount_amount
                                                                                                                        }

                                                                                                                        )
                                                                                                                  </strong>
                                                                                                            </li>
                                                                                                            <li>
                                                                                                                  Req.
                                                                                                                  Amount After Discount:{' '}
                                                                                                                  <strong>
                                                                                                                        Rs.{' '}
                                                                                                                        {
                                                                                                                              item.request_discount_total
                                                                                                                        }
                                                                                                                  </strong>{' '}
                                                                                                            </li>
                                                                                                            <li>
                                                                                                                  Request
                                                                                                                  Qty:{' '}
                                                                                                                  <strong>
                                                                                                                        {
                                                                                                                              item.requested_quantity
                                                                                                                        }
                                                                                                                  </strong>
                                                                                                            </li>
                                                                                                      </ul>
                                                                                                </td>
                                                                                                <td></td>
                                                                                                <td></td>
                                                                                                <td
                                                                                                      colSpan={
                                                                                                            2
                                                                                                      }
                                                                                                >
                                                                                                      <img
                                                                                                            src={
                                                                                                                  item.product_image
                                                                                                            }
                                                                                                            alt={
                                                                                                                  item.product_name
                                                                                                            }
                                                                                                      />
                                                                                                </td>
                                                                                          </tr>
                                                                                    </>
                                                                              )
                                                                        )
                                                                  ) : (
                                                                        <>
                                                                              Loading
                                                                        </>
                                                                  )
                                                            ) : (
                                                                  <>Loading</>
                                                            )}
                                                      </tbody>
                                                </QuotationTable>
                                                <div className="col-lg-12 text-end mt-3">
                                                      <p className="d-flex justify-content-end">
                                                            <strong>
                                                                  Total Quantity{' '}
                                                            </strong>
                                                            <div
                                                                  className="ps-5 text-start fw-bold"
                                                                  style={{
                                                                        width: '180px'
                                                                  }}
                                                            >
                                                                  {' '}
                                                                  {quantity}
                                                            </div>
                                                      </p>
                                                      <p className="d-flex justify-content-end">
                                                            <strong>
                                                                  Sub Total
                                                                  Amount Without
                                                                  VAT{' '}
                                                            </strong>
                                                            <div
                                                                  className="ps-5 text-start fw-bold"
                                                                  style={{
                                                                        width: '180px'
                                                                  }}
                                                            >
                                                                  Rs. {subTotal}
                                                            </div>
                                                      </p>
                                                      <p className="d-flex justify-content-end">
                                                            <strong>
                                                                  Total VAT
                                                                  Amount (+){' '}
                                                            </strong>
                                                            <div
                                                                  className="ps-5 text-start fw-bold"
                                                                  style={{
                                                                        width: '180px'
                                                                  }}
                                                            >
                                                                  Rs.{' '}
                                                                  {
                                                                        totalVatAmount
                                                                  }
                                                            </div>
                                                      </p>

                                                      <p className="d-flex justify-content-end">
                                                            <strong>
                                                                  Total Amount
                                                                  With VAT{' '}
                                                            </strong>
                                                            <div
                                                                  className="ps-5 text-start fw-bold"
                                                                  style={{
                                                                        width: '180px'
                                                                  }}
                                                            >
                                                                  Rs.{' '}
                                                                  {totalAmount}
                                                            </div>
                                                      </p>
                                                </div>
                                          </Card.Body>
                                    </Card>
                                    <div>
                                          <AmountWord
                                                amountStatus={amountStatus}
                                                handleAmountNumber={
                                                      handleAmountNumber
                                                }
                                                amountNumber={amountNumber}
                                          />
                                          <TermCondition
                                                handleTerms={handleTerms}
                                                loadTerms={loadTerms}
                                                setLoadTerms={setLoadTerms}
                                                terms={terms}
                                                amountStatus={amountStatus}
                                                setAmountStatus={
                                                      setAmountStatus
                                                }
                                          />
                                    </div>

                                    <Row className="mt-3">
                                          <FormSelect
                                                title="Company"
                                                loading={loading}
                                                required={true}
                                                classes={3}
                                                selected={shop}
                                                setData={setShop}
                                                options={shops}
                                          />
                                          <Input
                                                title="Starting Date"
                                                required={true}
                                                type="date"
                                                value={quotations.start_date}
                                                handleChange={
                                                      handleStartingDate
                                                }
                                                classes={3}
                                          />
                                          <Input
                                                title="Deadline"
                                                required={true}
                                                type="date"
                                                value={quotations.deadline}
                                                handleChange={
                                                      handleDeadlineDate
                                                }
                                                classes={3}
                                          />
                                          <FormSelect
                                                title="Status"
                                                loading={loading}
                                                required={true}
                                                classes={3}
                                                selected={status}
                                                setData={setStatus}
                                                options={options}
                                          />
                                          <Form.Group as={Col} lg={3}>
                                                <Link
                                                      target="_blank"
                                                      to={`/dashboard/quotations/${quotationId}/show`}
                                                      style={{
                                                            border: '1px solid #dee2e6',
                                                            padding: '6px',
                                                            borderRadius: '5px',
                                                            backgroundColor:
                                                                  'white',
                                                            marginTop: '30px',
                                                            display: 'block',
                                                            textAlign: 'center'
                                                      }}
                                                >
                                                      Preview in PDF
                                                </Link>
                                          </Form.Group>
                                    </Row>
                              </Col>
                        </Row>
                  </Container>
            </>
      );
};

export default QuotationShow;
