import React, { useEffect, useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import {
      CardHeader,
      CardLinks,
      CardTitle
} from '../../styles/components/common/Table';
import { LinkButton } from '../../styles/components/common/Button';
import { CardBody } from 'reactstrap';
import $, { error } from 'jquery';
import { toast } from 'react-hot-toast';
import Input from '../../components/Form/Input';
import axios from 'axios';
import Description from '../../components/Form/Description';
import Select from '../../components/Form/Select';
import FormImage from '../../components/Form/image';
import FormSeo from '../../components/commons/FormSeo';
import FormButton from '../../components/Form/Button';
import Textarea from '../../components/Form/Textarea';
import FormSelect from '../../components/Form/FormSelect';

const BlogCreate = () => {
      const [categories, setCategories] = useState([]);
      const [loadingData, setLoadingData] = useState(false);

      const [title, setTitle] = useState('');
      const [slug, setSlug] = useState('');
      const [status, setStatus] = useState('');
      const [loading, setLoading] = useState(false);
      const [image, setImage] = useState('');
      const [imageUrl, setImageUrl] = useState('');
      const [category, setCategory] = useState('');
      const [summary, setSummary] = useState('');
      const [description, setDescription] = useState('');
      const [seoTitle, setSeoTitle] = useState(null);
      const [seoKeyword, setSeoKeyword] = useState(null);
      const [seoDescription, setSeoDescription] = useState(null);

      const token = JSON.parse(localStorage.getItem('token'));

      const loadCategories = async () => {
            setLoadingData(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/blogs/categories/list`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setCategories(response.data.categories);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoadingData(false);
      };

      useEffect(() => {
            loadCategories();
      }, []);

      const handleForm = async (event) => {
            event.preventDefault();
            setLoading(true);
            const data = {
                  title: title,
                  slug: slug,
                  summary: summary,
                  description: description,
                  category: category,
                  status: status,
                  image: image,
                  seo_title: seoTitle,
                  seo_keyword: seoKeyword,
                  seo_description: seoDescription
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/blogs`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                                    'Content-Type': 'multipart/form-data'
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              toast.success(response.data.message);
                              $('form').trigger('reset');
                              setImageUrl(null);
                        }
                        if (response.data.result === 'error') {
                              if (response.data.message.title) {
                                    toast.error(response.data.message.title);
                              }
                              if (response.data.message.slug) {
                                    toast.error(response.data.message.slug);
                              }
                              if (response.data.message.status) {
                                    toast.error(response.data.message.status);
                              }
                              if (response.data.message.description) {
                                    toast.error(
                                          response.data.message.description
                                    );
                              }
                              if (response.data.message.category) {
                                    toast.error(response.data.message.category);
                              }
                              if (response.data.message.image) {
                                    toast.error(response.data.message.image);
                              }
                              if (response.data.message.summary) {
                                    toast.error(response.data.message.summary);
                              }
                              if (response.data.message.seo_description) {
                                    toast.error(
                                          response.data.message.seo_description
                                    );
                              }
                              if (response.data.message.seo_title) {
                                    toast.error(
                                          response.data.message.seo_title
                                    );
                              }
                              if (response.data.message.seo_keyword) {
                                    toast.error(
                                          response.data.message.seo_keyword
                                    );
                              }
                        }
                        setLoading(false);
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };

      return (
            <>
                  <Form onSubmit={handleForm}>
                        <Row>
                              <Col lg={12}>
                                    <Card>
                                          <CardHeader>
                                                <CardTitle>
                                                      Fill Blog Details
                                                </CardTitle>
                                                <CardLinks>
                                                      <LinkButton to="/dashboard/blogs">
                                                            Back
                                                      </LinkButton>
                                                </CardLinks>
                                          </CardHeader>
                                          <CardBody>
                                                <Row className="g-3">
                                                      <Input
                                                            title="Title"
                                                            required={true}
                                                            handleChange={
                                                                  setTitle
                                                            }
                                                            classes={8}
                                                      />
                                                      <Input
                                                            title="Slug"
                                                            slug={slug}
                                                            required={true}
                                                            handleChange={
                                                                  setSlug
                                                            }
                                                            classes={4}
                                                      />
                                                      <FormSelect
                                                            loading={
                                                                  loadingData
                                                            }
                                                            title="Category"
                                                            required={true}
                                                            options={categories}
                                                            setData={
                                                                  setCategory
                                                            }
                                                            classes={4}
                                                      />
                                                      <Select
                                                            title="Status"
                                                            setStatus={
                                                                  setStatus
                                                            }
                                                            classes={4}
                                                      />
                                                      <Textarea
                                                            title="Summary"
                                                            required={true}
                                                            handleChange={
                                                                  setSummary
                                                            }
                                                      />
                                                      <Description
                                                            title="Description"
                                                            required={true}
                                                            setDescription={
                                                                  setDescription
                                                            }
                                                      />
                                                </Row>
                                          </CardBody>
                                    </Card>
                                    <Card className="mt-4">
                                          <Card.Body>
                                                <Row className="g-3">
                                                      <FormImage
                                                            title="Featured Image"
                                                            setImage={setImage}
                                                            setImageUrl={
                                                                  setImageUrl
                                                            }
                                                            required={true}
                                                            imageUrl={imageUrl}
                                                      />
                                                </Row>
                                          </Card.Body>
                                    </Card>
                                    <FormSeo
                                          setSeoDescription={setSeoDescription}
                                          setSeoTitle={setSeoTitle}
                                          setSeoKeyword={setSeoKeyword}
                                    />
                                    <Card className="mt-4 mb-5">
                                          <Card.Body>
                                                <Row>
                                                      <Col lg={12}>
                                                            <FormButton
                                                                  title="Submit"
                                                                  loading={
                                                                        loading
                                                                  }
                                                            />
                                                      </Col>
                                                </Row>
                                          </Card.Body>
                                    </Card>
                              </Col>
                        </Row>
                  </Form>
            </>
      );
};

export default BlogCreate;
