import React from 'react';

const DownloadIcon = () => {
      return (
            <>
                  <span
                        style={{
                              width: '20px',
                              height: '20px',
                              fontSize: '22px'
                        }}
                        className="material-symbols-outlined"
                  >
                        download
                  </span>
            </>
      );
};

export default DownloadIcon;
