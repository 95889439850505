import React from 'react';
import { Button, Spinner } from 'react-bootstrap';

const FormButton = ({ title, loading, type, handleButton, classes }) => {
      return (
            <>
                  <Button
                        type={type || 'submit'}
                        onClick={handleButton}
                        className={classes}
                  >
                        {title}{' '}
                        {loading && (
                              <Spinner
                                    style={{
                                          height: '18px',
                                          width: '18px',
                                          marginLeft: '6px'
                                    }}
                                    animation="border"
                                    role="status"
                              >
                                    <span className="visually-hidden">
                                          Loading...
                                    </span>
                              </Spinner>
                        )}
                  </Button>
            </>
      );
};

export default FormButton;
