import React, { useEffect, useRef } from 'react';
import $ from 'jquery';
import 'metismenu';

import { useLocation } from 'react-router-dom';
import { MetisMenu } from '../../styles/layouts/navigation';
import { useState } from 'react';
import axios from 'axios';
import {
      DashboardManage,
      WebsiteManage,
      FrontManage,
      ProductManage,
      QuotationManage,
      UserManage,
      ContentManage,
      SystemManage
} from '../menus';

const NavMenu = () => {
      const location = useLocation();
      const activeRef = useRef(null);

      const [userPermissions, setUserPermissions] = useState([]);
      const token = JSON.parse(localStorage.getItem('token'));
      const [loading, setLoading] = useState(false);

      const loadPermission = async () => {
            setLoading(true);
            await axios
                  .get(`${process.env.REACT_APP_SECRET_KEY}/api/auth/profile`, {
                        headers: {
                              Authorization: `Bearer ${token}`
                        }
                  })
                  .then((response) => {
                        setUserPermissions(response.data.user.permissions);
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };
      useEffect(() => {
            loadPermission();
      }, []);

      useEffect(() => {
            $('#menu').metisMenu();
      });

      useEffect(() => {
            if (activeRef.current) {
                  activeRef.current.scrollIntoView({ behavior: 'smooth' });
            }
      }, [location]);

      return (
            <>
                  {!loading ? (
                        <MetisMenu className="metismenu" id="menu">
                              <DashboardManage
                                    userPermissions={userPermissions}
                              />

                              <UserManage userPermissions={userPermissions} />
                              <FrontManage userPermissions={userPermissions} />
                              <ProductManage
                                    userPermissions={userPermissions}
                              />
                              <QuotationManage
                                    userPermissions={userPermissions}
                              />
                              <WebsiteManage
                                    userPermissions={userPermissions}
                              />

                              <ContentManage
                                    userPermissions={userPermissions}
                              />
                              <SystemManage userPermissions={userPermissions} />
                        </MetisMenu>
                  ) : (
                        <>Loading</>
                  )}
            </>
      );
};

export default NavMenu;
