import { Accordion, Row } from 'react-bootstrap';
import { styled } from 'styled-components';

export const ProductRow = styled(Row)`
      /* display: flex;
      flex-wrap: wrap;
      gap: 30px;
      margin-top: 30px; */
`;

export const ProductContainer = styled.div`
      background-color: #f2f2f2;
      padding: 10px;
      border-radius: 5px;
      height: 100%;
      /* width: calc(25% - 24px); */
      border: 1px solid #f2f2f2;
      transition: all 0.5s ease;
      display: flex;
      flex-direction: column;

      img {
            width: 100%;
      }

      &:hover {
            border: 1px solid ${(props) => props.theme.black};
      }
`;

export const ProductImage = styled.div`
      max-width: 250px;
      height: 220px;
      text-align: center;

      img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            padding: 5px;
      }
`;

export const ProductDetail = styled.div`
      padding: 5px;
`;

export const ProductTitle = styled.div`
      padding: 10px 0 5px;
      font-weight: 600;
`;

export const ProductSummary = styled.div``;

export const ProductPrice = styled.div``;

export const ProductIcon = styled.div`
      margin-top: auto;

      button {
            border: 1px solid;
            width: 100%;
            padding: 5px 10px;
            border-radius: 5px;
            background-color: ${(props) => props.theme.black};
            color: ${(props) => props.theme.white};

            &:hover {
                  background-color: ${(props) => props.theme.black};
                  color: ${(props) => props.theme.white};
            }
      }

      .added {
            background-color: ${(props) => props.theme.black};
            color: ${(props) => props.theme.white};
      }
`;

export const QuoteCountBox = styled.a`
      display: flex;
      align-items: center;
      border: 2px solid ${(props) => props.theme.black};
      border-radius: 5px;
      color: ${(props) => props.theme.black};
      background-color: ${(props) => props.theme.black};

      div {
            padding: 5px 16px;
            background-color: ${(props) => props.theme.white};
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
      }

      div:last-child {
            border-left: 2px solid ${(props) => props.theme.black};
            height: 34px;
            background-color: ${(props) => props.theme.black};
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;

            span {
                  color: ${(props) => props.theme.white};
            }
      }
`;

export const ProductListItems = styled.div`
      background: white;
      padding: 10px 20px;
      margin-bottom: 20px;
`;

export const ProductListTitle = styled.div`
      h2 {
            border-bottom: 1px dotted #000000;
            font-size: 20px;
            text-transform: uppercase;
            letter-spacing: 1px;
      }
`;

export const ProductListContent = styled.div`
      max-height: 286px;
      overflow: hidden auto;
      padding: 10px 0;
      overflow-x: hidden !important;

      &::-webkit-scrollbar {
            width: 6px;
      }

      &::-webkit-scrollbar-track {
            background: #f1f1f1;
            border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb {
            background: #888;
            border-radius: 10px;
            transition: background 0.3s ease-in-out;
      }

      &::-webkit-scrollbar-thumb:hover {
            background: #555;
      }
`;

export const CategoryFilterContainer = styled(Accordion)`
      padding-right: 10px;
`;

export const CategoryFilterHeader = styled(Accordion.Header)`
      button {
            padding: 0;
            background-color: transparent !important;
            box-shadow: none !important;
            font-weight: 400;
            font-size: 1rem;
            font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
            padding: 10px;
      }
`;

export const CategoryFilterBody = styled(Accordion.Body)`
      padding: 0;

      ul {
            padding-left: 16px;

            li {
                  border-bottom: 1px solid #dee2e6;
                  padding-bottom: 5px;
                  padding-top: 5px;
                  list-style-type: none;

                  label {
                        padding: 0 10px;
                  }

                  &:last-child {
                        border-bottom: 0 !important;
                  }
            }

            .accordion {
                  border-bottom: 1px solid #dee2e6 !important;

                  &:last-child {
                        border-bottom: 0 !important;
                  }
            }
      }
`;

export const CategoryListFilter = styled.ul`
      margin-top: 30px !important;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -webkit-box-align: baseline;
      -ms-flex-align: baseline;
      align-items: baseline;
      margin: 0;
      padding-left: 17px !important;
      grid-gap: 10px;

      li {
            list-style: none;
      }
`;

export const CategoryListItem = styled.div`
      position: relative;
      background-color: #fff;
      text-transform: capitalize;
      color: #3e4152;
      cursor: default;
      font-size: 14px;
      padding: 6px 36px 5px 20px;
      -webkit-transition: all 0.2s ease-out;
      transition: all 0.2s ease-out;
      border-radius: 20px;
      border: 1px solid #d4d5d9;
`;

export const CategoryListRemove = styled.label`
      position: absolute;
      top: 4px;
      right: 4px;
      width: 18px;
      height: 20px;
      z-index: 1;
      text-align: center;
      cursor: pointer;
      right: 10px;
      top: 4px;
`;

export const CategoryListRemoveIcon = styled.span`
      vertical-align: middle;
      opacity: 0.7;
      -webkit-transform: scale(0.7);
      transform: scale(0.7);

      i {
            font-size: 20px;
      }
`;

export const FilterContainer = styled.div`
      display: flex;
      margin-bottom: 2rem;
      justify-content: space-between;
      align-items: center;
      background: white;
      padding: 8px 12px;
`;
