import axios from 'axios';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import {
      CardHeader,
      CardLinks,
      CardTitle
} from '../../styles/components/common/Table';
import { LinkButton } from '../../styles/components/common/Button';
import FormSelect from '../../components/Form/FormSelect';
import FormButton from '../../components/Form/Button';

const BrandExport = () => {
      const [loading, setLoading] = useState(false);
      const [brands, setBrands] = useState([]);

      const [brand, setBrand] = useState('');
      const [loadingData, setLoadingData] = useState(false);

      const token = JSON.parse(localStorage.getItem('token'));

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/brands/products/create`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setBrands(response.data.brands);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      const handleForm = async (event) => {
            event.preventDefault();
            setLoadingData(true);
            const data = {
                  brand: brand
            };

            try {
                  const response = await axios.post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/brands/products/create`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              },
                              responseType: 'blob' // Set the response type to 'blob'
                        }
                  );

                  if (response.data instanceof Blob) {
                        // Create a blob URL for the response data
                        const blobUrl = window.URL.createObjectURL(
                              new Blob([response.data])
                        );

                        // Create a hidden anchor element to trigger the download
                        const a = document.createElement('a');
                        a.style.display = 'none';
                        a.href = blobUrl;
                        a.download = `${brand}-list.xlsx`; // Set the desired file name
                        document.body.appendChild(a);

                        // Trigger the download
                        a.click();

                        // Remove the anchor element
                        window.URL.revokeObjectURL(blobUrl);
                  }

                  toast.success('File downloaded successfully');
            } catch (error) {
                  toast.error(error.message);
            } finally {
                  setLoadingData(false);
            }
      };

      return (
            <>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader>
                                          <CardTitle>Export Products</CardTitle>
                                          <CardLinks>
                                                <LinkButton to="/dashboard/brands">
                                                      Back
                                                </LinkButton>
                                          </CardLinks>
                                    </CardHeader>
                                    <Card.Body>
                                          <Form
                                                className="row g-3"
                                                onSubmit={handleForm}
                                          >
                                                <FormSelect
                                                      selectNone={true}
                                                      classes={6}
                                                      setData={setBrand}
                                                      options={brands}
                                                      loading={loading}
                                                />
                                                <Col lg={12}>
                                                      <FormButton
                                                            loading={
                                                                  loadingData
                                                            }
                                                            title="Export"
                                                      />
                                                </Col>
                                          </Form>
                                    </Card.Body>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default BrandExport;
