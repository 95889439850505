import React, { useEffect, useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import {
      CardHeader,
      CardLinks,
      CardTitle
} from '../../styles/components/common/Table';

import { LinkButton } from '../../styles/components/common/Button';
import Input from '../../components/Form/Input';
import FormButton from '../../components/Form/Button';
import axios from 'axios';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import FormImage from '../../components/Form/image';

function CompanyEdit() {
      const { companyId } = useParams();
      const [name, setName] = useState('');
      const [website, setWebsite] = useState('');
      const [phoneNumber, setPhoneNumber] = useState('');
      const [address, setAddress] = useState('');
      const [email, setEmail] = useState('');
      const [panNumber, setPanNumber] = useState('');
      const [image, setImage] = useState('');
      const [imageUrl, setImageUrl] = useState('');
      const [loading, setLoading] = useState(false);

      const token = JSON.parse(localStorage.getItem('token'));

      const loadData = async () => {
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/shops/${companyId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                                    'Content-Type': 'multipart/form-data'
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setName(response.data.shop.name);
                              setPhoneNumber(response.data.shop.phone_number);
                              setAddress(response.data.shop.address);
                              setEmail(response.data.shop.email);
                              setPanNumber(response.data.shop.pan_number);
                              setWebsite(response.data.shop.website);
                              setImageUrl(response.data.shop.image);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };

      useEffect(() => {
            loadData();
      }, []);

      const handleSubmit = async (event) => {
            event.preventDefault();
            setLoading(true);

            const data = {
                  name: name,
                  website: website,
                  phone_number: phoneNumber,
                  address: address,
                  email: email,
                  pan_number: panNumber,
                  image: image
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/shops/${companyId}`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                                    'Content-Type': 'multipart/form-data'
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              toast.success(response.data.message);
                        }
                        if (response.data.result === 'error') {
                              if (response.data.message.name) {
                                    toast.error(response.data.message.name);
                              }
                              if (response.data.message.phone_number) {
                                    toast.error(
                                          response.data.message.phone_number
                                    );
                              }
                              if (response.data.message.address) {
                                    toast.error(response.data.message.address);
                              }
                              if (response.data.message.email) {
                                    toast.error(response.data.message.email);
                              }
                              if (response.data.message.website) {
                                    toast.error(response.data.message.website);
                              }
                              if (response.data.message.pan_number) {
                                    toast.error(
                                          response.data.message.pan_number
                                    );
                              }
                              if (response.data.message.image) {
                                    toast.error(response.data.message.image);
                              }
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });

            setLoading(false);
      };
      return (
            <>
                  <Form onSubmit={handleSubmit}>
                        <Row>
                              <Col lg={12}>
                                    <Card>
                                          <CardHeader>
                                                <CardTitle>
                                                      Create New Company
                                                </CardTitle>
                                                <CardLinks>
                                                      <LinkButton to="/dashboard/companies">
                                                            Back
                                                      </LinkButton>
                                                </CardLinks>
                                          </CardHeader>
                                          <Card.Body>
                                                <Row className="g-3">
                                                      <Input
                                                            title="Company Name"
                                                            required={true}
                                                            value={name}
                                                            handleChange={
                                                                  setName
                                                            }
                                                      />
                                                      <Input
                                                            title="Website"
                                                            required={true}
                                                            value={website}
                                                            handleChange={
                                                                  setWebsite
                                                            }
                                                      />
                                                      <Input
                                                            title="Email Address"
                                                            value={email}
                                                            handleChange={
                                                                  setEmail
                                                            }
                                                      />
                                                      <Input
                                                            title="Phone No."
                                                            value={phoneNumber}
                                                            required={true}
                                                            handleChange={
                                                                  setPhoneNumber
                                                            }
                                                      />
                                                      <Input
                                                            title="Address"
                                                            value={address}
                                                            handleChange={
                                                                  setAddress
                                                            }
                                                      />
                                                      <Input
                                                            title="PAN No."
                                                            value={panNumber}
                                                            handleChange={
                                                                  setPanNumber
                                                            }
                                                      />
                                                </Row>
                                          </Card.Body>
                                    </Card>

                                    <Card className="mt-4">
                                          <Card.Body>
                                                <Row className="g-3">
                                                      <FormImage
                                                            title="Logo Image"
                                                            setImage={
                                                                  setImage
                                                            }
                                                            setImageUrl={
                                                                  setImageUrl
                                                            }
                                                            required={true}
                                                            imageUrl={
                                                                  imageUrl
                                                            }
                                                      />
                                                </Row>
                                          </Card.Body>
                                    </Card>

                                    <Card className="mt-4 mb-5">
                                          <Card.Body>
                                                <Row>
                                                      <Col lg={12}>
                                                            <FormButton
                                                                  title="Submit"
                                                                  loading={
                                                                        loading
                                                                  }
                                                            />
                                                      </Col>
                                                </Row>
                                          </Card.Body>
                                    </Card>
                              </Col>
                        </Row>
                  </Form>
            </>
      );
}

export default CompanyEdit;
