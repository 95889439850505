import React, { useEffect, useState } from 'react';
import { Card, Col, ListGroup, Row } from 'react-bootstrap';
import {
      CardHeader,
      CardLinks,
      CardTitle
} from '../../styles/components/common/Table';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import ItemShow from '../../components/Form/ItemShow';
import { LinkButton } from '../../styles/components/common/Button';
import toast from 'react-hot-toast';
import { Loading } from '../../components/Loading';
import FormButton from '../../components/Form/Button';

const DealerShow = () => {
      const { dealerId } = useParams();
      const [dealer, setDealer] = useState([]);
      const [loadingData, setLoadingData] = useState(false);

      const token = JSON.parse(localStorage.getItem('token'));

      const loadCategories = async () => {
            setLoadingData(true);

            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/dealers/${dealerId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setDealer(response.data.dealer);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoadingData(false);
      };

      useEffect(() => {
            loadCategories();
      }, []);

      const [loadingReject, setLoadingReject] = useState(false);

      const handleReject = async () => {
            setLoadingReject(true);

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/dealer_requests/${dealerId}/reject`,
                        {},
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              toast.success(response.data.message);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });

            setLoadingReject(false);
      };

      return (
            <>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader>
                                          <CardTitle>
                                                Dealer Detail
                                          </CardTitle>
                                          <CardLinks>
                                                <LinkButton to="/dashboard/dealers">
                                                      Back
                                                </LinkButton>
                                          </CardLinks>
                                    </CardHeader>
                                    <Card.Body>
                                          {!loadingData ? (
                                                <Row>
                                                      <Col lg={6}>
                                                            <ListGroup>
                                                                  <ItemShow
                                                                        title="Full Name"
                                                                        value={
                                                                              dealer.name
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Company Name"
                                                                        value={
                                                                              dealer.company_name
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="PAN No"
                                                                        value={
                                                                              dealer.pan_no
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Phone No."
                                                                        value={
                                                                              dealer.phone_number
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Email"
                                                                        value={
                                                                              dealer.email
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Address"
                                                                        value={
                                                                              dealer.address
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Date"
                                                                        value={
                                                                              dealer.date
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Remarks"
                                                                        value={
                                                                              dealer.remarks
                                                                        }
                                                                  />
                                                            </ListGroup>

                                                            <FormButton
                                                                  title="Reject"
                                                                  type="button"
                                                                  handleButton={
                                                                        handleReject
                                                                  }
                                                                  classes="btn-danger me-3 mt-4"
                                                                  loading={
                                                                        loadingReject
                                                                  }
                                                            />
                                                      </Col>
                                                      {dealer.pan_file && (
                                                            <Col lg={6}>
                                                                  <img
                                                                        src={
                                                                              dealer.pan_file
                                                                        }
                                                                        alt=""
                                                                        className="w-100"
                                                                  />
                                                            </Col>
                                                      )}
                                                </Row>
                                          ) : (
                                                <>
                                                      <div
                                                            className="text-center d-flex align-items-center justify-content-center w-100"
                                                            style={{
                                                                  height: '30vh'
                                                            }}
                                                      >
                                                            <Loading />
                                                      </div>
                                                </>
                                          )}
                                    </Card.Body>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default DealerShow;
