import React, { useEffect, useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import {
      CardHeader,
      CardLinks,
      CardTitle
} from '../../styles/components/common/Table';
import { LinkButton } from '../../styles/components/common/Button';
import Input from '../../components/Form/Input';
import FormImage from '../../components/Form/image';

import $ from 'jquery';
import axios from 'axios';

import { toast } from 'react-hot-toast';
import FormButton from '../../components/Form/Button';
import { InputSelect } from '../../components/Form/Select';
import Textarea from '../../components/Form/Textarea';
import FormSelect from '../../components/Form/FormSelect';
import CollectionForm from './CollectionForm';

const options = [
      {
            title: 'Left Layout',
            image: '/images/left-image.png',
            value: 'Left Layout'
      },
      // {
      //       title: 'Slider Layout',
      //       image: '/images/slider-image.png',
      //       value: 'Slider Layout'
      // },
      {
            title: 'Right Layout',
            image: '/images/right-image.png',
            value: 'Right Layout'
      }
];

const ShowroomCollectionCreate = () => {
      const [showrooms, setShowrooms] = useState([]);
      const [loadingData, setLoadingData] = useState(false);

      const [loading, setLoading] = useState('');
      const [title, setTitle] = useState('');
      const [showroom, setShowroom] = useState('');
      const [image, setImage] = useState('');
      const [imageUrl, setImageUrl] = useState('');
      const [layout, setLayout] = useState(null);
      const [summary, setSummary] = useState('');
      const [status, setStatus] = useState('');

      const [collections, setCollections] = useState([
            { title: '', descrition: '', image: '', imageUrl: '' }
      ]);

      const token = JSON.parse(localStorage.getItem('token'));

      const loadData = async () => {
            setLoadingData(true);

            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/showroom_collections/showrooms/list`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setShowrooms(response.data.showrooms);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });

            setLoadingData(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      const handleSubmit = async (event) => {
            event.preventDefault();
            setLoading(true);

            const data = {
                  title: title,
                  summary: summary,
                  status: status,
                  image: image,
                  showroom: showroom,
                  layout: layout
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/showroom_collections`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                                    'Content-Type': 'multipart/form-data'
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              toast.success(response.data.message);
                              $('form').trigger('reset');
                              setImageUrl('');
                              setHintImage('');
                        }
                        if (response.data.result === 'error') {
                              if (response.data.message.title) {
                                    toast.error(response.data.message.title);
                              }
                              if (response.data.message.summary) {
                                    toast.error(response.data.message.summary);
                              }
                              if (response.data.message.banner_image) {
                                    toast.error(
                                          response.data.message.banner_image
                                    );
                              }
                              if (response.data.message.image) {
                                    toast.error(response.data.message.image);
                              }
                              if (response.data.message.layout) {
                                    toast.error(response.data.message.layout);
                              }
                              if (response.data.message.showroom) {
                                    toast.error(response.data.message.showroom);
                              }
                              if (response.data.message.status) {
                                    toast.error(response.data.message.status);
                              }
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      const [hintImage, setHintImage] = useState('');

      useEffect(() => {
            const data = options.find((item) => item.title === layout);
            if (data) {
                  setHintImage(data.image);
            }
      }, [layout]);

      return (
            <>
                  <Form onSubmit={handleSubmit}>
                        <Row>
                              <Col lg={12}>
                                    <Card>
                                          <CardHeader>
                                                <CardTitle>
                                                      Create new Collection
                                                </CardTitle>
                                                <CardLinks>
                                                      <LinkButton to="/dashboard/showroom_collections">
                                                            Back
                                                      </LinkButton>
                                                </CardLinks>
                                          </CardHeader>

                                          <Card.Body>
                                                <Row className="g-3">
                                                      <Input
                                                            title="Title"
                                                            classes={8}
                                                            required={true}
                                                            handleChange={
                                                                  setTitle
                                                            }
                                                      />
                                                      <FormSelect
                                                            title="Showroom"
                                                            required={true}
                                                            options={showrooms}
                                                            loading={
                                                                  loadingData
                                                            }
                                                            setData={
                                                                  setShowroom
                                                            }
                                                            classes={4}
                                                      />
                                                      <FormSelect
                                                            title="Layout"
                                                            required={true}
                                                            options={options}
                                                            classes={4}
                                                            loading={
                                                                  loadingData
                                                            }
                                                            setData={setLayout}
                                                      />
                                                      <InputSelect
                                                            title="Status"
                                                            classes={4}
                                                            setStatus={
                                                                  setStatus
                                                            }
                                                      />
                                                      {hintImage && (
                                                            <Col lg={12}>
                                                                  <div
                                                                        style={{
                                                                              height: '220px'
                                                                        }}
                                                                        className="text-start align-items-start d-flex justify-content-start"
                                                                  >
                                                                        <img
                                                                              src={
                                                                                    hintImage
                                                                              }
                                                                              alt=""
                                                                              style={{
                                                                                    objectFit:
                                                                                          'contain'
                                                                              }}
                                                                              className=" h-100"
                                                                        />
                                                                  </div>
                                                                  <div className="text-muted">
                                                                        Hint
                                                                        Image
                                                                  </div>
                                                            </Col>
                                                      )}
                                                </Row>
                                          </Card.Body>
                                    </Card>

                                    <Card className="mt-4">
                                          <Card.Body>
                                                <Row className="g-3">
                                                      <Textarea
                                                            required={true}
                                                            title="Summary"
                                                            handleChange={
                                                                  setSummary
                                                            }
                                                      />
                                                </Row>
                                          </Card.Body>
                                    </Card>

                                    <Card className="mt-4">
                                          <Card.Body>
                                                <Row className="g-3">
                                                      <FormImage
                                                            title="Featured Image"
                                                            setImage={setImage}
                                                            setImageUrl={
                                                                  setImageUrl
                                                            }
                                                            required={true}
                                                            imageUrl={imageUrl}
                                                      />
                                                </Row>
                                          </Card.Body>
                                    </Card>
                                    {/* <CollectionForm
                                          collections={collections}
                                          setCollections={setCollections}
                                    /> */}

                                    <Card className="mt-4 mb-5">
                                          <Card.Body>
                                                <Row>
                                                      <Col lg={12}>
                                                            <FormButton
                                                                  title="Submit"
                                                                  loading={
                                                                        loading
                                                                  }
                                                            />
                                                      </Col>
                                                </Row>
                                          </Card.Body>
                                    </Card>
                              </Col>
                        </Row>
                  </Form>
            </>
      );
};

export default ShowroomCollectionCreate;
