import React from 'react';
import { Card, Col, Image, ListGroup, Modal, Row } from 'react-bootstrap';
import {
      CardHeader,
      CardLinks,
      CardTitle
} from '../../styles/components/common/Table';
import { LinkButton } from '../../styles/components/common/Button';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import ItemShow from '../../components/Form/ItemShow';
import { Loading } from '../../components/Loading';

const ShowroomShow = () => {
      const { showroomId } = useParams();

      const [loading, setLoading] = useState(false);
      const [showroom, setShowroom] = useState([]);

      const token = JSON.parse(localStorage.getItem('token'));

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/showrooms/${showroomId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setShowroom(response.data.showroom);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <Row className="g-4">
                        <Col lg={12}>
                              <Card>
                                    <CardHeader>
                                          <CardTitle>
                                                Showroom Details
                                          </CardTitle>
                                          <CardLinks>
                                                <LinkButton to="/dashboard/showrooms">
                                                      Back
                                                </LinkButton>
                                          </CardLinks>
                                    </CardHeader>
                                    <Card.Body>
                                          {!loading ? (
                                                <Row>
                                                      <Col lg={6}>
                                                            <ListGroup>
                                                                  <ItemShow
                                                                        title=" Title"
                                                                        value={
                                                                              showroom.title
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Email"
                                                                        value={
                                                                              showroom.email
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Phone Number"
                                                                        value={
                                                                              showroom.phone_number
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Address"
                                                                        value={`${showroom.address}`}
                                                                  />
                                                                  <ItemShow
                                                                        title="Created on"
                                                                        value={
                                                                              showroom.date
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Created by"
                                                                        value={`${showroom.user_name}`}
                                                                  />
                                                                  <ItemShow
                                                                        title="Seo Title"
                                                                        value={
                                                                              showroom.seo_title
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Seo Keyword"
                                                                        value={
                                                                              showroom.seo_keyword
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Seo Description"
                                                                        value={
                                                                              showroom.seo_description
                                                                        }
                                                                  />
                                                            </ListGroup>
                                                      </Col>
                                                      <Col lg={6}>
                                                            <div className="d-flex gap-3">
                                                                  <div>
                                                                        <Image
                                                                              src={
                                                                                    showroom.image
                                                                              }
                                                                              alt={
                                                                                    showroom.title
                                                                              }
                                                                              style={{
                                                                                    width: '220px',
                                                                                    height: '220px',
                                                                                    objectFit:
                                                                                          'contain'
                                                                              }}
                                                                        />
                                                                        <div>
                                                                              Feature
                                                                              Image
                                                                        </div>
                                                                  </div>
                                                                  {showroom.banner_image && (
                                                                        <>
                                                                              <div>
                                                                                    <Image
                                                                                          src={
                                                                                                showroom.banner_image
                                                                                          }
                                                                                          alt={
                                                                                                showroom.title
                                                                                          }
                                                                                          style={{
                                                                                                width: '220px',
                                                                                                height: '220px',
                                                                                                objectFit:
                                                                                                      'contain'
                                                                                          }}
                                                                                    />
                                                                                    <div>
                                                                                          Banner
                                                                                          Image
                                                                                    </div>
                                                                              </div>
                                                                        </>
                                                                  )}
                                                            </div>
                                                            <div className="mt-3">
                                                                  <div
                                                                        dangerouslySetInnerHTML={{
                                                                              __html: showroom.summary
                                                                        }}
                                                                  ></div>
                                                            </div>
                                                      </Col>
                                                </Row>
                                          ) : (
                                                <>
                                                      <div
                                                            className="text-center d-flex align-items-center justify-content-center w-100"
                                                            style={{
                                                                  height: '30vh'
                                                            }}
                                                      >
                                                            <Loading />
                                                      </div>
                                                </>
                                          )}
                                    </Card.Body>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default ShowroomShow;
