import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Table } from 'react-bootstrap';
import {
      CardHeader,
      CardLinks,
      CardTitle
} from '../../styles/components/common/Table';
import TableSearch from '../../components/Form/Search';
import axios from 'axios';
import Progress from 'react-progress-2';
import { toast } from 'react-hot-toast';
import { LinkButton } from '../../styles/components/common/Button';
import ItemStatus from '../../components/Form/Status';
import { Link } from 'react-router-dom';
import { DeleteIcon, EditIcon, ShowIcon } from '../../components/icons';
import TableFooter from '../../components/Form/TableFooter';
import ProductImage from '../../components/Form/ProductImage';

const TestimonialList = () => {
      const [loading, setLoading] = useState(false);
      const [testimonials, setTestimonials] = useState([]);
      const [totalTestimonials, setTotalTestimonials] = useState(0);
      const [perPages, setPerPages] = useState(0);
      const [currentPage, setCurrentPage] = useState(0);

      const token = JSON.parse(localStorage.getItem('token'));
      const [limit, setLimit] = useState(10);
      const [search, setSearch] = useState('');

      const loadData = async () => {
            setLoading(true);
            await axios(
                  `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/testimonials?limit=${limit}&search=${search}`,
                  {
                        headers: {
                              Authorization: `Bearer ${token}`
                        }
                  }
            )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setTestimonials(response.data.testimonials);
                              setTotalTestimonials(
                                    response.data.totalTestimonials
                              );
                              setPerPages(response.data.itemsCountPerPage);
                              setCurrentPage(response.data.currentPage);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, [limit, search]);

      async function getData(pageNumber = 1) {
            window.scrollTo(0, 0);
            setLoading(true);
            Progress.show();
            const url = `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/testimonials?page=${pageNumber}&search=${search}`;
            await axios(url, {
                  headers: {
                        Authorization: `Bearer ${token}`
                  }
            })
                  .then((response) => {
                        setTestimonials(response.data.testimonials);
                        setTotalTestimonials(response.data.totalTestimonials);
                        setPerPages(response.data.itemsCountPerPage);
                        setCurrentPage(response.data.currentPage);
                        Progress.hide();
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      }

      const handleDelete = async (testimonial) => {
            setLoading(true);
            await axios
                  .delete(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/testimonials/${testimonial.slug}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setTestimonials([]);
                              loadData();
                              toast.success(response.data.message);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      const handleStatus = async (testimonial) => {
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/testimonials/${testimonial.slug}/status`,
                        {},
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              toast.success(response.data.message);
                        }
                        if (response.data.result === 'error') {
                              toast.error(response.data.message);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };

      return (
            <>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader>
                                          <CardTitle>
                                                Testimonial List
                                          </CardTitle>
                                          <CardLinks>
                                                <LinkButton to="/dashboard/testimonials/create">
                                                      Add New
                                                </LinkButton>
                                          </CardLinks>
                                    </CardHeader>
                                    <Card.Body>
                                          <TableSearch
                                                setLimit={setLimit}
                                                setSearch={setSearch}
                                          />
                                          <Table hover bordered>
                                                <thead>
                                                      <tr>
                                                            <th
                                                                  className="text-center"
                                                                  width="80px"
                                                            >
                                                                  #
                                                            </th>
                                                            <th>Title</th>
                                                            <th width="220px">
                                                                  Company Name
                                                            </th>
                                                            <th width="120px">
                                                                  Status
                                                            </th>
                                                            <th width="120px">
                                                                  Action
                                                            </th>
                                                      </tr>
                                                </thead>
                                                <tbody>
                                                      {!loading ? (
                                                            testimonials.length >
                                                            0 ? (
                                                                  testimonials.map(
                                                                        (
                                                                              testimonial,
                                                                              index
                                                                        ) => (
                                                                              <>
                                                                                    <tr
                                                                                          key={
                                                                                                index
                                                                                          }
                                                                                    >
                                                                                          <td className="text-center">
                                                                                                {
                                                                                                      testimonial.index
                                                                                                }
                                                                                          </td>
                                                                                          <td>
                                                                                                <ProductImage
                                                                                                      title={
                                                                                                            testimonial.name
                                                                                                      }
                                                                                                      image={
                                                                                                            testimonial.image
                                                                                                      }
                                                                                                      category={
                                                                                                            testimonial.position
                                                                                                      }
                                                                                                />
                                                                                          </td>
                                                                                          <td>
                                                                                                {
                                                                                                      testimonial.company
                                                                                                }
                                                                                          </td>
                                                                                          <td>
                                                                                                <ItemStatus
                                                                                                      item={
                                                                                                            testimonial
                                                                                                      }
                                                                                                      handleStatus={
                                                                                                            handleStatus
                                                                                                      }
                                                                                                />
                                                                                          </td>
                                                                                          <td className="text-center">
                                                                                                <Link
                                                                                                      to={`/dashboard/testimonials/${testimonial.slug}/edit`}
                                                                                                      className="text-secondary"
                                                                                                >
                                                                                                      <EditIcon />
                                                                                                </Link>
                                                                                                <Link
                                                                                                      to={`/dashboard/testimonials/${testimonial.slug}/show`}
                                                                                                      className="text-success mx-2"
                                                                                                >
                                                                                                      <ShowIcon />
                                                                                                </Link>
                                                                                                <Link
                                                                                                      onClick={() =>
                                                                                                            handleDelete(
                                                                                                                  testimonial,
                                                                                                                  index
                                                                                                            )
                                                                                                      }
                                                                                                      className="text-danger mx-2"
                                                                                                >
                                                                                                      <DeleteIcon />
                                                                                                </Link>
                                                                                          </td>
                                                                                    </tr>
                                                                              </>
                                                                        )
                                                                  )
                                                            ) : (
                                                                  <>
                                                                        <tr>
                                                                              <td
                                                                                    colSpan="5"
                                                                                    className="text-center"
                                                                              >
                                                                                    No
                                                                                    Testimonial
                                                                                    Found.
                                                                              </td>
                                                                        </tr>
                                                                  </>
                                                            )
                                                      ) : (
                                                            <>
                                                                  <tr>
                                                                        <td
                                                                              colSpan="5"
                                                                              className="text-center"
                                                                        >
                                                                              Loading...
                                                                        </td>
                                                                  </tr>
                                                            </>
                                                      )}
                                                </tbody>
                                          </Table>
                                          <TableFooter
                                                perPages={perPages}
                                                totalItems={totalTestimonials}
                                                currentPage={currentPage}
                                                getData={getData}
                                          />
                                    </Card.Body>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default TestimonialList;
