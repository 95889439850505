import React, { useEffect, useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import {
      CardHeader,
      CardLinks,
      CardTitle
} from '../../styles/components/common/Table';
import { LinkButton } from '../../styles/components/common/Button';

import Description from '../../components/Form/Description';
import Select from '../../components/Form/Select';
import Input from '../../components/Form/Input';
import FormImage from '../../components/Form/image';
import FormSeo from '../../components/commons/FormSeo';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import FormButton from '../../components/Form/Button';
import { useNavigate, useParams } from 'react-router-dom';

const BrandEdit = () => {
      const { brandId } = useParams();

      const [brand, setBrand] = useState([]);
      const [brandName, setBrandName] = useState(null);
      const [brandSlug, setBrandSlug] = useState(null);
      const [brandImage, setBrandImage] = useState('');
      const [brandImageUrl, setBrandImageUrl] = useState(null);
      const [website, setWebsite] = useState(null);
      const [brandBanner, setBrandBanner] = useState('');
      const [brandBannerUrl, setBrandBannerUrl] = useState(null);
      const [status, setStatus] = useState(null);
      const [description, setDescription] = useState(null);
      const [seoTitle, setSeoTitle] = useState(null);
      const [seoKeyword, setSeoKeyword] = useState(null);
      const [seoDescription, setSeoDescription] = useState(null);
      const [loading, setLoading] = useState(false);

      const token = JSON.parse(localStorage.getItem('token'));
      const navigate = useNavigate();

      const loadData = async () => {
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/brands/${brandId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setBrand(response.data.brand);
                              setBrandName(response.data.brand.title);
                              setBrandSlug(response.data.brand.slug);
                              setBrandImageUrl(response.data.brand.image);
                              setBrandBannerUrl(
                                    response.data.brand.brand_banner
                              );
                              setWebsite(response.data.brand.website);
                              setStatus(response.data.brand.status);
                              setSeoTitle(response.data.brand.seo_title);
                              setSeoKeyword(response.data.brand.seo_keyword);
                              setDescription(response.data.brand.description);
                              setSeoDescription(
                                    response.data.brand.seo_description
                              );
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };
      useEffect(() => {
            loadData();
      }, []);

      const handleSubmit = async (event) => {
            event.preventDefault();
            setLoading(true);
            const data = {
                  title: brandName,
                  slug: brandSlug,
                  image: brandImage,
                  website: website,
                  brand_banner: brandBanner,
                  status: status,
                  description: description,
                  seo_title: seoTitle,
                  seo_keyword: seoKeyword,
                  seo_description: seoDescription
            };

            let updatedSlug = false;

            const handleUpdateSlug = () => {
                  if (updatedSlug) {
                        navigate(`/dashboard/brands/${updatedSlug}/edit`);
                  }
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/brands/${brandId}`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                                    'Content-Type': 'multipart/form-data'
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              toast.success(response.data.message);
                              updatedSlug = brandSlug;
                              handleUpdateSlug();
                        }
                        if (response.data.result === 'error') {
                              if (response.data.message.title) {
                                    toast.error(response.data.message.title);
                              }
                              if (response.data.message.image) {
                                    toast.error(response.data.message.image);
                              }
                              if (response.data.message.status) {
                                    toast.error(response.data.message.status);
                              }
                              if (response.data.message.slug) {
                                    toast.error(response.data.message.slug);
                              }
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      return (
            <>
                  <Form onSubmit={handleSubmit}>
                        <Row>
                              <Col lg={12}>
                                    <Card>
                                          <CardHeader>
                                                <CardTitle>
                                                      Create New Brand
                                                </CardTitle>
                                                <CardLinks>
                                                      <LinkButton to="/dashboard/brands">
                                                            Back
                                                      </LinkButton>
                                                </CardLinks>
                                          </CardHeader>
                                          <Card.Body>
                                                <Row className="g-3">
                                                      <Input
                                                            value={brand.title}
                                                            title="Brand Name"
                                                            required={true}
                                                            handleChange={
                                                                  setBrandName
                                                            }
                                                            classes={8}
                                                      />
                                                      <Input
                                                            value={brandSlug}
                                                            title="Brand Slug"
                                                            required={true}
                                                            handleChange={
                                                                  setBrandSlug
                                                            }
                                                            classes={4}
                                                      />
                                                      <Input
                                                            value={website}
                                                            title="Brand Website"
                                                            handleChange={
                                                                  setWebsite
                                                            }
                                                            classes={4}
                                                      />
                                                      <Select
                                                            selected={status}
                                                            title="Status"
                                                            setStatus={
                                                                  setStatus
                                                            }
                                                            classes={4}
                                                      />
                                                      <Description
                                                            description={
                                                                  description
                                                            }
                                                            title="Description"
                                                            setDescription={
                                                                  setDescription
                                                            }
                                                      />
                                                </Row>
                                          </Card.Body>
                                    </Card>
                                    <Card className="mt-4">
                                          <Card.Body>
                                                <Row className="g-3">
                                                      <FormImage
                                                            title="Brand Image"
                                                            setImage={
                                                                  setBrandImage
                                                            }
                                                            setImageUrl={
                                                                  setBrandImageUrl
                                                            }
                                                            required={true}
                                                            imageUrl={
                                                                  brandImageUrl
                                                            }
                                                      />
                                                      <FormImage
                                                            title="Brand Banner"
                                                            setImage={
                                                                  setBrandBanner
                                                            }
                                                            setImageUrl={
                                                                  setBrandBannerUrl
                                                            }
                                                            imageUrl={
                                                                  brandBannerUrl
                                                            }
                                                      />
                                                </Row>
                                          </Card.Body>
                                    </Card>
                                    <FormSeo
                                          setSeoDescription={setSeoDescription}
                                          setSeoTitle={setSeoTitle}
                                          setSeoKeyword={setSeoKeyword}
                                    />

                                    <Card className="mt-4 mb-5">
                                          <Card.Body>
                                                <Row>
                                                      <Col lg={12}>
                                                            <FormButton
                                                                  title="Update"
                                                                  loading={
                                                                        loading
                                                                  }
                                                            />
                                                      </Col>
                                                </Row>
                                          </Card.Body>
                                    </Card>
                              </Col>
                        </Row>
                  </Form>
            </>
      );
};

export default BrandEdit;
