import React, { useEffect, useState } from 'react';
import { Card, Col, ListGroup, Row } from 'react-bootstrap';
import {
      CardHeader,
      CardLinks,
      CardTitle
} from '../../../styles/components/common/Table';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import ItemShow from '../../../components/Form/ItemShow';
import { LinkButton } from '../../../styles/components/common/Button';
import toast from 'react-hot-toast';
import { Loading } from '../../../components/Loading';

const CustomerEnquiryShow = () => {
      const { customerEnquiryId } = useParams();
      const [enquiry, setEnquiry] = useState([]);
      const [loadingData, setLoadingData] = useState(false);

      const token = JSON.parse(localStorage.getItem('token'));

      const loadCategories = async () => {
            setLoadingData(true);

            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/customer_enquiries/${customerEnquiryId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setEnquiry(response.data.enquiry);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoadingData(false);
      };

      useEffect(() => {
            loadCategories();
      }, []);

      return (
            <>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader>
                                          <CardTitle>
                                                Customer Enquiry Detail
                                          </CardTitle>
                                          <CardLinks>
                                                <LinkButton to="/dashboard/customer-enquiries">
                                                      Back
                                                </LinkButton>
                                          </CardLinks>
                                    </CardHeader>
                                    <Card.Body>
                                          {!loadingData ? (
                                                <Row>
                                                      <Col lg={6}>
                                                            <ListGroup>
                                                                  <ItemShow
                                                                        title="Full Name"
                                                                        value={
                                                                              enquiry.name
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Email"
                                                                        value={
                                                                              enquiry.email
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Phone"
                                                                        value={
                                                                              enquiry.phone
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Address"
                                                                        value={
                                                                              enquiry.address
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Date"
                                                                        value={
                                                                              enquiry.date
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Message"
                                                                        value={
                                                                              enquiry.message
                                                                        }
                                                                  />
                                                            </ListGroup>
                                                      </Col>
                                                </Row>
                                          ) : (
                                                <>
                                                      <div
                                                            className="text-center d-flex align-items-center justify-content-center w-100"
                                                            style={{
                                                                  height: '30vh'
                                                            }}
                                                      >
                                                            <Loading />
                                                      </div>
                                                </>
                                          )}
                                    </Card.Body>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default CustomerEnquiryShow;
