import React from 'react';
import CheckMultiplePermission from '../roles/CheckMultiplePermission';
import NavSeparator from '../Navigation/NavSeparator';
import {
      MetisMenuIcon,
      MetisMenuLink,
      MetisMenuTitle
} from '../../styles/layouts/navigation';
import {
      CareerIcon,
      CheckListIcon,
      ComplaintIcon,
      ElementIcon
} from '../icons';
import { NavLink } from 'react-router-dom';

const WebsiteManage = ({ userPermissions }) => {
      return (
            <>
                  <CheckMultiplePermission
                        userPermissions={userPermissions}
                        permissions={[
                              'banner-list',
                              'banner-edit',
                              'banner-create',
                              'banner-show',
                              'plugin-list',
                              'plugin-create',
                              'plugin-edit',
                              'plugin-show',
                              'testimonial-list',
                              'testimonial-create',
                              'testimonial-edit',
                              'testimonial-show',
                              'job-list',
                              'job-edit',
                              'job-create',
                              'job-show',
                              'complain-list',
                              'complain-show',
                              'complain-type-list',
                              'complain-type-show',
                              'complain-type-edit',
                              'complain-type-create',
                              'candidate-list',
                              'candidate-show',
                              'subscriber-list',
                              'contact-request-list'
                        ]}
                  >
                        <NavSeparator title="Website Manage" />
                        <CheckMultiplePermission
                              userPermissions={userPermissions}
                              permissions={[
                                    'banner-list',
                                    'banner-edit',
                                    'banner-create',
                                    'banner-show',
                                    'plugin-list',
                                    'plugin-create',
                                    'plugin-edit',
                                    'plugin-show',
                                    'testimonial-list',
                                    'testimonial-create',
                                    'testimonial-edit',
                                    'testimonial-show'
                              ]}
                        >
                              <li>
                                    <MetisMenuLink
                                          className="has-arrow"
                                          href="#"
                                    >
                                          <MetisMenuIcon>
                                                <ElementIcon />
                                          </MetisMenuIcon>
                                          <MetisMenuTitle>
                                                <span>Element Manage</span>
                                          </MetisMenuTitle>
                                    </MetisMenuLink>
                                    <ul>
                                          <CheckMultiplePermission
                                                userPermissions={
                                                      userPermissions
                                                }
                                                permissions={[
                                                      'banner-list',
                                                      'banner-edit',
                                                      'banner-create',
                                                      'banner-show'
                                                ]}
                                          >
                                                <li>
                                                      <NavLink to="/dashboard/banners">
                                                            Banner
                                                      </NavLink>
                                                </li>
                                          </CheckMultiplePermission>
                                          <CheckMultiplePermission
                                                userPermissions={
                                                      userPermissions
                                                }
                                                permissions={[
                                                      'plugin-list',
                                                      'plugin-create',
                                                      'plugin-edit',
                                                      'plugin-show'
                                                ]}
                                          >
                                                <li>
                                                      <NavLink to="/dashboard/plugins">
                                                            Plugins
                                                      </NavLink>
                                                </li>
                                          </CheckMultiplePermission>
                                          <CheckMultiplePermission
                                                userPermissions={
                                                      userPermissions
                                                }
                                                permissions={[
                                                      'testimonial-list',
                                                      'testimonial-create',
                                                      'testimonial-edit',
                                                      'testimonial-show'
                                                ]}
                                          >
                                                <li>
                                                      <NavLink to="/dashboard/testimonials">
                                                            Testimonial
                                                      </NavLink>
                                                </li>
                                          </CheckMultiplePermission>
                                    </ul>
                              </li>
                        </CheckMultiplePermission>
                        <CheckMultiplePermission
                              userPermissions={userPermissions}
                              permissions={[
                                    'job-list',
                                    'job-edit',
                                    'job-create',
                                    'job-show',
                                    'candidate-list',
                                    'candidate-show'
                              ]}
                        >
                              <li>
                                    <MetisMenuLink
                                          className="has-arrow"
                                          href="#"
                                    >
                                          <MetisMenuIcon>
                                                <CareerIcon />
                                          </MetisMenuIcon>
                                          <MetisMenuTitle>
                                                <span>Career Manage</span>
                                          </MetisMenuTitle>
                                    </MetisMenuLink>
                                    <ul>
                                          <CheckMultiplePermission
                                                userPermissions={
                                                      userPermissions
                                                }
                                                permissions={[
                                                      'job-list',
                                                      'job-create',
                                                      'job-edit',
                                                      'job-show'
                                                ]}
                                          >
                                                <li>
                                                      <NavLink to="/dashboard/jobs">
                                                            All Job
                                                      </NavLink>
                                                </li>
                                          </CheckMultiplePermission>
                                          <CheckMultiplePermission
                                                userPermissions={
                                                      userPermissions
                                                }
                                                permissions={[
                                                      'candidate-list',
                                                      'candidate-show'
                                                ]}
                                          >
                                                <li>
                                                      <NavLink to="/dashboard/candidates">
                                                            Candidate List
                                                      </NavLink>
                                                </li>
                                          </CheckMultiplePermission>
                                    </ul>
                              </li>
                        </CheckMultiplePermission>

                        <CheckMultiplePermission
                              userPermissions={userPermissions}
                              permissions={[
                                    'complain-list',
                                    'complain-show',
                                    'complain-type-list',
                                    'complain-type-show',
                                    'complain-type-edit',
                                    'complain-type-create'
                              ]}
                        >
                              <li>
                                    <MetisMenuLink
                                          className="has-arrow"
                                          href="#"
                                    >
                                          <MetisMenuIcon>
                                                <ComplaintIcon />
                                          </MetisMenuIcon>
                                          <MetisMenuTitle>
                                                <span>Complaint Manage</span>
                                          </MetisMenuTitle>
                                    </MetisMenuLink>
                                    <ul>
                                          <CheckMultiplePermission
                                                userPermissions={
                                                      userPermissions
                                                }
                                                permissions={[
                                                      'complain-list',
                                                      'complain-show'
                                                ]}
                                          >
                                                <li>
                                                      <NavLink to="/dashboard/complains">
                                                            All Complaint
                                                      </NavLink>
                                                </li>
                                          </CheckMultiplePermission>

                                          <CheckMultiplePermission
                                                userPermissions={
                                                      userPermissions
                                                }
                                                permissions={[
                                                      'complain-type-list',
                                                      'complain-type-show',
                                                      'complain-type-edit',
                                                      'complain-type-create'
                                                ]}
                                          >
                                                <li>
                                                      <NavLink to="/dashboard/complains/types">
                                                            Complaint Type
                                                      </NavLink>
                                                </li>
                                          </CheckMultiplePermission>
                                    </ul>
                              </li>
                        </CheckMultiplePermission>

                        <CheckMultiplePermission
                              userPermissions={userPermissions}
                              permissions={[
                                    'subscriber-list',
                                    'contact-request-list'
                              ]}
                        >
                              <li>
                                    <MetisMenuLink
                                          className="has-arrow"
                                          href="#"
                                    >
                                          <MetisMenuIcon>
                                                <CheckListIcon />
                                          </MetisMenuIcon>
                                          <MetisMenuTitle>
                                                <span>Frontend Form</span>
                                          </MetisMenuTitle>
                                    </MetisMenuLink>
                                    <ul>
                                          <CheckMultiplePermission
                                                userPermissions={
                                                      userPermissions
                                                }
                                                permissions={[
                                                      'subscriber-list'
                                                ]}
                                          >
                                                <li>
                                                      <NavLink to="/dashboard/subscribers">
                                                            Subscriber List
                                                      </NavLink>
                                                </li>
                                          </CheckMultiplePermission>

                                          <CheckMultiplePermission
                                                userPermissions={
                                                      userPermissions
                                                }
                                                permissions={[
                                                      'contact-request-list'
                                                ]}
                                          >
                                                <li>
                                                      <NavLink to="/dashboard/contact_requests">
                                                            Contact Request
                                                      </NavLink>
                                                </li>
                                          </CheckMultiplePermission>
                                    </ul>
                              </li>
                        </CheckMultiplePermission>
                  </CheckMultiplePermission>
            </>
      );
};

export default WebsiteManage;
