import React from 'react';
import { FormCheck } from 'react-bootstrap';

const ItemStatus = ({ item, handleStatus, type }) => {
      return (
            <>
                  <FormCheck
                        type="switch"
                        id="custom-switch"
                        defaultChecked={
                              type
                                    ? item.menu_status
                                    : item.status === 1
                                    ? true
                                    : false
                        }
                        onClick={() => handleStatus(item)}
                  />
            </>
      );
};

export default ItemStatus;
