import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Table } from 'react-bootstrap';
import {
      CardHeader,
      CardLinks,
      CardTitle
} from '../../styles/components/common/Table';
import TableSearch from '../../components/Form/Search';
import axios from 'axios';
import Progress from 'react-progress-2';
import { toast } from 'react-hot-toast';
import { LinkButton } from '../../styles/components/common/Button';
import ItemStatus from '../../components/Form/Status';
import { Link } from 'react-router-dom';
import { EditIcon, ShowIcon } from '../../components/icons';
import TableFooter from '../../components/Form/TableFooter';
import ProductImage from '../../components/Form/ProductImage';
import DeleteButton from '../../components/commons/DeleteButton';

const FaqList = () => {
      const [loading, setLoading] = useState(false);
      const [faqs, setFaqs] = useState([]);
      const [totalFaqs, setTotalFaqs] = useState(0);
      const [perPages, setPerPages] = useState(0);
      const [currentPage, setCurrentPage] = useState(0);

      const token = JSON.parse(localStorage.getItem('token'));
      const [limit, setLimit] = useState(10);
      const [search, setSearch] = useState('');

      const loadData = async () => {
            setLoading(true);
            await axios(
                  `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/faqs?limit=${limit}&search=${search}`,
                  {
                        headers: {
                              Authorization: `Bearer ${token}`
                        }
                  }
            )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setFaqs(response.data.faqs);
                              setTotalFaqs(response.data.totalFaqs);
                              setPerPages(response.data.itemsCountPerPage);
                              setCurrentPage(response.data.currentPage);
                        }
                        setLoading(false);
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };
      useEffect(() => {
            loadData();
      }, [limit, search]);

      async function getFaqData(pageNumber = 1) {
            window.screenTop(0, 0);
            Progress.show();
            const url = `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/faqs?page=${pageNumber}&limit=${limit}&search=${search}`;
            await axios(url, {
                  headers: {
                        Authorization: `Bearer ${token}`
                  }
            })
                  .then((response) => {
                        setFaqs(response.data.faqs);
                        setTotalFaqs(response.data.totalFaqs);
                        setPerPages(response.data.itemsCountPerPage);
                        setCurrentPage(response.data.currentPage);
                        Progress.hide();
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      }

      const handleDelete = async (faq) => {
            setLoading(true);
            await axios
                  .delete(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/faqs/${faq.slug}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setFaqs([]);
                              loadData();
                              toast.success(response.data.message);
                        }
                        setLoading(false);
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };

      const handleStatus = async (faq) => {
            const data = {
                  slug: faq.slug
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/faqs/status/update`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              toast.success(response.data.message);
                        }
                        if (response.data.result === 'error') {
                              toast.error(response.data.message);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };

      return (
            <>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader>
                                          <CardTitle>Faq List</CardTitle>
                                          <CardLinks>
                                                <LinkButton to="/dashboard/faqs/create">
                                                      Add New
                                                </LinkButton>
                                          </CardLinks>
                                    </CardHeader>
                                    <Card.Body>
                                          <TableSearch
                                                setLimit={setLimit}
                                                setSearch={setSearch}
                                          />
                                          <Table hover bordered>
                                                <thead>
                                                      <tr>
                                                            <th
                                                                  className="text-center"
                                                                  width="80px"
                                                            >
                                                                  #
                                                            </th>
                                                            <th>Question</th>
                                                            <th width="120px">
                                                                  Status
                                                            </th>
                                                            <th width="120px">
                                                                  Action
                                                            </th>
                                                      </tr>
                                                </thead>
                                                <tbody>
                                                      {!loading ? (
                                                            faqs.length > 0 ? (
                                                                  faqs.map(
                                                                        (
                                                                              faq,
                                                                              index
                                                                        ) => (
                                                                              <>
                                                                                    <tr
                                                                                          key={
                                                                                                index
                                                                                          }
                                                                                    >
                                                                                          <td className="text-center">
                                                                                                {
                                                                                                      faq.index
                                                                                                }
                                                                                          </td>
                                                                                          <td>
                                                                                                <ProductImage
                                                                                                      title={
                                                                                                            faq.question
                                                                                                      }
                                                                                                      category={
                                                                                                            faq.category
                                                                                                      }
                                                                                                />
                                                                                          </td>
                                                                                          <td>
                                                                                                <ItemStatus
                                                                                                      item={
                                                                                                            faq
                                                                                                      }
                                                                                                      handleStatus={
                                                                                                            handleStatus
                                                                                                      }
                                                                                                />
                                                                                          </td>
                                                                                          <td className="text-center">
                                                                                                <Link
                                                                                                      to={`/dashboard/faqs/${faq.slug}/edit`}
                                                                                                      className="text-secondary"
                                                                                                >
                                                                                                      <EditIcon />
                                                                                                </Link>
                                                                                                <Link
                                                                                                      to={`/dashboard/faqs/${faq.slug}/show`}
                                                                                                      className="text-success mx-2"
                                                                                                >
                                                                                                      <ShowIcon />
                                                                                                </Link>
                                                                                                <DeleteButton
                                                                                                      item={
                                                                                                            faq
                                                                                                      }
                                                                                                      handleDelete={
                                                                                                            handleDelete
                                                                                                      }
                                                                                                      loading={
                                                                                                            loading
                                                                                                      }
                                                                                                      setLoading={
                                                                                                            setLoading
                                                                                                      }
                                                                                                />
                                                                                          </td>
                                                                                    </tr>
                                                                              </>
                                                                        )
                                                                  )
                                                            ) : (
                                                                  <>
                                                                        <tr>
                                                                              <td
                                                                                    colSpan="5"
                                                                                    className="text-center"
                                                                              >
                                                                                    No
                                                                                    Faq
                                                                                    Found.
                                                                              </td>
                                                                        </tr>
                                                                  </>
                                                            )
                                                      ) : (
                                                            <>
                                                                  <tr>
                                                                        <td
                                                                              colSpan="5"
                                                                              className="text-center"
                                                                        >
                                                                              Loading...
                                                                        </td>
                                                                  </tr>
                                                            </>
                                                      )}
                                                </tbody>
                                          </Table>
                                          <TableFooter
                                                perPages={perPages}
                                                totalItems={totalFaqs}
                                                currentPage={currentPage}
                                                getData={getFaqData}
                                          />
                                    </Card.Body>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default FaqList;
