import React, { useState, useEffect } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import {
      CardHeader,
      CardLinks,
      CardTitle
} from '../../styles/components/common/Table';
import { LinkButton } from '../../styles/components/common/Button';
import Input from '../../components/Form/Input';
import FormSeo from '../../components/commons/FormSeo';
import FormImage from '../../components/Form/image';

import $ from 'jquery';
import axios from 'axios';

import { toast } from 'react-hot-toast';
import Select from 'react-select';
import FormSelect from '../../components/Form/FormSelect';
import MultipleImage from '../../components/Form/image/MultipleImage';
import FormButton from '../../components/Form/Button';
import { InputSelect } from '../../components/Form/Select';
import Description from '../../components/Form/Description';

const ProductCreate = () => {
      const [brands, setBrands] = useState([]);
      const [colors, setColors] = useState([]);
      const [units, setUnits] = useState([]);
      const [sizes, setSizes] = useState([]);

      const [categories, setCategories] = useState([]);
      const [subCategories, setSubCategories] = useState([]);
      const [childCategories, setChildCategories] = useState([]);
      const [loadingData, setLoadingData] = useState(false);

      const [loading, setLoading] = useState('');
      const [category, setcategory] = useState('');
      const [brand, setBrand] = useState('');
      const [title, setTitle] = useState('');
      const [slug, setSlug] = useState('');
      const [firstSize, setFirstSize] = useState('');
      const [secondSize, setSecondSize] = useState('');
      const [thirdSize, setThirdSize] = useState('');
      const [sizeUnit, setSizeUnit] = useState('');
      const [stock, setStock] = useState('');
      const [productCode, setProductCode] = useState('');
      const [unit, setUnit] = useState('');
      const [color, setColor] = useState([]);
      const [mrpPrice, setMrpPrice] = useState('');
      const [offerPrice, setOfferPrice] = useState('');
      const [dealerPrice, setDealerPrice] = useState('');
      const [discountPrice, setDiscountPrice] = useState('');
      const [image, setImage] = useState('');
      const [imageUrl, setImageUrl] = useState('');
      const [description, setDescription] = useState('');
      const [seoTitle, setSeoTitle] = useState(null);
      const [seoImage, setSeoImage] = useState('');
      const [seoImageUrl, setSeoImageUrl] = useState(null);
      const [seoKeyword, setSeoKeyword] = useState(null);
      const [seoDescription, setSeoDescription] = useState(null);
      const [images, setImages] = useState([]);
      const [imageFiles, setImageFiles] = useState([]);
      const [status, setStatus] = useState('');

      const token = JSON.parse(localStorage.getItem('token'));

      const loadData = async () => {
            setLoadingData(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/products/categories/list`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setCategories(response.data.categories);
                              setBrands(response.data.brands);
                              setColors(response.data.colors);
                              setUnits(response.data.units);
                              setSizes(response.data.sizes);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoadingData(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      const loadCategory = async (value) => {
            setcategory(value);
            const data = {
                  id: value
            };
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/products/categories/list`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setSubCategories(response.data.categories);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };

      const loadSubCategory = async (value) => {
            setcategory(value);
            const data = {
                  id: value
            };
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/products/categories/list`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setChildCategories(response.data.categories);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };

      const onChange = (newValue) => {
            setColor(newValue);
      };

      const handleSubmit = async (event) => {
            event.preventDefault();
            setLoading(true);

            const data = {
                  title: title,
                  slug: slug,
                  product_code: productCode,
                  brand: brand,
                  color: color,
                  first_size: firstSize,
                  second_size: secondSize,
                  third_size: thirdSize,
                  size: sizeUnit,
                  category: category,
                  status: status,
                  unit: unit,
                  stock: stock,
                  mrp_price: mrpPrice,
                  offer_price: offerPrice,
                  discount_price: discountPrice,
                  dealer_price: dealerPrice,
                  description: description,
                  image: image,
                  images: imageFiles,
                  seo_title: seoTitle,
                  seo_description: seoDescription,
                  seo_keyword: seoKeyword,
                  seo_image: seoImage
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/products`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                                    'Content-Type': 'multipart/form-data'
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              toast.success(response.data.message);
                              $('form').trigger('reset');
                              setImageUrl('');
                              setSeoImageUrl('');
                              setImages([]);
                              setColor([]);
                        }
                        if (response.data.result === 'error') {
                              if (response.data.message.title) {
                                    toast.error(response.data.message.title);
                              }
                              if (response.data.message.slug) {
                                    toast.error(response.data.message.slug);
                              }
                              if (response.data.message.product_code) {
                                    toast.error(
                                          response.data.message.product_code
                                    );
                              }
                              if (response.data.message.brand) {
                                    toast.error(response.data.message.brand);
                              }
                              if (response.data.message.color) {
                                    toast.error(response.data.message.color);
                              }
                              if (response.data.message.first_size) {
                                    toast.error(
                                          response.data.message.first_size
                                    );
                              }
                              if (response.data.message.second_size) {
                                    toast.error(
                                          response.data.message.second_size
                                    );
                              }
                              if (response.data.message.third_size) {
                                    toast.error(
                                          response.data.message.third_size
                                    );
                              }
                              if (response.data.message.size) {
                                    toast.error(response.data.message.size);
                              }
                              if (response.data.message.category) {
                                    toast.error(response.data.message.category);
                              }
                              if (response.data.message.status) {
                                    toast.error(response.data.message.status);
                              }
                              if (response.data.message.unit) {
                                    toast.error(response.data.message.unit);
                              }
                              if (response.data.message.stock) {
                                    toast.error(response.data.message.stock);
                              }
                              if (response.data.message.mrp_price) {
                                    toast.error(
                                          response.data.message.mrp_price
                                    );
                              }
                              if (response.data.message.offer_price) {
                                    toast.error(
                                          response.data.message.offer_price
                                    );
                              }
                              if (response.data.message.discount_price) {
                                    toast.error(
                                          response.data.message.discount_price
                                    );
                              }
                              if (response.data.message.dealer_price) {
                                    toast.error(
                                          response.data.message.dealer_price
                                    );
                              }
                              if (response.data.message.description) {
                                    toast.error(
                                          response.data.message.description
                                    );
                              }
                              if (response.data.message.image) {
                                    toast.error(response.data.message.image);
                              }
                              if (response.data.message.seo_title) {
                                    toast.error(
                                          response.data.message.seo_title
                                    );
                              }
                              if (response.data.message.seo_keyword) {
                                    toast.error(
                                          response.data.message.seo_keyword
                                    );
                              }
                              if (response.data.message.seo_description) {
                                    toast.error(
                                          response.data.message.seo_description
                                    );
                              }
                              if (response.data.message.seo_image) {
                                    toast.error(
                                          response.data.message.seo_image
                                    );
                              }
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      return (
            <>
                  <Form onSubmit={handleSubmit}>
                        <Row>
                              <Col lg={12}>
                                    <Card>
                                          <CardHeader>
                                                <CardTitle>
                                                      Create new Product
                                                </CardTitle>
                                                <CardLinks>
                                                      <LinkButton to="/dashboard/products">
                                                            Back
                                                      </LinkButton>
                                                </CardLinks>
                                          </CardHeader>

                                          <Card.Body>
                                                <Row className="g-3">
                                                      <Input
                                                            title="Product Name"
                                                            classes={8}
                                                            required={true}
                                                            handleChange={
                                                                  setTitle
                                                            }
                                                      />
                                                      <Input
                                                            title="Product Slug"
                                                            classes={4}
                                                            required={true}
                                                            handleChange={
                                                                  setSlug
                                                            }
                                                      />
                                                      <Input
                                                            title="Product Code"
                                                            classes={4}
                                                            required={true}
                                                            handleChange={
                                                                  setProductCode
                                                            }
                                                      />
                                                      <FormSelect
                                                            title="Brand"
                                                            classes={4}
                                                            required={true}
                                                            loading={
                                                                  loadingData
                                                            }
                                                            options={brands}
                                                            setData={setBrand}
                                                      />
                                                      <Col lg={4}>
                                                            <Form.Label>
                                                                  Color{''}
                                                            </Form.Label>
                                                            <Select
                                                                  isMulti
                                                                  onChange={
                                                                        onChange
                                                                  }
                                                                  options={
                                                                        colors
                                                                  }
                                                            />
                                                      </Col>
                                                      <Input
                                                            title="First Size"
                                                            required={true}
                                                            classes={4}
                                                            handleChange={
                                                                  setFirstSize
                                                            }
                                                      />
                                                      <Input
                                                            title="Second Size"
                                                            required={true}
                                                            classes={4}
                                                            handleChange={
                                                                  setSecondSize
                                                            }
                                                      />
                                                      <Input
                                                            title="Third Size"
                                                            required={true}
                                                            classes={4}
                                                            handleChange={
                                                                  setThirdSize
                                                            }
                                                      />
                                                      <FormSelect
                                                            title="Size Unit"
                                                            classes={4}
                                                            loading={
                                                                  loadingData
                                                            }
                                                            setData={
                                                                  setSizeUnit
                                                            }
                                                            required={true}
                                                            options={sizes}
                                                      />
                                                      <FormSelect
                                                            title="Category"
                                                            classes={4}
                                                            loading={
                                                                  loadingData
                                                            }
                                                            setData={
                                                                  loadCategory
                                                            }
                                                            required={true}
                                                            options={categories}
                                                      />
                                                      {subCategories.length >
                                                            0 && (
                                                            <FormSelect
                                                                  title="Sub Category"
                                                                  classes={4}
                                                                  loading={
                                                                        loadingData
                                                                  }
                                                                  setData={
                                                                        loadSubCategory
                                                                  }
                                                                  required={
                                                                        true
                                                                  }
                                                                  options={
                                                                        subCategories
                                                                  }
                                                            />
                                                      )}

                                                      {childCategories.length >
                                                            0 && (
                                                            <FormSelect
                                                                  title="Child Category"
                                                                  classes={4}
                                                                  loading={
                                                                        loadingData
                                                                  }
                                                                  setData={
                                                                        setcategory
                                                                  }
                                                                  required={
                                                                        true
                                                                  }
                                                                  options={
                                                                        childCategories
                                                                  }
                                                            />
                                                      )}
                                                      <InputSelect
                                                            title="Status"
                                                            classes={4}
                                                            setStatus={
                                                                  setStatus
                                                            }
                                                      />
                                                </Row>
                                          </Card.Body>
                                    </Card>

                                    <Card className="mt-4">
                                          <Card.Body>
                                                <Row className="g-3">
                                                      <FormSelect
                                                            title="Product Unit"
                                                            classes={4}
                                                            loading={
                                                                  loadingData
                                                            }
                                                            setData={setUnit}
                                                            required={true}
                                                            options={units}
                                                      />
                                                      <Input
                                                            title="Stock"
                                                            classes={4}
                                                            required={true}
                                                            handleChange={
                                                                  setStock
                                                            }
                                                      />
                                                      <Input
                                                            title="Mrp Price"
                                                            classes={4}
                                                            required={true}
                                                            handleChange={
                                                                  setMrpPrice
                                                            }
                                                      />
                                                      <Input
                                                            title="Offer Price"
                                                            classes={4}
                                                            handleChange={
                                                                  setOfferPrice
                                                            }
                                                      />
                                                      <Input
                                                            title="Dealer Price"
                                                            classes={4}
                                                            handleChange={
                                                                  setDealerPrice
                                                            }
                                                      />
                                                      <Input
                                                            title="Discount Price"
                                                            classes={4}
                                                            handleChange={
                                                                  setDiscountPrice
                                                            }
                                                      />
                                                </Row>
                                          </Card.Body>
                                    </Card>

                                    <Card className="mt-4">
                                          <Card.Body>
                                                <Row className="g-3">
                                                      <Description
                                                            required={true}
                                                            title="Description"
                                                            setDescription={
                                                                  setDescription
                                                            }
                                                      />
                                                </Row>
                                          </Card.Body>
                                    </Card>

                                    <Card className="mt-4">
                                          <Card.Body>
                                                <Row className="g-3">
                                                      <FormImage
                                                            title="Featured Image"
                                                            setImage={setImage}
                                                            setImageUrl={
                                                                  setImageUrl
                                                            }
                                                            required={true}
                                                            imageUrl={imageUrl}
                                                      />
                                                      <MultipleImage
                                                            title="Other Images (Upload multiple images)"
                                                            images={images}
                                                            grid={4}
                                                            classes={8}
                                                            setImages={
                                                                  setImages
                                                            }
                                                            setImageFiles={
                                                                  setImageFiles
                                                            }
                                                      />
                                                </Row>
                                          </Card.Body>
                                    </Card>

                                    <FormSeo
                                          seoImageUrl={seoImageUrl}
                                          setSeoImage={setSeoImage}
                                          setSeoImageUrl={setSeoImageUrl}
                                          setSeoDescription={setSeoDescription}
                                          setSeoTitle={setSeoTitle}
                                          setSeoKeyword={setSeoKeyword}
                                    />

                                    <Card className="mt-4 mb-5">
                                          <Card.Body>
                                                <Row>
                                                      <Col lg={12}>
                                                            <FormButton
                                                                  title="Submit"
                                                                  loading={
                                                                        loading
                                                                  }
                                                            />
                                                      </Col>
                                                </Row>
                                          </Card.Body>
                                    </Card>
                              </Col>
                        </Row>
                  </Form>
            </>
      );
};

export default ProductCreate;
