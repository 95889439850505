import React, { useEffect, useState } from 'react';
import { Card, Col, ListGroup, Row } from 'react-bootstrap';
import {
      CardHeader,
      CardLinks,
      CardTitle
} from '../../styles/components/common/Table';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import ItemShow from '../../components/Form/ItemShow';
import { LinkButton } from '../../styles/components/common/Button';
import { Loading } from '../../components/Loading';

const BlogShow = () => {
      const { blogId } = useParams();
      const [blog, setBlog] = useState([]);
      const [loadingData, setLoadingData] = useState(false);

      const token = JSON.parse(localStorage.getItem('token'));

      const loadCategories = async () => {
            setLoadingData(true);

            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/blogs/${blogId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setBlog(response.data.blog);
                        }
                  });
            setLoadingData(false);
      };

      useEffect(() => {
            loadCategories();
      }, []);

      return (
            <>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader>
                                          <CardTitle>Blog Detail</CardTitle>
                                          <CardLinks>
                                                <LinkButton to="/dashboard/blogs">
                                                      Back
                                                </LinkButton>
                                          </CardLinks>
                                    </CardHeader>
                                    <Card.Body>
                                          {!loadingData ? (
                                                <Row>
                                                      <Col lg={6}>
                                                            <img
                                                                  className="w-100"
                                                                  src={
                                                                        blog.image
                                                                  }
                                                                  alt=""
                                                            />
                                                            <div className="px-3 ">
                                                                  <div>
                                                                        <div
                                                                              dangerouslySetInnerHTML={{
                                                                                    __html: blog.description
                                                                              }}
                                                                        ></div>
                                                                  </div>
                                                            </div>
                                                      </Col>
                                                      <Col lg={6}>
                                                            <ListGroup>
                                                                  <ItemShow
                                                                        title="Title"
                                                                        value={
                                                                              blog.title
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Category"
                                                                        value={
                                                                              blog.category
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Date"
                                                                        value={
                                                                              blog.date
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="By"
                                                                        value={
                                                                              blog.user_name
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Seo Title"
                                                                        value={
                                                                              blog.seo_title
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Seo Keyword"
                                                                        value={
                                                                              blog.seo_keyword
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Seo Description"
                                                                        value={
                                                                              blog.seo_description
                                                                        }
                                                                  />
                                                            </ListGroup>

                                                            <div className="p-3">
                                                                  <img
                                                                        style={{
                                                                              width: '200px'
                                                                        }}
                                                                        src={
                                                                              blog.seo_image
                                                                        }
                                                                        alt=""
                                                                  />
                                                            </div>
                                                      </Col>
                                                </Row>
                                          ) : (
                                                <>
                                                      <div
                                                            className="text-center d-flex align-items-center justify-content-center w-100"
                                                            style={{
                                                                  height: '30vh'
                                                            }}
                                                      >
                                                            <Loading />
                                                      </div>
                                                </>
                                          )}
                                    </Card.Body>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default BlogShow;
